import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: 20,
            border: '1px solid #bababa',
            borderRadius: '4px',
            '& .MuiAccordionDetails-root': {
                display: 'flex',
                flexDirection: 'column',
                padding: '0',
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        accordionSummary: {
            flexDirection: 'row-reverse',
        },
        titleHeader: {
            color: '#575F72',
            fontWeight: theme.typography.fontWeightMedium,
        },
        flexStart: {
            justifyContent: 'flex-start',
            display: 'flex',
        },
        flexEnd: {
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
        },
        sortGroupButton: {
            color: '#000000',
            minWidth: 35,
            backgroundColor: '#ffffff',
            '&:hover': {
                color: '#ffffff',
                backgroundColor: '#216085',
            },
            marginLeft: 14,
        },
        sizeButton: {
            borderRadius: 50,
            border: '#000000 solid 2px',
            minWidth: 30,
            padding: 0,
            marginLeft: 2,
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    })
);

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
    color: #5ca7da;
`;
