export const allowedExtensions = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/ico',
    'image/tif',
    'image/tiff',
    'image/exif',
    'image/emf',
    'image/wmf',
    'video/mp4',
    'video/mpeg',
    'video/avi',
    'video/mov',
    'video/wmv',
    'video/flv',
    'video/webm',
    'video/mpg',
    'video/mp2',
];

export const SIZES = {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large',
    ORIGINAL: 'Original',
    MOBILE: 'Mobile',
};

export const PREVIEW_TOKEN_ALIVE = 12;

export const PREVIEW_DEFAULT_IMAGE = `${process.env.PUBLIC_URL}/default.jpg`;
