import React, { useState } from 'react';

import { PREVIEW_DEFAULT_IMAGE } from 'base/constants/previewFiles';

const ImageWithDefault: React.FC<{ src: string; alt: string; defaultSrc?: string }> = ({
    src,
    alt,
    defaultSrc,
}) => {
    const [url, setUrl] = useState<string>(src);
    return (
        <img
            src={url}
            alt={alt}
            loading="lazy"
            onError={() => setUrl(defaultSrc ?? PREVIEW_DEFAULT_IMAGE)}
        />
    );
};

export default ImageWithDefault;
