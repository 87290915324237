import { makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import styled from 'styled-components';

export const StyledToggleButton = withStyles({
    root: {
        fontFamily: 'Arial',
        fontSize: '14px',
        lineHeight: '10px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '15px 15px',
        textTransform: 'none',
        width: '120px',
        '&$selected': {
            backgroundColor: 'rgba(61, 168, 223, .25)',
            color: 'rgb(26, 88, 159)',
            '&:hover': {
                backgroundColor: 'rgba(33, 137, 214, 0.14)',
                color: 'rgb(26, 88, 159)',
            },
        },
    },
    selected: {},
})(ToggleButton);

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    selectFilter: {
        height: '40px',
        width: '320px',
    },
    formControl: {},
}));

export const DivContainer = styled.div`
    padding-top: 30px;
`;
