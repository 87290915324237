import React from 'react';
import { List } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { AssetItem } from '../AssetItem';
import { Assets } from 'base/models/Asset';
import { Asset } from 'base/models/Asset';
interface AssetListProps {
    assets: Assets;
}

const AssetList: React.FC<AssetListProps> = ({ assets }) => {
    return assets.length > 0 ? (
        <List>
            {assets.map((asset: Asset) => {
                const { isCancelled, isUploaded, hasError, progress, hasStartUpload, isChecked } =
                    asset.uploadProperties;

                const { filePath } = asset.fields;
                return (
                    <div key={asset.id}>
                        <AssetItem
                            key={asset.id}
                            assetProgress={progress}
                            isCancelled={isCancelled}
                            isUploaded={isUploaded}
                            hasError={hasError}
                            hasStartUpload={hasStartUpload}
                            isChecked={isChecked}
                            asset={asset}
                            filePath={filePath}
                        />
                    </div>
                );
            })}
        </List>
    ) : (
        <></>
    );
};

export default observer(AssetList);
