import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import { groupType, UserDropdown } from 'base/models/DashboardProps';

export const getAssetsGrouped = async (
    statusFilter: number,
    groupBy: groupType,
    emailFilter: string
) => {
    let groupByString = '';
    switch (groupBy) {
        case groupType.TYPE_TITLE:
            groupByString = 'assetType-Title';
            break;
        case groupType.TITLE:
            groupByString = 'title';
            break;
    }

    const response = await resolvePromise(
        api.get(`assets?status=${statusFilter}&groupBy=${groupByString}&user=${emailFilter}`)
    );
    return response;
};

export const getAssetStatusCount = async (userSelected: string) => {
    return await resolvePromise(api.get(`assets/status/count?user=${userSelected}`));
};

export const getUsers = async () => {
    return await resolvePromise(api.get<UserDropdown[]>('assets/users'));
};

export const getAssetsHoldback = async (userSelected: string, groupBy: groupType) => {
    let groupByString = '';
    switch (groupBy) {
        case groupType.TYPE_TITLE:
            groupByString = 'assetType-Title';
            break;
        case groupType.TITLE:
            groupByString = 'title';
            break;
    }
    return await resolvePromise(
        api.get(`assets/holdback?user=${userSelected}&groupBy=${groupByString}`)
    );
};
