import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    disableRipple: {
        textDecoration: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconButton: {
        color: 'white',
        height: '20px',
        position: 'absolute',
        top: '12px',
        right: '14px',
        padding: '0px',
    },
}));
