import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useAssetDuplicateStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            height: '100%',
        },
        dialogTitle: {
            marginBottom: -20,
        },
        dialogGridContainer: {
            padding: '10px 24px 25px',
            marginBottom: 0,
        },
        dialogContent: {
            border: '1px solid lightgray',
            borderLeft: 'none',
            borderRight: 'none',
        },
        clearBtn: {
            padding: 0,
        },
        dialogSectionHeading: {
            fontSize: '14',
            height: '3em',
            lineHeight: 3,
        },
        selectionsTitle: {
            fontSize: '16px',
        },
        titleList: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            border: 'solid 1px lightgray',
            borderRadius: '3px',
            overflow: 'auto',
            maxHeight: ' 75vh',
        },
        titleItem: {
            paddingTop: 0,
            paddingBottom: 0,
            cursor: 'pointer',
            '&:hover': {
                background: 'lightgray',
            },
        },
        titleItemIcon: {
            minWidth: '30px',
            marginTop: '-2px',
        },
        onelineEllipsis: {
            width: '50%',
            flex: '1 auto',
            '& label': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                // textOverflow: 'clip',
                textOverflow: 'ellipsis',
                width: '100%',
                display: 'inline-block',
            },
        },
        assetDescription: {
            padding: '4px 6px 4px 8px',
        },
    })
);

export default useAssetDuplicateStyles;
