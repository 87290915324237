import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Grid, Paper, FormControlLabel, Checkbox } from '@material-ui/core';

import { AssetList } from 'components/EditAsset/AssetList';
import { useRootStoreContext } from 'base/stores/rootStore';
import { CommonMetadata } from 'components/EditForm/CommonMetadata';

import { useStyles, TextHeaderContainer } from './EditAssetUnassigned.styles';
import { Assets } from 'base/models/Asset';

const EditAssetUnassigned = () => {
    const classes = useStyles();
    const [unassignedAssets, setUnassignedAssets] = useState<Assets>([]);
    const { assetsInStore, setAllAssetsChecksInStore, areAllAssetsCheckedInStore } =
        useRootStoreContext().ingestionStore;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checkValue = e.target.checked;
        setAllAssetsChecksInStore(checkValue);
    };

    useEffect(() => {
        setUnassignedAssets(assetsInStore);
    }, [assetsInStore]);

    return (
        <Grid container className={classes.outline}>
            <Grid item xs={12} md={3} lg={3}>
                <Grid container>
                    <Grid item xs={12} className={classes.outlineTopLeft}>
                        <Paper elevation={0}>
                            <div>
                                <FormControlLabel
                                    value="select-all"
                                    control={
                                        <>
                                            <Checkbox
                                                color="primary"
                                                onChange={handleCheckboxChange}
                                                checked={areAllAssetsCheckedInStore}
                                            />
                                            <TextHeaderContainer>Asset Queue</TextHeaderContainer>
                                        </>
                                    }
                                    label=""
                                    labelPlacement="end"
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.outlineTopRight}>
                        <Paper elevation={0}>
                            <AssetList assets={unassignedAssets} />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
                <Grid container>
                    <Grid item xs={12} className={classes.outlineBottomLeft}>
                        <Paper elevation={0}>
                            <TextHeaderContainer>Assign title and asset group</TextHeaderContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.root}>
                        <Paper elevation={0}>
                            <CommonMetadata />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default observer(EditAssetUnassigned);
