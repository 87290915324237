import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {},
    selectFilter: {
        marginLeft: '60px',
        paddingLeft: '20px',
        height: '30px',
        width: '320px',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        fontWeight: 'bold',
    },
}));
