import React, { useState } from 'react';
import { getUserInfo } from 'base/api/auth';
import localStorageAccess, { LocalStorageKeys } from 'base/utils/localStorageAccess';
import { useStyles } from './Reauth.page.styles';

const Reauth: React.FC = () => {
    const [token, setToken] = useState('');
    const classes = useStyles();

    const handleOnClick = async () => {
        const response = await getUserInfo(token);
        setToken('');
        if (response.response && response.response.data) {
            localStorageAccess.setToLocalStorage(LocalStorageKeys.userInfo, response.response.data);
            window.location.href = '/';
        }
    };

    return (
        <form
            onSubmit={(evt) => {
                evt.preventDefault();
                handleOnClick();
            }}
        >
            <textarea
                value={token}
                onChange={(e) => setToken(e.target.value)}
                className={classes.textareaStyle}
            ></textarea>
            <input type="submit" value="Send" className={classes.buttonStyle} />
        </form>
    );
};

export default Reauth;
