import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import {
    useStyles,
    PaperContainer,
    UploadIconContainer,
    MainUploadParagraph,
    SecondUploadParagraph,
    ButtonUpload,
} from './Upload.styles';

import { useDropzone } from 'react-dropzone';
import { useRootStoreContext } from 'base/stores/rootStore';

import { Grid } from '@material-ui/core';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';

import { Aspera } from 'base/utils/aspera-client';
import { onDropAssetsAspera } from './Upload.functions';
import { executeTransfer } from 'base/services/aspera';

const dragAndDropSelector = 'drag-and-drop';

export const UploadComponent = () => {
    const { current: aspera } = useRef(new Aspera());
    const classes = useStyles();
    const {
        setAssetsInStore,
        initializeJobInStore,
        setCurrentJobInStore,
        updateAssetsWithTransferId,
        setIsStartingUploadInStore,
    } = useRootStoreContext().ingestionStore;
    const { getRootProps, getInputProps } = useDropzone({ noClick: true });

    const handleSelectAssetsFromDialog = async () => {
        const dialogOptions: any = {
            allowMultipleSelection: true
        };
        aspera.init();
        aspera.client.showSelectFileDialog(
            {
                success: async (event: any) => {
                    const isThereAnySelectedFiles = event.dataTransfer.files.length > 0;
                    if (isThereAnySelectedFiles) {
                        setIsStartingUploadInStore(true);
                        const data = await onDropAssetsAspera(
                            event,
                            false,
                            initializeJobInStore,
                            setCurrentJobInStore,
                            setAssetsInStore
                        );
                        if (data) {
                            executeTransfer(
                                data.transferSpec,
                                data.assets,
                                aspera,
                                setIsStartingUploadInStore,
                                setAssetsInStore,
                                updateAssetsWithTransferId
                            );
                        }
                    }
                },
                error: (error: any) => error,
            },
            dialogOptions
        );
    };

    const handleDragAndDropAssets = async (event: any) => {
        setIsStartingUploadInStore(true);

        const data = await onDropAssetsAspera(
            event,
            true,
            initializeJobInStore,
            setCurrentJobInStore,
            setAssetsInStore
        );

        if (data) {
            executeTransfer(
                data.transferSpec,
                data.assets,
                aspera,
                setIsStartingUploadInStore,
                setAssetsInStore,
                updateAssetsWithTransferId
            );
        }
    };

    useEffect(() => {
        aspera.validateStatusAndInstallation();
        aspera.client.setDragDropTargets(`#${dragAndDropSelector}`, {}, handleDragAndDropAssets);
        aspera.init();
    }, []);

    return (
        <Grid container justify="center">
            <Grid item xs>
                <div {...getRootProps()} id={dragAndDropSelector}>
                    <input {...getInputProps()} />
                    <PaperContainer elevation={0} className={classes.paper}>
                        <div className={classes.root}>
                            <UploadIconContainer>
                                <PublishOutlinedIcon style={{ fontSize: '100px' }} />
                            </UploadIconContainer>
                            <MainUploadParagraph>
                                Drag and drop your files to upload
                            </MainUploadParagraph>
                            <SecondUploadParagraph>or</SecondUploadParagraph>
                            <ButtonUpload
                                disableRipple
                                variant="contained"
                                onClick={handleSelectAssetsFromDialog}
                            >
                                Select Files
                            </ButtonUpload>
                        </div>
                    </PaperContainer>
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(UploadComponent);
