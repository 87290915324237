import React, { useState, useRef } from 'react';
import { Button } from '@material-ui/core';
import { SortGroup, SortGroupAsset } from 'base/models/Asset';

import { observer } from 'mobx-react-lite';
import { useStyles } from '../GroupWrapper/GroupWrapper.styles';

import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import VerticalAlignTopOutlinedIcon from '@material-ui/icons/VerticalAlignTopOutlined';
import VerticalAlignBottomOutlinedIcon from '@material-ui/icons/VerticalAlignBottomOutlined';

import TextField from '@material-ui/core/TextField';

import { useRootStoreContext } from 'base/stores/rootStore';
import { ReactSortable } from 'react-sortablejs';
import { ChildrenContainer } from '../ChildrenContainer';
import { SortableOptions } from '../SortableOptions.variables';
import { ImageWithDefault } from '../ImageWithDefault';

interface GroupWrapperProps {
    groupIdx: number;
    group: SortGroup;
    openGroupView?: (idx: number, group: SortGroup) => void;
    disableSorting?: (newValue: boolean) => void;
}

const OrphanItem: React.FC<{
    isDragging: boolean;
    classes: any;
    mainAsset: any;
    isPlaceholder?: boolean;
}> = ({ isDragging, classes, mainAsset, isPlaceholder }) => (
    <>
        <div
            title={mainAsset.name}
            className={`dmdc-sort-group--orphan-item ${
                isPlaceholder && 'dmdc-sort-group--orphan-item--static'
            }`}
        >
            <div className={`dmdc-sort-group--header`}></div>
            <div
                className={`dmdc-sort-group--main-asset-image ${
                    isDragging ? classes.isDraggingPlaceholder : classes.placeholder
                }`}
            >
                <ImageWithDefault
                    src={mainAsset?.imageUrl}
                    alt={mainAsset?.name}
                ></ImageWithDefault>
            </div>
            <div className="dmdc-sort-group--main-label">
                <span> {mainAsset?.name}</span>
            </div>
        </div>
    </>
);

const GroupWrapper: React.FC<GroupWrapperProps> = ({
    groupIdx,
    group,
    openGroupView,
    disableSorting,
}) => {
    const {
        moveToUpByIdx,
        moveToBottomByIdx,
        editGroupName,
        updateGroupAssets,
        isDragging,
        setIsDragging,
    } = useRootStoreContext().groupingStore;

    const classes = useStyles({ isDragging });
    const [isReadOnlyTitle, setIsReadOnlyTitle] = useState<boolean>(true);
    const [isHover, setIsHover] = useState<boolean>(false);

    const carosuelRef = useRef<any | null>(null);

    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);

    const assets = group.assets;

    const handleMoveToTop = () => {
        moveToUpByIdx(groupIdx);
    };

    const handleMoveToBottom = () => {
        moveToBottomByIdx(groupIdx);
    };

    const [isLocallyDragging, setIsLocallyDragging] = useState<boolean>(false);

    if (!group || (group?.type === 'group' && !group.assets?.length)) return null;

    const filterAsset = group.assets?.filter((asset) => asset.isMain);

    let mainAsset = filterAsset.length > 0 ? filterAsset[0] : null;
    if (!mainAsset) {
        mainAsset = group.assets[0];
    }

    const handleClick = (evt: any) => {
        evt.target.focus();
        setIsReadOnlyTitle(false);
        disableSorting && disableSorting(true);
    };

    const handleLeave = (group: SortGroup) => {
        defaultValues();
        group.name && editGroupName(group, group.name);
    };

    const keyPress = (e: any, group: SortGroup) => {
        if (e.key === 'Enter') {
            defaultValues();
            editGroupName(group, e.target.value);
        }
    };

    const defaultValues = () => {
        setIsReadOnlyTitle(true);
        disableSorting && disableSorting(false);
    };

    const onChange = (e: any, group: SortGroup) => {
        group.name = e.target.value;
    };

    const scrollToLeft = () => {
        if (carosuelRef.current) {
            carosuelRef.current.scrollToLeft();
        }
    };

    const scrollToRight = () => {
        if (carosuelRef.current) {
            carosuelRef.current.scrollToRight();
            setShowLeftArrow(true);
        }
    };

    const updateList = (currentList: SortGroupAsset[]) => {
        const before = [...assets];
        const after = [...currentList];
        if (JSON.stringify(before.sort()) !== JSON.stringify(after.sort())) {
            updateGroupAssets(group.id, currentList);
        }
    };

    const handleOrphanChosen = (isDragging: boolean) => {
        setIsDragging(isDragging);
        setIsLocallyDragging(isDragging);
    };

    const handleMouseEnter = () => {
        !isDragging && setIsHover(true);
    };

    const handleMouseLeave = () => {
        !isDragging && setIsHover(false);
    };

    return (
        <div
            className={`dmdc-sort-group--card-content ${isDragging && 'dmdc-squish'}`}
            onMouseEnter={(evt) => {
                handleMouseEnter();
            }}
            onMouseLeave={(evt) => {
                handleMouseLeave();
            }}
        >
            {group.assets.length > 1 ? (
                // GROUP WITH Children, to do - componetize
                <>
                    <div className="dmdc-sort-group--header-wrapper">
                        <div className={`dmdc-sort-group--header`}>
                            <TextField
                                defaultValue={group.name}
                                disabled={Boolean(isReadOnlyTitle)}
                                style={{ width: '100%' }}
                                onBlur={() => handleLeave(group as SortGroup)}
                                onKeyDown={(e) => keyPress(e, group as SortGroup)}
                                onChange={(e) => onChange(e, group as SortGroup)}
                                onClick={(evt) => handleClick(evt)}
                            />
                        </div>
                        <div
                            title={mainAsset.name}
                            className={`dmdc-sort-group--main-asset-image ${
                                isDragging ? classes.isDraggingPlaceholder : classes.placeholder
                            }`}
                        >
                            <ImageWithDefault
                                src={mainAsset?.imageUrl}
                                alt={mainAsset?.name}
                            ></ImageWithDefault>
                        </div>

                        <div className="dmdc-sort-group--main-label">
                            <span> {mainAsset?.name}</span>
                        </div>
                    </div>
                    <ChildrenContainer mainAsset={mainAsset} group={group} ref={carosuelRef} />

                    {/* to do: componetize actions area */}
                    {!isDragging && (
                        <div className="dmdc-sort-group--actions-container">
                            <div className="d-flex dmdc-sort-group--control-buttons">
                                <Button
                                    onClick={() => openGroupView && openGroupView(groupIdx, group)}
                                    className={classes.footerButtons}
                                    title="Expand"
                                >
                                    <OpenWithOutlinedIcon />
                                </Button>
                                <Button
                                    className={classes.footerButtons}
                                    onClick={handleMoveToTop}
                                    title="Move as First"
                                >
                                    <VerticalAlignTopOutlinedIcon />
                                </Button>
                                <Button
                                    className={classes.footerButtons}
                                    onClick={handleMoveToBottom}
                                    title="Move as Last"
                                >
                                    <VerticalAlignBottomOutlinedIcon />
                                </Button>
                            </div>

                            {group.assets.length > 3 && (
                                <div
                                    className={`d-flex dmdc-sort-group--scroll-buttons dmdc-sort-group--item-count-${group.assets.length}`}
                                >
                                    {showLeftArrow && (
                                        <Button
                                            className={classes.footerButtons}
                                            onClick={scrollToLeft}
                                            title="Scroll to Left"
                                        >
                                            <KeyboardArrowLeftOutlinedIcon />
                                        </Button>
                                    )}
                                    <Button
                                        className={classes.footerButtons}
                                        onClick={scrollToRight}
                                        title="Scroll to Right"
                                    >
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </>
            ) : (
                // ORPHAN GROUP (also called as a Group with an orpan item), to do - componetize
                <>
                    {!isHover ? (
                        <>
                            <OrphanItem
                                isDragging={isDragging}
                                classes={classes}
                                mainAsset={mainAsset}
                                isPlaceholder={true}
                            ></OrphanItem>
                        </>
                    ) : (
                        <ReactSortable
                            className={`dmdc-sort-group--orphan-container ${
                                isLocallyDragging ? 'dmdc-ignore-squish' : ''
                            }`}
                            list={assets}
                            onChoose={() => handleOrphanChosen(true)}
                            onUnchoose={() => handleOrphanChosen(false)}
                            setList={updateList}
                            {...SortableOptions}
                        >
                            <OrphanItem
                                isDragging={isDragging}
                                classes={classes}
                                mainAsset={mainAsset}
                            ></OrphanItem>
                        </ReactSortable>
                    )}

                    {isDragging && !isLocallyDragging && (
                        <div className="dmdc-sort-group-oprhan-children-placeholder">
                            <ChildrenContainer
                                mainAsset={mainAsset}
                                group={group}
                                ref={carosuelRef}
                            />
                        </div>
                    )}
                    {!isDragging && (
                        <div className="dmdc-sort-group--actions-container">
                            <div className="d-flex dmdc-sort-group--control-buttons">
                                <Button
                                    className={classes.footerButtons}
                                    onClick={handleMoveToTop}
                                    title="Move as First"
                                >
                                    <VerticalAlignTopOutlinedIcon />
                                </Button>
                                <Button
                                    className={classes.footerButtons}
                                    onClick={handleMoveToBottom}
                                    title="Move as Last"
                                >
                                    <VerticalAlignBottomOutlinedIcon />
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default observer(GroupWrapper);
