import React from 'react';

interface TabContainerProps {
    children: React.ReactNode;
}

const TabContainer = ({ children }: TabContainerProps) => {
    return <div style={{ marginTop: '15px' }}>{children}</div>;
};

export default TabContainer;
