interface AW4Prop extends Window {
    AW4?: any;
}

export class Aspera {
    AW4: any;
    client: any;
    asperaInstaller: any;

    constructor() {
        const { AW4 }: AW4Prop = window;

        const clientInstance = new AW4.Connect({
            minVersion: '3.10.0',
            dragDropEnabled: true,
        });

        const CONNECT_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
        this.asperaInstaller = new AW4.ConnectInstaller({ sdkLocation: CONNECT_INSTALLER });

        this.AW4 = AW4;
        this.client = clientInstance;
    }

    validateStatusAndInstallation() {
        const statusEventListener = (eventType: any, data: any) => {
            if (
                eventType === this.AW4.Connect.EVENT.STATUS &&
                data === this.AW4.Connect.STATUS.INITIALIZING
            ) {
                this.asperaInstaller.showLaunching();
            } else if (
                eventType === this.AW4.Connect.EVENT.STATUS &&
                data === this.AW4.Connect.STATUS.FAILED
            ) {
                this.asperaInstaller.showDownload();
            } else if (
                eventType === this.AW4.Connect.EVENT.STATUS &&
                data === this.AW4.Connect.STATUS.OUTDATED
            ) {
                this.asperaInstaller.showUpdate();
            } else if (
                eventType === this.AW4.Connect.EVENT.STATUS &&
                data === this.AW4.Connect.STATUS.RUNNING
            ) {
                this.asperaInstaller.connected();
            }
        };

        this.client.addEventListener(this.AW4.Connect.EVENT.STATUS, statusEventListener);
    }

    init() {
        this.client.initSession();
    }

    stopTransfer(transferId: string) {
        this.client.stopTransfer(transferId);
    }

    resumeTransfer(transferId: string) {
        this.client.resumeTransfer(transferId);
    }
}
