import React from 'react';
import { TitleDisplay } from './TitleRowFormat.style';

interface TitleRowFormatProps {
    typeTitle: string;
}

const TitleRowFormat: React.FC<TitleRowFormatProps> = ({ typeTitle }: TitleRowFormatProps) => (
    <TitleDisplay> {typeTitle} </TitleDisplay>
);

export default TitleRowFormat;
