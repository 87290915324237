import React, { useRef } from 'react';
import { Asset } from 'base/models/Asset';
import useAsperaTransfer from 'base/hooks/useAsperaTransfer';
import { useRootStoreContext } from 'base/stores/rootStore';
import { Aspera } from 'base/utils/aspera-client';
import { CircularProgressWithLabel } from 'components/shared/CircularProgressWithLabel';

interface ProgressProps {
    asset: Asset;
}

const Progress = ({ asset }: ProgressProps) => {
    const { getUploads } = useRootStoreContext().transferStore;
    const { current: aspera } = useRef(new Aspera());
    aspera.init();
    const isEditGrid = true;
    useAsperaTransfer(
        aspera,
        asset.asperaTransferId ?? '',
        asset.fields?.originalFileName ?? '',
        asset.id,
        '',
        '',
        isEditGrid,
        asset
    );

    const transferProperties =
        (getUploads.get(asset.asperaTransferId || '') as any)?.uploadProperties || {};

    const incomplete = transferProperties.progress < 100;

    return (
        <>
            {incomplete
                ? transferProperties.progress !== undefined && (
                      <CircularProgressWithLabel value={Math.floor(transferProperties.progress)} />
                  )
                : transferProperties.progress === 100 && <span>Complete</span>}
        </>
    );
};

export default Progress;
