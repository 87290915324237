import { SortGroup } from 'base/models/Asset';
import React from 'react';
import { SortableWrapper } from '../SortableWrapper';

const ProductCollection: React.FC<{
    openGroupView: (idx: number, group: SortGroup) => void;
}> = ({ openGroupView }) => {
    return <SortableWrapper openGroupView={openGroupView} />;
};

export default ProductCollection;
