import React from 'react';
import { DigitalScreenerList } from 'components/Dashboard/DigitalScreenerComponent';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

interface DisplayDSModalProps {
    packageId: string;
    open: boolean;
    setOpen: (isOpen: boolean) => void;
}

const DisplayDSModal: React.FC<DisplayDSModalProps> = ({ packageId, open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth="xl"
        >
            <DialogTitle>Components</DialogTitle>
            <DialogContent>
                <DigitalScreenerList packageId={packageId}></DigitalScreenerList>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisplayDSModal;
