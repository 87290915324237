import React, { useState } from 'react';

import { CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { useStyles } from './CustomPreview.styles';
import { publishDraftPreview, putCustomPreviewFile, restoreOriginalPreviewAsync } from 'base/api/preview';
import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Asset } from 'base/models/Asset';
import { AssetStatus } from 'base/enums/AssetStatus';
import { ConfirmModal } from 'components/shared/ConfirmModal';
import Resizer from 'react-image-file-resizer';
import Tiff from 'tiff.js/tiff';

interface CustomPreviewMenuProps {
    asset: Asset | null;
    updateAsset: (asset: Asset | null) => void;
}

const CustomPreviewMenu = ({ asset, updateAsset }: CustomPreviewMenuProps) => {
    const classes = useStyles();
    const { 
        setIsBackdrop, 
        getIsBackdropOpen: isBackdropOpen } = useRootStoreContext().dashboardStore;

    const { addPreview, deletePreview, getPreviewMaps } = useRootStoreContext().previewStore;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDeleteCustomPreviewConfirmationOpen, setIsDeleteCustomPreviewConfirmationOpen] = useState(false);

    const [menuRef, setMenuRef] = useState<(EventTarget & HTMLButtonElement) | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuRef(event.currentTarget);
        setIsMenuOpen(true);
    };

    const resizeFile = (file: File, height: number, width: number) =>
        new Promise<string>((resolve) => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri.toString());
                },
                'base64'
            );
        });

    const resizeFileCall = (asset: Asset, assetFile: File) => {
        const resizeFileSmall = resizeFile(assetFile, 208, 284);
        const resizeFileLarge = resizeFile(assetFile, 800, 800);

        Promise.all([resizeFileSmall, resizeFileLarge])
            .then((values) => {
                addPreview(asset.assetId, values[0], values[1]);
            })
            .catch(() => {
                // eslint-disable-next-line
                console.log(`Asset ${asset.assetId} could not be resized`);
            });
    };

    const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',');
        const mime = 'image/png';
        const bstr = atob(arr[1]);
        let num = bstr.length;
        const u8arr = new Uint8Array(num);

        while (num--) {
            u8arr[num] = bstr.charCodeAt(num);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target.files && e?.target.files.length > 0 && asset) {
            const formData = new FormData();
            let assetFile = e.target.files[0];
            const splittedFileName = assetFile.name.split('.');
            const fileExtension = splittedFileName[splittedFileName.length - 1];
            formData.append('file', assetFile);

            let isTifImage = false;
            if (assetFile.size > 0) {
                if (assetFile.type === 'image/tif' || assetFile.type === 'image/tiff') {
                    isTifImage = true;
                    assetFile.arrayBuffer().then((arrayBuffer) => {
                        Tiff.initialize({ TOTAL_MEMORY: 16777216 * 10 });
                        const tiff = new Tiff({ buffer: arrayBuffer });
                        const url = tiff.toDataURL();

                        assetFile = dataURLtoFile(url, assetFile.name);
                        resizeFileCall(asset, assetFile);
                    });
                }

                if (!isTifImage) resizeFileCall(asset, assetFile);
            }

            setIsBackdrop(true);
            const response = await putCustomPreviewFile(formData);

            if(!response.response?.data?.idFileName){
                //TODO: alert about error to the user
                setIsBackdrop(false);
                setIsMenuOpen(false);
                return;
            }

            const customPreviewId = response.response?.data?.idFileName;

            if(!customPreviewId){
                setIsBackdrop(false);
                setIsMenuOpen(false);
                return;
            }
            
            const newAsset = { ...asset } as Asset;                
            newAsset.customPreviewId = `${customPreviewId}.${fileExtension}`;

            // Note: DraftAsset receives status as IdValue
            // @ts-ignore
            const numericAssetStatus = asset.status as number;
            newAsset.status = {
                id: numericAssetStatus,
                value: AssetStatus[numericAssetStatus].toString(),
            };

            await publishDraftPreview(newAsset);

            updateAsset(newAsset);

            setIsBackdrop(false);
            setIsMenuOpen(false);
        }
    };

    const handleDeleteCustomThumbnail = async () => {
        
        if(!asset){
            return;
        }

        const newAsset = {
            ...asset,
            previewId: undefined,
            customPreviewId: undefined
        };

        setIsBackdrop(true);

        const apiResponse = await restoreOriginalPreviewAsync(newAsset);    
        if(apiResponse && apiResponse.response?.status !== 200){
            setIsBackdrop(false);
            setIsMenuOpen(false);

            return;
        }

        deletePreview(newAsset.assetId);
        updateAsset(newAsset);        
        
        setIsBackdrop(false);
        setIsMenuOpen(false);
    };

    const hasCustomPreview = () => {        
        const base64CustomPreview = getPreviewMaps.get(asset?.assetId ?? '');        
        return asset?.customPreviewId || base64CustomPreview;
    };

    const handleClose = () => {
        setIsMenuOpen(false);
    };

    const renderAddCustomThumbnailMenuItem = () => {
        return (
            <MenuItem disabled={isBackdropOpen}>                    
                {isBackdropOpen ? 
                (
                    <div>
                        <CircularProgress color="inherit" size={22} />
                        Uploading...
                    </div>
                ) : 
                (
                    <IconButton
                        className={classes.disableRipple}
                        color="inherit"
                        size="small"
                        component="label">
                        Add Custom Thumbnail
                        <input type="file" hidden onChange={handleChangeFile} />
                    </IconButton>
                )}
            </MenuItem>
        );
    };

    const renderReplaceCustomThumbnailMenuItem = () => {
        return (
            <MenuItem disabled={isBackdropOpen}>                    
                {isBackdropOpen ? (
                    <div>
                        <CircularProgress color="inherit" size={22} />
                        Uploading...
                    </div>
                ) : (
                    <IconButton
                        className={classes.disableRipple}
                        color="inherit"
                        size="small"
                        component="label">
                        Replace Custom Thumbnail
                        <input type="file" hidden onChange={handleChangeFile} />
                    </IconButton>
                )}
            </MenuItem>
        );
    }

    const renderDeleteCustomThumbnailMenuItem = () => {
        return (
            <MenuItem disabled={isBackdropOpen} onClick={() => setIsDeleteCustomPreviewConfirmationOpen(true)}>
                {isBackdropOpen ? (
                    <div>
                        <CircularProgress color="inherit" size={22} />...
                    </div>
                ) : (
                    <IconButton
                        className={classes.disableRipple}
                        color="inherit"
                        size="small"
                        component="label"
                    >
                        Delete Custom Thumbnail
                    </IconButton>
                )}
            </MenuItem>
        );
    }

    return (
        <>
            <IconButton className={classes.iconButton} onClick={(e) => handleOpenMenu(e)}>
                <Edit fontSize="default" />
            </IconButton>
            <Menu
                id="basic-menu"
                open={isMenuOpen}
                anchorEl={menuRef}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                    {!hasCustomPreview() && renderAddCustomThumbnailMenuItem()}
                    {
                        asset && hasCustomPreview() && 
                        (
                            <div>
                                {renderReplaceCustomThumbnailMenuItem()}
                                {renderDeleteCustomThumbnailMenuItem()}
                            </div>                                                                                            
                        )
                    }                
            </Menu>
            <ConfirmModal
                open={isDeleteCustomPreviewConfirmationOpen}
                setOpen={setIsDeleteCustomPreviewConfirmationOpen}
                onConfirm={handleDeleteCustomThumbnail}
                title="Permanently remove the existing Custom Thumbnail image?"
                message=""
            />
        </>
    );
};

export default observer(CustomPreviewMenu);
