import { CircularProgress, IconButton, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import useAssetDuplicateStyles from '../AssetDuplicate.styles';
import ClearIcon from '@material-ui/icons/Clear';
import { getTitles } from 'base/api/title';
import { Title } from 'base/types/title';

export interface IProps {
    onSearch: (titles: Title[]) => void;
    onSearchError: (error: any) => void;
}

const TitlePicker: React.FC<IProps> = ({ onSearch, onSearchError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');

    const classes = useAssetDuplicateStyles();

    const handleOnInputChange = (value: string) => {
        setValue(value);
        onSearch([]);

        if (value.length > 0) {
            searchTitles(value);
        }
    };

    const searchTitles = async (value: string) => {
        try {
            setIsLoading(true);
            const titles = await getTitles(value);
            onSearch(titles || []);
        } catch (err) {
            onSearchError(err);
        }

        setIsLoading(false);
    };

    const renderClearButton = () => {
        if (value) {
            return (
                <IconButton className={classes.clearBtn} onClick={() => handleOnInputChange('')}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            );
        }

        return null;
    };

    const renderFieldIndicator = () => {
        if (isLoading) {
            return <CircularProgress color="inherit" size={20} />;
        }

        return <SearchOutlined color="disabled" fontSize="small" />;
    };

    return (
        <TextField
            placeholder="Title Search"
            multiline
            fullWidth
            value={value}
            onChange={(e) => handleOnInputChange(e.target.value)}
            InputProps={{
                endAdornment: (
                    <React.Fragment>
                        {renderClearButton()}
                        {renderFieldIndicator()}
                    </React.Fragment>
                ),
            }}
        />
    );
};

export default TitlePicker;
