import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const height = '2.625rem';

export const ManageUsersTextField = withStyles({
    root: {
        width: 160,
        border: 0,
        borderRadius: 0,

        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            height: height,
            border: 0,
        },
        '& input': {
            color: '#000',
            textAlign: 'center',
            fontWeight: 'bold',
            borderRadius: 0,
            border: 0,
        },
    },
})(TextField);

export const AutoCompleteTextField = withStyles({
    root: {
        border: 0,
        borderRadius: 0,
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            height: height,
            border: 0,
        },
    },
})(TextField);

export const DivContainer = styled.div`
    display: flex;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
        0 1px 3px 0 rgb(0 0 0 / 12%);
    height: ${height};
    top: 0.75rem;
`;
