import { AuthenticationResponse } from 'base/models/UserInfo';
import { AssetStatus } from 'base/enums/AssetStatus';

export const LocalStorageKeys = {
    userInfo: 'userInfo',
    currentUser: 'localCurrentUser',
    currentFilter: 'localCurrentFilter',
};

export interface LocalCurrentUser {
    fullName?: string;
    email?: string;
}

export interface LocalCurrentFilter {
    currentFilter?: AssetStatus;
}

interface LocalStorageAccess {
    getFromLocalStorage: <T>(key: string) => T | undefined;
    setToLocalStorage: (key: string, value: object) => void;
    removeFromLocalStorage: (key: string) => void;
}

const getFromLocalStorage = <T>(key: string) => {
    const localValue = localStorage.getItem(key);
    if (localValue) {
        return JSON.parse(localValue) as T;
    }
    return undefined;
};

const setToLocalStorage = (key: string, value: object) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

const localStorageAccess: LocalStorageAccess = {
    getFromLocalStorage,
    setToLocalStorage,
    removeFromLocalStorage,
};

export const setLastPath = () => {
    const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    localStorage.setItem('lastPath', currentPath);
};

export const clearLastPath = () => {
    localStorage.removeItem('lastPath');
};

export const getLastPath = () => {
    return localStorage.getItem('lastPath');
};

export const retrieveUserInformation = () => {
    const isLogged = localStorageAccess.getFromLocalStorage<AuthenticationResponse>(
        LocalStorageKeys.userInfo
    );
    if (isLogged) return isLogged?.user;
};

export const retrieveLocalUser = () => {
    const localUser = localStorageAccess.getFromLocalStorage<LocalCurrentUser>(
        LocalStorageKeys.currentUser
    );
    if (localUser) return localUser;
};

export const retrieveLocalStatusFilter = () => {
    const localFilter = localStorageAccess.getFromLocalStorage<LocalCurrentFilter>(
        LocalStorageKeys.currentFilter
    );
    if (localFilter) return localFilter;
};

export default localStorageAccess;
