import { makeStyles, TableCell } from '@material-ui/core';
import styled from 'styled-components';

const borderColor = '#bababa';

const CellStaticContentRules = `
    position: static;
    left: 0;
    z-index: 1;
    background-color: #fff;
 `;

export const CellStaticContentBorderRight = styled(TableCell)`
    ${CellStaticContentRules}
    border-right: 1px solid ${borderColor};
    font-weight: bold;
    color: #575f72;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    position: sticky;
`;

export const FileNameAndAvatarContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
export const useStyles = makeStyles((theme) => ({
    holdback: {
        backgroundColor: '#c44039',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    tvdOnly: {
        backgroundColor: '#ed9a4b',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    exclusive: {
        backgroundColor: '#ed9a4b',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    restrictions: {
        backgroundColor: '#c34039',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    unpublished: {
        backgroundColor: '#be5f94',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    inProgress: {
        backgroundColor: '#f7d658',
        color: '#7d7a6b',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    error: {
        backgroundColor: '#c44039',
        color: '#ffffff',
        margin: '5px',
        height: 'auto',
        fontWeight: 'normal',
    },
    smallIcon: {
        width: 12,
        height: 12,
    },
}));
export const FileNameContainer = styled.div`
    max-width: 250px;
    min-width: 250px;
    word-break: break-all;

    color: #444a55;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    display: inline-flex;
    align-items: center;
    text-align: left;
`;

export const TableCellField = styled(TableCell)`
    padding: 6px 16px;
    min-width: 150px;
`;
