import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridOverlay,
    GridSelectionModelChangeParams,
    GridSortModel,
    GridRowId,
    GridColDef,
} from '@material-ui/data-grid';
import {
    useStyles,
    DivError,
    DivButtonEdit,
    DivButtonDelete,
    DivButtonContainer,
    DivButtonPublish,
} from './AssetGrid.styles';
import { groupType } from 'base/models/DashboardProps';
import { Asset, Assets } from 'base/models/Asset';
import { getAssetsGrouped, getAssetsHoldback } from 'base/api/dashboard';
import { AssetStatus } from 'base/enums/AssetStatus';
import { ColumnsType, ColumnsTitle } from './Columns/Columns';
import { useRootStoreContext } from 'base/stores/rootStore';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'base/constants/route';
import { getAssetsWithUploadProperties, getTemplateFromCached } from '../Dashboard.functions';
import { ErrorOutline, CheckCircleOutline } from '@material-ui/icons';
import { Snackbar, SnackbarContent, Button } from '@material-ui/core';
import { putStatusUpdate } from 'base/api/updateStatus';
import { Template } from 'base/models/Template';
import { observer } from 'mobx-react-lite';
import { UpdateCounter } from 'base/utils/dasboardCounter';
import { Alert, Color } from '@material-ui/lab';
import { deleteDraft, getDraftsFromAssets } from 'base/api/draft';
import { Loading } from 'components/Dashboard/Loading';
import { hasTitleOrAssetGroup } from 'base/utils/editFunctions';
import { retrieveLocalStatusFilter } from 'base/utils/localStorageAccess';
import { getTemplateIdsFromAssets } from './AssetGrid.functions';
import { ConfirmModal } from 'components/shared/ConfirmModal';
import { useQueryClient } from 'react-query';
import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';

interface GroupedAsset {
    id: string;
    mam: string;
    createdAt: Date;
    updatedAt: Date;
    jobId: string;
    assetType: string;
    title: string;
    assets: Assets;
    user: string;
    totalAssets: number;
}

type GroupedAssets = GroupedAsset[];

const emptyRow: GroupedAsset = {
    id: '',
    mam: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    jobId: '',
    assetType: '',
    title: '',
    assets: [],
    user: '',
    totalAssets: 0,
};

const emptyData: GroupedAssets = [];
emptyData.push(emptyRow);

type AssetGridProps = {
    statusFilter: AssetStatus;
    columnType: groupType;
    className: string;
};

const defaultSort: GridSortModel = [
    {
        field: 'updatedAt',
        sort: 'desc',
    },
];

interface UpdateStatusInfo {
    status: number;
    ids: string[];
}

const AssetGrid: React.FC<AssetGridProps> = ({
    statusFilter,
    columnType,
    className,
}: AssetGridProps) => {
    const [isError, setIsError] = useState(false);
    const [columnsDisplay, setColumnsDisplay] = useState(ColumnsType(() => handleBulkEditClick()));
    const [selectedTitles, setSelectedTitles] = useState<GridRowId[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const history = useHistory();
    const { setAssetsInStore, removeAssetsFromStore } = useRootStoreContext().ingestionStore;
    const {
        setIsExternalEditingInStore,
        setRequestIdInStore,
        startTabsInEditStore,
        startTabsForMultipleAssetsInEditStore,
    } = useRootStoreContext().editStore;
    const [isStatusUpdated, setIsStatusUpdated] = useState(false);
    const [displayMessage, setDisplayMessage] = useState('');
    const [isSuccesfull, setIsSuccesfull] = useState(false);
    const {
        setStatusTotal,
        processAssets,
        getUserFilterInStore: userFilter,
        gridAssets,
        filteredAssets,
        getSnackbarSettings: snackbarSettings,
        setSnackbarSettings,
        setIsLoadingGrid: setIsLoading,
        getIsLoadingGrid: isLoading,
    } = useRootStoreContext().dashboardStore;
    const isEditEnabled = statusFilter !== AssetStatus.InProgress;
    const isDeleteEnabled = statusFilter === AssetStatus.Failed;
    const isPublishEnabled = statusFilter === AssetStatus.NotPublished;
    const isUnpublishEnabled = statusFilter === AssetStatus.Published;

    const handleCloseCustomPreviewAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarSettings(false, '', '');
    };

    const templateQueryClient = useQueryClient();

    async function getData() {
        if (userFilter.email) {
            const localStorageFilter = retrieveLocalStatusFilter();
            let filter: AssetStatus = statusFilter;
            if (localStorageFilter?.currentFilter) {
                filter =
                    statusFilter !== localStorageFilter.currentFilter
                        ? localStorageFilter.currentFilter
                        : statusFilter;
            }

            if (filter === AssetStatus.Holdback) {
                const { response, error } = await getAssetsHoldback(userFilter.email, columnType);
                if (response && response.data) {
                    setIsError(false);
                    setIsLoading(false);
                    processAssets(response.data);
                }
                if (error) {
                    setIsError(true);
                }
            } else {
                const { response, error } = await getAssetsGrouped(
                    filter,
                    columnType,
                    userFilter.email
                );
                if (response && response.data) {
                    setIsError(false);
                    setIsLoading(false);
                    processAssets(response.data);
                }
                if (error) {
                    setIsError(true);
                }
            }
        }
    }

    const classes = useStyles();

    useEffect(() => {
        if (userFilter.email) {
            setIsLoading(true);
            getData();
            const editIndex = columnsDisplay.findIndex((c) => c.field === 'edit');
            columnsDisplay[editIndex].hide = !isEditEnabled;
            setColumnsDisplay([...columnsDisplay]);
        }
    }, [statusFilter, userFilter.fullname]);

    useEffect(() => {
        setIsLoading(true);

        let newColumnsDisplay: GridColDef[] = [];

        switch (columnType) {
            case groupType.TYPE_TITLE:
                newColumnsDisplay = ColumnsType(() => handleBulkEditClick());
                break;
            case groupType.TITLE:
                newColumnsDisplay = ColumnsTitle;
                break;
        }
        const editIndex = newColumnsDisplay.findIndex((c) => c.field === 'edit');
        newColumnsDisplay[editIndex].hide = !isEditEnabled;
        setColumnsDisplay([...newColumnsDisplay]);
        getData();
    }, [columnType]);

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Assets found</div>
            </GridOverlay>
        );
    }

    const handleRowSelection = (e: GridSelectionModelChangeParams) => {
        setColumnsDisplay([...columnsDisplay]);
        setSelectedTitles(e.selectionModel as string[]);
    };

    const fetchTemplate = async (templateId: string) => {
        const queryObject: any = templateQueryClient.getQueryData([
            QUERY_IDENTIFIERS.ASSET_TEMPLATES,
        ]);

        if (queryObject && queryObject.data) {
            const template = getTemplateFromCached(templateId, queryObject.data);
            if (template) return template;
        }
    };

    const handleBulkEditClick = async () => {
        setIsLoading(true);
        const assets = selectedTitles.reduce((groupedAssets, rowId) => {
            const row = gridAssets.find((asset: GroupedAsset) => asset.id === rowId);
            if (row) {
                return [...groupedAssets, ...row.assets];
            }
            return groupedAssets;
        }, [] as Assets);

        const templatesIdValue: Map<string, Template> = getTemplateIdsFromAssets(
            assets,
            fetchTemplate
        );

        const [firstAsset] = assets;

        if (
            firstAsset.status.id === AssetStatus.Draft ||
            firstAsset.status.id === AssetStatus.Failed
        ) {
            const assetsWithUploadProperties = getAssetsWithUploadProperties(assets);

            removeAssetsFromStore();
            if (!hasTitleOrAssetGroup(firstAsset)) setAssetsInStore(assetsWithUploadProperties); // for the unassigned assets

            const assetToRedirect: Asset | undefined = assets.find(
                (asset: Asset) => asset.assetGroup && asset.assetGroup.name
            );

            if (assetsWithUploadProperties.length === 1) {
                let template: any;
                if (firstAsset && firstAsset.templateId) {
                    template = await fetchTemplate(firstAsset.templateId);
                    startTabsInEditStore(assetsWithUploadProperties, template);
                }
            } else {
                startTabsForMultipleAssetsInEditStore(assetsWithUploadProperties, templatesIdValue);
            }
            setIsLoading(false);
            if (assetToRedirect)
                history.push({
                    pathname: `/${ROUTES.EDIT}`,
                    state: { assetGroupName: assetToRedirect.assetGroup.name },
                });
            else history.push(`/${ROUTES.EDIT}`);
        } else {
            const assetIds = assets.map((asset: Asset) => asset.assetId);
            const { response } = await getDraftsFromAssets(assetIds);
            const payload = response?.data;
            if (payload) {
                const assetsWithUploadProperties = getAssetsWithUploadProperties(payload.assets);
                const assets = assetsWithUploadProperties.map((asset: Asset) => {
                    return {
                        ...asset,
                        status: payload.assets[0].status,
                    };
                });
                if (!hasTitleOrAssetGroup(firstAsset)) setAssetsInStore(assets);

                const assetToRedirect: Asset | undefined = payload.assets.find(
                    (asset: Asset) => asset.assetGroup && asset.assetGroup.name
                );

                const [payloadAsset] = payload.assets;

                setIsExternalEditingInStore(true);
                setRequestIdInStore(payload.requestId);

                if (assets.length === 1) {
                    let template: any;
                    if (payloadAsset && payloadAsset.templateId) {
                        template = await fetchTemplate(payloadAsset.templateId);
                        startTabsInEditStore(assets, template);
                    }
                } else {
                    startTabsForMultipleAssetsInEditStore(assets, templatesIdValue);
                }

                setIsLoading(false);
                if (assetToRedirect)
                    history.push({
                        pathname: `/${ROUTES.EDIT}`,
                        state: { assetGroupName: assetToRedirect.assetGroup.name },
                    });
                else history.push(`/${ROUTES.EDIT}`);
            }
        }
    };

    const handleBulkPublishClick = () => {
        const assets = selectedTitles.reduce((groupedAssets, rowId) => {
            const row = gridAssets.find((asset: GroupedAsset) => asset.id === rowId);
            if (row) {
                return [...groupedAssets, ...row.assets];
            }
            return groupedAssets;
        }, [] as Assets);

        updateAssetStatus(assets, AssetStatus.Published);
    };

    const handleBulkUnpublishClick = () => {
        const assets = selectedTitles.reduce((groupedAssets, rowId) => {
            const row = gridAssets.find((asset: GroupedAsset) => asset.id === rowId);
            if (row) {
                return [...groupedAssets, ...row.assets];
            }
            return groupedAssets;
        }, [] as Assets);

        updateAssetStatus(assets, AssetStatus.NotPublished);
    };

    const handlePublishMessageClose = () => {
        setIsStatusUpdated(false);
    };

    const handleBulkDeleteAssets = async () => {
        setIsLoading(true);
        const assets = selectedTitles.reduce((groupedAssets, rowId) => {
            const row = gridAssets.find((asset: GroupedAsset) => asset.id === rowId);
            if (row) {
                return [...groupedAssets, ...row.assets];
            }
            return groupedAssets;
        }, [] as Assets);

        const assetDeletePromises = assets.map((asset) => deleteDraft(asset.id));

        await Promise.all(assetDeletePromises);
        setIsStatusUpdated(true);
        setDisplayMessage(`${assets.length} asset(s) deleted`);

        const filter: AssetStatus = statusFilter;
        const { response, error } = await getAssetsGrouped(filter, columnType, userFilter.email);
        if (response && response.data) {
            setIsError(false);
            processAssets(response.data);

            UpdateCounter(userFilter.email).then((val) => {
                setStatusTotal(val);
            });
        }
        if (error) {
            setIsError(true);
        }

        setIsLoading(false);
    };

    async function updateAssetStatus(assets: Assets, status: AssetStatus) {
        let assetIdCollection: string[] = [];

        assets.forEach(({ id }) => {
            assetIdCollection = [...assetIdCollection, id];
        });

        const dataSent: UpdateStatusInfo = {
            status: status,
            ids: assetIdCollection,
        };

        const resolvePayload = await putStatusUpdate(dataSent);

        setIsStatusUpdated(true);
        if (resolvePayload.response?.status === 200) {
            setIsSuccesfull(true);
            const newStatusText =
                status === AssetStatus.NotPublished ? 'Not Published' : 'Published';
            const assetOrAssets = assetIdCollection.length === 1 ? ' asset' : ' assets';
            setDisplayMessage(
                assetIdCollection.length + assetOrAssets + ' updated to ' + newStatusText
            );
            getData();
            UpdateCounter(userFilter.email).then((val) => {
                setStatusTotal(val);
            });
        } else {
            setDisplayMessage('Error updating assets');
        }
    }

    if (isLoading) return <Loading />;
    if (isError)
        return (
            <DivError>
                <ErrorOutline style={{ fontSize: 32 }} /> Error loading data
            </DivError>
        );
    return (
        <div className={`${className}`}>
            <DivButtonContainer>
                <DivButtonPublish>
                    {isPublishEnabled && (
                        <Button
                            className={classes.button}
                            disabled={selectedTitles.length === 0}
                            onClick={handleBulkPublishClick}
                        >
                            Publish Selected
                        </Button>
                    )}
                    {isUnpublishEnabled && (
                        <Button
                            className={classes.button}
                            disabled={selectedTitles.length === 0}
                            onClick={handleBulkUnpublishClick}
                        >
                            Unpublish Selected
                        </Button>
                    )}
                </DivButtonPublish>
                <DivButtonDelete>
                    {isDeleteEnabled && (
                        <Button
                            className={classes.button}
                            disabled={selectedTitles.length === 0}
                            onClick={() => setIsDeleteModalOpen(true)}
                        >
                            Delete Selected
                        </Button>
                    )}
                </DivButtonDelete>
                <DivButtonEdit>
                    {isEditEnabled && (
                        <Button
                            className={classes.button}
                            disabled={selectedTitles.length === 0}
                            onClick={handleBulkEditClick}
                        >
                            Edit Selected
                        </Button>
                    )}
                </DivButtonEdit>
            </DivButtonContainer>
            <DataGrid
                className={`${classes.root}  ${selectedTitles?.length ? 'marked-title' : ''}`}
                rows={filteredAssets}
                columns={columnsDisplay}
                pageSize={15}
                autoHeight={true}
                sortModel={defaultSort}
                sortingOrder={['desc', 'asc']}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                checkboxSelection
                onSelectionModelChange={handleRowSelection}
                disableColumnMenu
                disableSelectionOnClick
            />

            <Snackbar
                open={snackbarSettings.isOpen}
                autoHideDuration={6000}
                key="custom-preview-snackbar"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setSnackbarSettings(false, '', '')}
            >
                <Alert
                    onClose={handleCloseCustomPreviewAlert}
                    severity={(snackbarSettings.severity as Color) || 'info'}
                >
                    {snackbarSettings.message}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={isStatusUpdated}
                onClose={handlePublishMessageClose}
                autoHideDuration={5000}
            >
                <SnackbarContent
                    className={classes.snackbar}
                    message={
                        <span className={classes.message}>
                            {isSuccesfull ? (
                                <CheckCircleOutline className={classes.iconVariant} />
                            ) : (
                                <ErrorOutline className={classes.iconVariant} />
                            )}
                            {displayMessage}
                        </span>
                    }
                />
            </Snackbar>
            <ConfirmModal
                open={isDeleteModalOpen}
                setOpen={setIsDeleteModalOpen}
                onConfirm={handleBulkDeleteAssets}
                title="Confirm Delete"
                message="Are you sure you want to delete the selected Asset(s)?"
            />
        </div>
    );
};

export default observer(AssetGrid);
