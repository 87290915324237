import { publishDraftPreview } from 'base/api/preview';
import { Asset } from 'base/models/Asset';
import { useRootStoreContext } from 'base/stores/rootStore';
import { TransferFile, TransferInfo } from 'base/types/aspera';
import { useEffect, useState } from 'react';

const TRANSFER_TIME_INTERVAL = 500;

const useAsperaTransfer = (
    aspera: any,
    transferId: string,
    originalFileName: string,
    assetId: string,
    tabName = '',
    titleName = '',
    isEditGrid: boolean,
    asset: Asset
) => {
    const { setAsperaAssetProgressInStore } = useRootStoreContext().ingestionStore;
    const { setAsperaAssetProgressInEditStore } = useRootStoreContext().editStore;
    const { upsertUploadItem } = useRootStoreContext().transferStore;
    const [transferInfo, setTransferInfo] = useState<TransferInfo | null>(null);
    const [errorTransferId, setErrorTransferId] = useState(null);
    const [isPublishedDraft, setIsPublishedDraft] = useState(false);

    const curateTransferInfo = async (transferInfo: TransferInfo) => {
        if (transferInfo.files && originalFileName && assetId) {
            const transferAsset = transferInfo.files.find((source: TransferFile) => {
                const normalizedFileName = source.file.replaceAll('/', '\\'); // Mac and Windows fileName
                const splittedName = normalizedFileName.split('\\');
                const fileName = splittedName[splittedName.length - 1];

                return fileName === originalFileName ? source : null;
            });

            if (
                transferAsset &&
                transferAsset.bytes_expected > 0 &&
                transferAsset.fasp_file_id === transferId
            ) {
                const progress =
                    Math.round(
                        ((transferAsset.bytes_written * 100) / transferAsset.bytes_expected +
                            Number.EPSILON) *
                            100
                    ) / 100;

                if (isEditGrid) {
                    setAsperaAssetProgressInEditStore(tabName, titleName, assetId, progress);
                } else setAsperaAssetProgressInStore(assetId, progress, false);

                upsertUploadItem(transferId, {
                    ...asset,
                    uploadProperties: {
                        ...(asset?.uploadProperties || {}),
                        progress,
                        isUploaded: progress === 100,
                    },
                });
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(async () => {
            if (
                transferId &&
                transferInfo?.status !== 'completed' &&
                transferInfo?.status !== 'removed' &&
                !errorTransferId
            ) {
                aspera.client.getTransfer(transferId, {
                    success: (data: any) => {
                        setTransferInfo(data.transfer_info);
                        curateTransferInfo(data.transfer_info);
                    },
                    error: (err: any) => {
                        if (err) {
                            setErrorTransferId(err);
                            setAsperaAssetProgressInStore(assetId, 0, true); // TODO: handle error on editStore as well
                        }
                    },
                });
            }
        }, TRANSFER_TIME_INTERVAL);

        return () => clearInterval(interval);
    }, [transferId, transferInfo, originalFileName, assetId, errorTransferId]);

    useEffect(() => {
        const publishPreviewEvent = async () => {
            setIsPublishedDraft(true);
            await publishDraftPreview(asset);
        };
        if (!asset.uploadProperties?.isUploaded && !isPublishedDraft) {
            publishPreviewEvent();
        }
    }, [asset.uploadProperties?.isUploaded]);
};

export default useAsperaTransfer;
