import React, { useEffect, useRef } from 'react';

import {
    ButtonUpload,
    MainUploadParagraph,
    SecondUploadParagraph,
    UploadIconContainer,
    useStyles,
} from './ReplacementUpload.styles';

import { Paper } from '@material-ui/core';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { useDropzone } from 'react-dropzone';
import { onDropReplacementAssetsAspera } from '../../../../Upload/Upload.functions';
import { useRootStoreContext } from 'base/stores/rootStore';
import { Aspera } from 'base/utils/aspera-client';
import { executeTransfer, getDraftReplacement } from 'base/services/aspera';
import { DraftReplacement } from 'base/models/Replacement';
import { Asset } from 'base/models/Asset';
import { dataURLtoFile } from 'components/EditAsset/AssetItem/AssetItem.functions';
interface SmallUploadProps {
    textButton: string;
    textContent: string;
    setDraftReplacement: React.Dispatch<React.SetStateAction<DraftReplacement | null>>;
    setAssetReplacement: React.Dispatch<React.SetStateAction<Asset | null>>;
    setNewAssetUrl: React.Dispatch<React.SetStateAction<string>>;
    currentAsset: Asset | null;
    onCloseModal: () => void;
}

const dragAndDropSelector = 'drag-and-drop';

export const ReplacementUpload = ({
    textButton,
    textContent,
    setDraftReplacement,
    setAssetReplacement,
    setNewAssetUrl,
    currentAsset,
    onCloseModal,
}: SmallUploadProps) => {
    const { current: aspera } = useRef(new Aspera());

    const classes = useStyles();
    const {
        setAssetsInStore,
        initializeJobInStore,
        updateAssetsWithTransferId,
        setIsStartingUploadInStore,
    } = useRootStoreContext().ingestionStore;
    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
    });

    const setupDraftReplacement = async (event: any, isDragAndDrop: boolean) => {
        const data = await onDropReplacementAssetsAspera(
            event,
            isDragAndDrop,
            initializeJobInStore,
            currentAsset
        ); // build the data for the asset
        if (data && currentAsset) {
            const { assets, localAsperaPaths } = data;
            const [newAsset] = assets;
            const [asperaPath] = localAsperaPaths;
            const draftReplacement = getDraftReplacement(currentAsset, asperaPath, newAsset); // create the draftReplacement

            const isReplacement = true;
            executeTransfer(
                data.transferSpec,
                data.assets,
                aspera,
                setIsStartingUploadInStore,
                setAssetsInStore,
                updateAssetsWithTransferId,
                isReplacement
            ); // start the transfer of the asset through Aspera

            setDraftReplacement(draftReplacement); // for the parent component we set the data
            setAssetReplacement(newAsset);

            aspera.client.readAsArrayBuffer(
                // retrieve the asset using Aspera to show it in the page
                { path: asperaPath.source },
                {
                    success: (dataFile: any) => {
                        const file = dataURLtoFile(
                            dataFile.data,
                            dataFile.type,
                            newAsset.fields.fileName,
                            false
                        );
                        const objectURL = URL.createObjectURL(file);
                        setNewAssetUrl(objectURL as unknown as string);
                    },
                    error: (err: any) => err,
                }
            );
        }
    };

    const handleSelectAssetsFromDialog = async () => {
        const dialogOptions: any = {
            allowMultipleSelection: false,
            allowedFileTypes: [
                {
                    filter_name: 'All types',
                    extensions: ['*'],
                },
            ],
        };
        aspera.init();
        aspera.client.showSelectFileDialog(
            {
                success: async (event: any) => {
                    const isThereAnySelectedFiles = event.dataTransfer.files.length > 0;
                    if (isThereAnySelectedFiles) {
                        setIsStartingUploadInStore(true);
                        await setupDraftReplacement(event, false);
                    }
                },
                error: (error: any) => error,
            },
            dialogOptions
        );
    };

    const handleDragAndDropAssets = async (event: any) => {
        setIsStartingUploadInStore(true);

        await setupDraftReplacement(event, true);
    };

    useEffect(() => {
        aspera.validateStatusAndInstallation();
        aspera.client.setDragDropTargets(`#${dragAndDropSelector}`, {}, handleDragAndDropAssets);
        aspera.init();
    }, []);

    return (
        <div {...getRootProps()} id={dragAndDropSelector}>
            <input {...getInputProps()} />
            <Paper elevation={0} className={classes.paper}>
                <div className={classes.root}>
                    <UploadIconContainer>
                        <PublishOutlinedIcon style={{ fontSize: '50px' }} />
                    </UploadIconContainer>
                    <MainUploadParagraph>Drag and drop your files to upload</MainUploadParagraph>
                    <SecondUploadParagraph>or</SecondUploadParagraph>
                    <ButtonUpload
                        disableRipple
                        variant="contained"
                        onClick={handleSelectAssetsFromDialog}
                    >
                        Select Files
                    </ButtonUpload>
                </div>
            </Paper>
        </div>
    );
};

export default ReplacementUpload;
