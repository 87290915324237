import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from './ContextSwitcher.style';
import { FormControl } from '@material-ui/core';
import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { getContextSwitcherByUser } from 'base/api/contextSwitcher';
import { ContextSwitcherList } from 'base/models/ContextSwitcher';
import localStorageAccess, {
    LocalStorageKeys,
    retrieveUserInformation,
} from 'base/utils/localStorageAccess';
import { AuthenticationResponse, UserInfo } from 'base/models/UserInfo';
import { getAuthSettings } from 'base/api/settings';
import { AuthSettings } from 'base/models/AuthSettings';

const ContextSwitcher: React.FC = () => {
    const classes = useStyles();
    const [appValues, setAppValues] = useState<ContextSwitcherList>([]);

    const { setPortalId } = useRootStoreContext().dashboardStore;
    const { setContextSwitcherData, getContextSwitcherData } =
        useRootStoreContext().contextSwitcherStore;
    const { setAuthSettings } = useRootStoreContext().settingsStore;
    const userInfo: UserInfo | undefined = retrieveUserInformation();

    const [appId, setAppId] = useState<string>('');

    const retrieveContextSwitcherData = async () => {
        const token = localStorageAccess.getFromLocalStorage<AuthenticationResponse>(
            LocalStorageKeys.userInfo
        )?.token;
        if (token) {
            const contextSwitcherList = await getContextSwitcherByUser('ingest-ui');
            const payload = contextSwitcherList.response?.data;
            if (payload) {
                setAppValues(payload);
                setContextSwitcherData(payload);

                const applicationUserContext = payload.find(
                    (data) => data.appId === userInfo?.applicationId
                );
                const uidContext = applicationUserContext
                    ? applicationUserContext.uid
                    : payload[0].uid;
                setPortalId(uidContext);
                setAppId(uidContext);
                setAuthSettingsForSelectedApp(
                    applicationUserContext ? applicationUserContext.appId : payload[0].appId
                );
            }
        }
    };

    const setAuthSettingsForSelectedApp = async (applicationId: number) => {
        const payload = await getAuthSettings(applicationId);
        const authSettings = payload.response?.data;
        if (authSettings) {
            setAuthSettings(authSettings as AuthSettings);
        }
    };

    useEffect(() => {
        retrieveContextSwitcherData();
    }, []);

    const handleChange = (targetValue: string) => {
        setPortalId(targetValue);
        setAppId(targetValue);

        const selectedAppId = getContextSwitcherData.find((c) => c.uid === targetValue)?.appId;
        if (selectedAppId) {
            setAuthSettingsForSelectedApp(selectedAppId);
        }
    };

    return (
        <FormControl required>
            <Select
                className={classes.selectFilter}
                value={appId}
                onChange={(event) => handleChange(event.target.value as string)}
            >
                {appValues?.map((option) => {
                    return (
                        <MenuItem key={option.uid} value={option.uid}>
                            {option.applicationName ?? option.applicationName}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default observer(ContextSwitcher);
