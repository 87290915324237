import React, { useEffect, useRef, useState } from 'react';
import { ListItem, ListItemText, LinearProgress, Checkbox, Tooltip } from '@material-ui/core';
import { PriorityHigh, StopOutlined, PlayArrowOutlined } from '@material-ui/icons';

import { observer } from 'mobx-react-lite';

import { useRootStoreContext } from 'base/stores/rootStore';
import {
    useStyles,
    DivBreakWordContainer,
    UploadInfoContainer,
    MainContainer,
} from './AssetItem.styles';
import { Preview } from 'components/shared/Preview';
import { Asset } from 'base/models/Asset';

import { dataURLtoFile, validateTiffFileAndResize } from './AssetItem.functions';
import useAsperaTransfer from 'base/hooks/useAsperaTransfer';

import { Aspera } from 'base/utils/aspera-client';

export interface AssetItemProps {
    assetProgress: number;
    isCancelled: boolean;
    isUploaded: boolean;
    hasError: boolean;
    hasStartUpload: boolean;
    isChecked: boolean;
    asset: Asset;
    filePath: string;
}

const AssetItem: React.FC<AssetItemProps> = ({
    assetProgress,
    isCancelled,
    isUploaded,
    hasError,
    isChecked,
    asset,
}: AssetItemProps) => {
    const styles = useStyles();
    const { current: aspera } = useRef(new Aspera());
    aspera.init();
    const previewContainer = React.useRef(null);
    const { setAssetCheckByIdInStore } = useRootStoreContext().ingestionStore;
    const { addPreview } = useRootStoreContext().previewStore;

    const [isTransferStopped, setIstransferStopped] = useState(false);

    useAsperaTransfer(
        aspera,
        asset.asperaTransferId ?? '',
        asset.fields.originalFileName ?? '',
        asset.id,
        '',
        '',
        false,
        asset
    );

    const handleStop = () => {
        setIstransferStopped(true);
        if (asset.asperaTransferId) {
            aspera.stopTransfer(asset.asperaTransferId);
        }
    };

    const handleResume = () => {
        setIstransferStopped(false);
        if (asset.asperaTransferId) {
            aspera.resumeTransfer(asset.asperaTransferId);
        }
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checkValue = e.target.checked;
        setAssetCheckByIdInStore(asset.assetId, checkValue);
    };

    const createFileFromAspera = (aspera: any, path: string) => {
        aspera.client.readAsArrayBuffer(
            { path },
            {
                success: (dataFile: any) => {
                    const file = dataURLtoFile(
                        dataFile.data,
                        dataFile.type,
                        asset.fields.fileName,
                        false
                    );
                    if (file) {
                        validateTiffFileAndResize(asset, file, addPreview);
                    }
                },
                error: (err: any) => err,
            }
        );
    };

    useEffect(() => {
        if (asset.uploadProperties.asperaPath) {
            createFileFromAspera(aspera, asset.uploadProperties.asperaPath);
        }
    }, [asset.uploadProperties.asperaPath]);

    return !isCancelled ? (
        <MainContainer key={asset.assetId}>
            <ListItem>
                <div>
                    <Checkbox
                        color="primary"
                        edge="start"
                        onChange={handleCheckboxChange}
                        checked={isChecked}
                    />
                </div>
                {isUploaded && previewContainer && (
                    <div className={styles.listItemIcon}>
                        <Preview
                            isFirstPreview={true}
                            previewStyle={''}
                            asset={asset}
                            firstPreviewUrl={''}
                        />
                        <div ref={previewContainer}></div>
                    </div>
                )}
                {hasError && !isUploaded && (
                    <div className={styles.listItemIcon}>
                        <Tooltip
                            title="Some of the uploading chunks have failed, please retry to complete the upload"
                            aria-label="retry-error"
                        >
                            <PriorityHigh color="error" />
                        </Tooltip>
                    </div>
                )}
                <div className={styles.assetProgressContainer}>
                    <div>
                        <DivBreakWordContainer>
                            <ListItemText primary={asset.fields.fileName} />
                        </DivBreakWordContainer>
                        {!hasError && !isUploaded && (
                            <LinearProgress variant="determinate" value={assetProgress} />
                        )}

                        {!isUploaded && (
                            <div className={styles.uploadButtons}>
                                <UploadInfoContainer>
                                    <span>{`${Math.round(assetProgress)}%`}</span>
                                </UploadInfoContainer>

                                {!isTransferStopped ? (
                                    <UploadInfoContainer>
                                        <Tooltip title="Stop Transfer">
                                            <button
                                                className={styles.iconButton}
                                                onClick={handleStop}
                                            >
                                                <StopOutlined style={{ fontSize: '14px' }} />
                                            </button>
                                        </Tooltip>
                                    </UploadInfoContainer>
                                ) : (
                                    <UploadInfoContainer>
                                        <Tooltip title="Resume Transfer">
                                            <button
                                                className={styles.iconButton}
                                                onClick={handleResume}
                                            >
                                                <PlayArrowOutlined style={{ fontSize: '14px' }} />
                                            </button>
                                        </Tooltip>
                                    </UploadInfoContainer>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ListItem>
        </MainContainer>
    ) : (
        <></>
    );
};

export default observer(AssetItem);
