import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import useAssetDuplicateStyles from '../AssetDuplicate.styles';
import { Title } from 'base/types/title';

export interface IProps {
    icon: 'add' | 'remove';
    titles: Title[];
    onItemClick: (title: Title, index: number) => void;
}

const TitleCollection: React.FC<IProps> = ({ icon, titles, onItemClick }) => {
    const classes = useAssetDuplicateStyles();

    const handleOnItemClick = (title: Title, index: number) => {
        onItemClick(title, index);
    };

    const itemTitle = `${icon === 'add' ? 'Add' : 'Remove'} title`;
    const listClassName = titles.length > 0 ? classes.titleList : undefined;

    return (
        <List className={listClassName}>
            {titles.map((item, idx) => {
                return (
                    <ListItem
                        key={item.id}
                        title={itemTitle}
                        className={classes.titleItem}
                        onClick={() => handleOnItemClick(item, idx)}
                    >
                        <ListItemIcon className={classes.titleItemIcon}>
                            {icon === 'add' ? (
                                <AddBoxOutlined />
                            ) : (
                                <IndeterminateCheckBoxOutlinedIcon />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            key={`${icon}-title-${item.id}`}
                            primary={`${item.identifiers.productId} - ${item.name?.primary}`}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default TitleCollection;
