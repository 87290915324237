import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Outline } from 'layouts/Outline';
import { Home } from 'pages/Home';
import { UploadPage } from 'pages/Upload';
import { Login, LoginCallback, DmdcLoginCallback } from 'pages/Auth';
import { ROUTES } from 'base/constants/route';
import PrivateRoute from './PrivateRoute/PrivateRoute.component';
import { RequestToken } from 'pages/RequestToken';
import { EditFromApp } from 'pages/EditFromApp';
import { AddFromApp } from 'pages/AddFromApp';
import { Reauth } from 'pages/Reauth';
import { UploadScreeners } from 'pages/DigitalScreeners/Index';

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Outline>
                <Switch>
                    <PrivateRoute component={Home} path="/" exact />
                    <PrivateRoute component={UploadPage} path={`/${ROUTES.UPLOAD}`} />
                    <PrivateRoute component={UploadPage} path={`/${ROUTES.EDIT}`} />
                    <PrivateRoute
                        component={EditFromApp}
                        path={`/${ROUTES.EDIT_FROM_APPLICATION}`}
                    />
                    <PrivateRoute component={AddFromApp} path={`/${ROUTES.ADD_FROM_APPLICATION}`} />
                    <Route component={Login} path={`/${ROUTES.LOGIN}`} exact />
                    <Route component={LoginCallback} path={`/${ROUTES.LOGIN_CALLBACK}`} exact />
                    <Route
                        component={DmdcLoginCallback}
                        path={`/${ROUTES.LOGIN_CALLBACK_DMDC}`}
                        exact
                    />

                    <PrivateRoute
                        component={RequestToken}
                        path={`/${ROUTES.REQUEST_TOKEN}`}
                        exact
                    />
                    <PrivateRoute component={Reauth} path={`/${ROUTES.REAUTH}`}></PrivateRoute>
                    <PrivateRoute
                        component={UploadScreeners}
                        path={`/${ROUTES.UPLOADSCREENERS}`}
                    ></PrivateRoute>
                </Switch>
            </Outline>
        </BrowserRouter>
    );
};

export default Router;
