import React from 'react';
import { useStyles } from './UploadWidget.style';
import { ScreenersWidgetProps } from 'base/enums/ActionCodes';
import { Grid, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserActionFlags } from 'base/models/AuthSettings';

interface UploadWidgetProps {
    token: string;
    dsPortalID: string;
    userActionFlags: UserActionFlags;
    widgetUserActions: string;
}

const UploadWidget: React.FC<UploadWidgetProps> = ({ token, dsPortalID, userActionFlags, widgetUserActions }) => {
    const classes = useStyles();
    const isMinWidth1405px = useMediaQuery('(min-width:1405px)');

    const canUploadMediaFilesForPackaging = userActionFlags && userActionFlags.canUploadMediaFilesForPackaging === true;
    const canViewUploadStatus = userActionFlags && userActionFlags.canViewUploadStatus === true;
    return (        
        <Box display="flex" flexWrap="wrap">
            <Grid container spacing={3}>
                <Grid item xs></Grid>
                <Grid item xs className={classes.buttonContainer}>
                    <div className={classes.buttonGrid}>
                        {canUploadMediaFilesForPackaging && 
                            (<a className={isMinWidth1405px ? classes.buttonStyle : classes.smallButtonStyle} href="#/upload" id="uploadBtn">Upload Video/Components</a>)
                        }
                    </div>
                    <div className={classes.buttonGrid}>
                        {(canViewUploadStatus || canUploadMediaFilesForPackaging) && 
                            (<a className={isMinWidth1405px ? classes.buttonStyle : classes.smallButtonStyle} href="#/status" id="statusBtn">Package Ingest Status</a>)
                        }
                    </div>
                </Grid>
                <Grid item xs></Grid>
            </Grid>            
            <Grid item xs={12} className={classes.widgetContainer}>
                <ds-media-manager
                    token={token}
                    x-ds-portal-id={dsPortalID}
                    context={ScreenersWidgetProps.Context}
                    userActionFlags={widgetUserActions}
                />
            </Grid>
        </Box>
    );
};

export default UploadWidget;
