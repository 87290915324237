import React from 'react';

export const Buttons = {
    copy: {
        h: 18,
        w: 24,
        viewBox: '0 0 18 24',
        path: 'M17.25 5.75V23.25H4.75V5.75H17.25ZM15.75 7.2495H6.24975V21.75H15.75V7.2495ZM13.25 0.75V3.5H11.75L11.7495 2.25025H2.25V16.75H2.5V18.25H0.75V0.75H13.25Z',
    },
    clone: {
        h: 18,
        w: 18,
        viewBox: '0 0 18 18',
        path: 'M3.75 0C2.1 0 0.75 1.35 0.75 3C0.75 3.83475 1.10775 4.58775 1.66425 5.133C1.15374 5.4775 0.735221 5.9416 0.445149 6.48488C0.155077 7.02816 0.00225579 7.63413 0 8.25V12.4688L1.5 13.2188V18H6V13.2188L7.5 12.4688V11.25H6V11.5312L4.5 12.2812V16.5H3V12.2812L1.5 11.5312V8.25C1.5 6.999 2.499 6 3.75 6C5.001 6 6 6.999 6 8.25H7.5C7.49774 7.63413 7.34492 7.02816 7.05485 6.48488C6.76478 5.9416 6.34626 5.4775 5.83575 5.133C6.39225 4.58775 6.75 3.8355 6.75 3C6.75 1.35 5.4 0 3.75 0ZM14.25 0C12.6 0 11.25 1.35 11.25 3C11.25 3.83475 11.6077 4.58775 12.1642 5.133C11.6537 5.4775 11.2352 5.9416 10.9451 6.48488C10.6551 7.02816 10.5023 7.63413 10.5 8.25V12.4688L12 13.2188V18H16.5V13.2188L18 12.4688V8.25C17.9977 7.63413 17.8449 7.02816 17.5549 6.48488C17.2648 5.9416 16.8463 5.4775 16.3358 5.133C16.8923 4.58775 17.25 3.8355 17.25 3C17.25 1.35 15.9 0 14.25 0ZM3.75 1.5C4.58775 1.5 5.25 2.16225 5.25 3C5.25 3.83775 4.58775 4.5 3.75 4.5C2.91225 4.5 2.25 3.83775 2.25 3C2.25 2.16225 2.91225 1.5 3.75 1.5ZM14.25 1.5C15.0878 1.5 15.75 2.16225 15.75 3C15.75 3.83775 15.0878 4.5 14.25 4.5C13.4122 4.5 12.75 3.83775 12.75 3C12.75 2.16225 13.4122 1.5 14.25 1.5ZM14.25 6C15.501 6 16.5 6.999 16.5 8.25V11.5312L15 12.2812V16.5H13.5V12.2812L12 11.5312V8.25C12 6.999 12.999 6 14.25 6ZM8.25 7.641V9H5.25V10.5H8.25V11.8597L10.3597 9.75L8.25 7.641Z',
    },
    trash: {
        h: 16,
        w: 18,
        viewBox: '0 0 16 18',
        path: 'M8 0C9.84706 0 11.4039 1.15355 11.6284 2.68845L11.6632 2.92981L16 2.93023V4.18605L14.7292 4.18563L14.3424 15.7659C14.3026 16.9589 13.2393 17.9331 11.8882 17.9967L11.7474 18H4.2526C2.83526 18 1.69881 17 1.65758 15.7659L1.2705 4.18563L0 4.18605V2.93023L4.33575 2.92981L4.3716 2.68845C4.59609 1.15355 6.15294 0 8 0ZM13.2285 4.18563H2.77125L3.157 15.7308C3.17514 16.274 3.62041 16.6951 4.15702 16.7402L4.2526 16.7442H11.7474C12.3257 16.7442 12.8238 16.3059 12.843 15.7308L13.2285 4.18563ZM6.75 6.06977V14.8605H5.25V6.06977H6.75ZM10.75 6.06977V14.8605H9.25V6.06977H10.75ZM8 1.25581L7.87016 1.25905C6.83868 1.31059 5.98756 1.97222 5.86048 2.84109L5.847 2.93023H10.152L10.1395 2.84109C10.0071 1.93601 9.08914 1.25581 8 1.25581Z',
    },
    group: {
        h: 16,
        w: 18,
        viewBox: '0 0 24 24',
        path: '"M3 11H11V3H3M5 5H9V9H5M13 21H21V13H13M15 15H19V19H15M3 21H11V13H3M5 15H9V19H5M13 3V11H21V3M19 9H15V5H19Z',
    },
};

const SvgButton: React.FC<{
    path: string;
    h: number;
    w: number;
    viewBox?: string;
    fill?: string;
}> = ({ path = '', h = 18, w = 18, viewBox = '0 0 18 18', fill = '#575F72' }) => {
    return (
        <>
            <svg
                width={w}
                height={h}
                viewBox={viewBox}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
            </svg>
        </>
    );
};

export default SvgButton;
