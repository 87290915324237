import { AsperaPath } from './../../base/types/aspera';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';

import {
    Asset,
    Assets,
    AssetUploadProperties,
    DraftAssetPatchPayload,
    Status,
    UserState,
} from 'base/models/Asset';
import Job from 'base/models/Job';
import { AssetStatus } from 'base/enums/AssetStatus';
import { postDraft, putDraft } from 'base/api/draft';
import { putAssets } from 'base/api/asset';
import { retrieveUserInformation } from 'base/utils/localStorageAccess';
import { UserInfo } from 'base/models/UserInfo';
import { Mam } from 'base/enums/Mam';
import { AsperaFileProperty, AsperaPaths } from 'base/types/aspera';
import { getTransferSpec } from 'base/services/aspera';

const getFileNameFromAsperaSource = (source: string) => {
    const normalizedName = source.replaceAll('/', '\\'); // for Mac and Windows
    const splittedName = normalizedName.split('\\');
    const fileName = splittedName[splittedName.length - 1];

    return fileName;
};

export const getAsperaAssetsAsInitialized = (
    fileProperty: AsperaFileProperty,
    job: Job = {} as Job
) => {
    const { fileName, filePath, source } = fileProperty;
    const [, draftAssetId] = filePath.split('/');
    const previewId = uuidv4();
    const userInfo: UserInfo | undefined = retrieveUserInformation();
    const originalFileName = getFileNameFromAsperaSource(source);

    const uploadProperties: AssetUploadProperties = {
        hasStartUpload: false,
        progress: 0,
        isCancelled: false,
        isChecked: true,
        isUploaded: false,
        hasError: false,
        asperaPath: source,
    };

    const status: Status = {
        id: AssetStatus.Draft,
        value: AssetStatus[AssetStatus.Draft],
    };

    let userObject: UserState | undefined = undefined;
    if (userInfo) {
        userObject = {
            date: new Date(),
            user: {
                id: userInfo.id,
                name: `${userInfo.firstName} ${userInfo.lastName}`,
                email: userInfo.userName,
            },
        };
    }
    const asset: Asset = {
        id: draftAssetId,
        jobId: job.id,
        createdAt: new Date(),
        updatedAt: new Date(),

        appId: userInfo?.applicationId,
        assetId: draftAssetId,
        mam: Mam.Wonderland,

        fields: {
            fileName,
            filePath: filePath,
            status: AssetStatus.Draft,
            originalFileName,
        },
        hasAssetChanges: false,
        hasApplicationChanges: false,
        previewId,

        created: userObject,
        modified: userObject,
        title: {
            id: NIL_UUID,
            name: '',
        },

        assetGroup: {
            id: '',
            name: '',
        },
        status,
        uploadProperties,
        inputSelectRules: [],
    };
    return asset;
};

export const updateJob = async (job: Job, assets: Assets) => {
    const user = retrieveUserInformation();
    let userObject: UserState | undefined = undefined;
    if (user) {
        userObject = {
            date: new Date(),
            user: {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                email: user.userName,
            },
        };
    }

    const reducedAssets = assets.reduce((currentValue, asset) => {
        currentValue.push({
            ...asset,
            modified: userObject,
            uploadProperties: {} as AssetUploadProperties,
        });
        return currentValue;
    }, [] as Assets);
    const ingestPayload = {
        job: job,
        assets: reducedAssets,
    };
    const resolvePayload = job.id ? await putDraft(ingestPayload) : await postDraft(ingestPayload);
    return resolvePayload.response?.data;
};

export const updateAssets = async (payload: DraftAssetPatchPayload) => {
    const resolvePayload = await putAssets(payload);

    return resolvePayload.response?.data;
};

const getAsperaPathsAndFileNames = (
    files: FileList,
    uniqueId: string,
    isReplacement?: boolean,
    existingAssetId?: string
) => {
    const fileProperties: AsperaFileProperty[] = [];

    const asperaPaths: AsperaPaths = Array.from(files).map(({ name, type, size, lastModified }) => {
        let draftAssetId = uuidv4();
        if (isReplacement && existingAssetId) draftAssetId = existingAssetId;
        const fileName = getFileNameFromAsperaSource(name);

        const fileProperty: AsperaFileProperty = {
            fileName,
            filePath: `${uniqueId}/${draftAssetId}/${fileName}`,
            source: name,
        };
        fileProperties.push(fileProperty);

        return {
            source: name,
            destination: `${uniqueId}/${draftAssetId}/${fileName}`,
            type: type,
            size: size,
            lastModifiedDate: lastModified ? new Date(lastModified) : undefined,
        };
    });

    return {
        asperaPaths,
        fileProperties,
    };
};

export const onDropAssetsAspera = async (
    event: any,
    isDragAndDrop: boolean,
    initializeJobInStore: () => Job,
    setCurrentJobInStore: (job: Job) => void,
    setAssetsInStore: (assets: Assets) => void
) => {
    const userInfo: UserInfo | undefined = retrieveUserInformation();

    const fileList = isDragAndDrop ? event.files.dataTransfer.files : event.dataTransfer.files;

    if (userInfo) {
        const { asperaPaths, fileProperties } = getAsperaPathsAndFileNames(
            fileList,
            userInfo.uniqueId
        );

        const job = initializeJobInStore();
        const tempAssets: Assets = fileProperties.map((fileProperty: AsperaFileProperty) => {
            const tempAsset: Asset = getAsperaAssetsAsInitialized(fileProperty, job);
            return tempAsset;
        });

        const ingestion = await updateJob(job, tempAssets);
        if (ingestion) {
            setCurrentJobInStore(ingestion.job);

            let responseJobs: Job[] = [];
            if (ingestion.responseIngestJobs) responseJobs = ingestion.responseIngestJobs;

            let indexJob = 0;
            const assets = tempAssets.reduce((currentValue, asset) => {
                if (!asset.jobId) {
                    asset.jobId = responseJobs[indexJob].id;
                }
                const responseAsset = ingestion.assets.find((x) => x.id === asset.id);

                if (responseAsset) {
                    asset.ownerAppId = responseAsset.ownerAppId;
                    asset.fields.fileName = responseAsset.fields.fileName;
                    asset.fields.filePath = responseAsset.fields.filePath;
                    asset.modified = responseAsset.modified;
                }

                indexJob++;
                return [...currentValue, asset];
            }, [] as Assets);

            const updatedAsperaPaths = updateDestinations(asperaPaths, ingestion.assets);
            const transferSpec = await getTransferSpec(updatedAsperaPaths);
            return {
                transferSpec: transferSpec?.data,
                assets,
                asperaPaths,
            };
        }
    }
};

export const onDropReplacementAssetsAspera = async (
    event: any,
    isDragAndDrop: boolean,
    initializeJobInStore: () => Job,
    existingAsset: Asset | null
) => {
    const userInfo: UserInfo | undefined = retrieveUserInformation();

    const fileList = isDragAndDrop ? event.files.dataTransfer.files : event.dataTransfer.files;

    if (userInfo) {
        let localAsperaPaths: AsperaPaths = [];
        let localFileProperties: AsperaFileProperty[] = [];

        if (existingAsset && existingAsset.id) {
            const data = getAsperaPathsAndFileNames(
                fileList,
                userInfo.uniqueId,
                true,
                existingAsset.id
            );
            localAsperaPaths = data.asperaPaths;
            localFileProperties = data.fileProperties;
        } else {
            const data = getAsperaPathsAndFileNames(fileList, userInfo.uniqueId);
            localAsperaPaths = data.asperaPaths;
            localFileProperties = data.fileProperties;
        }

        const job = initializeJobInStore();
        const tempAssets: Assets = localFileProperties.map((fileProperty: AsperaFileProperty) => {
            const tempAsset: Asset = getAsperaAssetsAsInitialized(fileProperty, job);
            return tempAsset;
        });

        const assets = tempAssets.reduce((currentValue, asset) => {
            if (!asset.jobId) {
                asset.jobId = existingAsset?.jobId ?? NIL_UUID;
            }
            const responseAsset = tempAssets.find((x) => x.id === asset.id);

            if (responseAsset) {
                asset.ownerAppId = responseAsset.ownerAppId;
                asset.fields.fileName = responseAsset.fields.fileName;
                asset.fields.filePath = responseAsset.fields.filePath;
                asset.modified = responseAsset.modified;
            }

            return [...currentValue, asset];
        }, [] as Assets);

        const updatedAsperaPaths = updateDestinations(localAsperaPaths, assets);
        const transferSpec = await getTransferSpec(updatedAsperaPaths);
        return {
            transferSpec: transferSpec?.data,
            assets,
            localAsperaPaths,
        };
    }
};

const updateDestinations = (asperaPaths: AsperaPaths, assets: Assets) => {
    const updatedAsperaPaths = asperaPaths.map((path: AsperaPath) => {
        const asset = assets.find((asset: Asset) => {
            const [, draftAssetId] = asset.fields.filePath.split('/');
            const [, destinationAssetId] = path.destination.split('/');
            return draftAssetId === destinationAssetId;
        });
        if (asset) {
            const asperaPath: AsperaPath = {
                ...path,
                destination: asset.fields.filePath,
            };
            return asperaPath;
        } else return path;
    });
    return updatedAsperaPaths;
};
