import { Title } from './../types/title';
import axios, { CancelTokenSource } from 'axios';
import api from './config';

let cancelToken: CancelTokenSource;

export const getTitles = async (keyword: string) => {
    try {
        if (cancelToken) cancelToken.cancel();

        cancelToken = axios.CancelToken.source();

        const response = await api.get('titles', {
            params: { keyword },
            cancelToken: cancelToken.token,
        });

        return response.data as Title[];
    } catch (ex) {
        return null;
    }
};
