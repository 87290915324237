import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface StyleProps {
    color: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            background: ({ color }) => color,
            border: 0,
            borderRadius: 4,
            height: '32px',
            minWidth: 'auto',
            lineHeight: '30px',
        },
        fakeSpacer: {
            paddingLeft: 24,
        },
        fakeSpacerRight: {
            paddingRight: '.375em',
        },
        checkpoint: {
            fontStyle: 'italic',
        },
        gridContainer: {
            alignItems: 'center',
        },
        transferText: {
            color: '#5CA7DA',
            fontWeight: 600,
        },
        successText: {
            color: '#4EA847',
            fontWeight: 600,
        },
        paper: {
            position: 'absolute',
            width: '50%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        tableTitle: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        smallIcon: {
            width: 12,
            height: 12,
        },
        iconMenu: {
            color: 'white',
        },
        divider: {
            backgroundColor: 'white',
        },
        avatar: {
            '&.dmdc-avatar': {
                margin: '0 0 12px 10px',
                width: '180px',
                height: '180px',
                backgroundColor: 'transparent',
            },
        },
        dialog: {
            borderTop: 'none!important',
        },
        dialogTitle: {
            padding: '5px 10px 5px 10px',
        },
        dialogContent: {
            padding: '0 10px 0 10px',
        },
        dialogActions: {
            padding: '5px 10px 5px 10px',
        },
        avatarImg: {
            width: '100%',
            height: 'auto',
        },
    })
);

export const extraStyleProps = {
    color: '#F0F0F0',
};
