import { MenuProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 200,
            maxWidth: 300,
        },
    })
);

export const propsMenuItemsBellowControl: Partial<MenuProps> | undefined = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
    style: {
        maxHeight: 500,
    },
};
