import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { Title } from 'components/shared/Title';
import { UploadComponent } from 'components/Upload';
import { SmallUpload } from 'components/Upload/SmallUpload';

import { useStyles } from './Upload.styles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { EditAsset } from 'components/EditAsset';
import { Breadcrumb } from 'components/Upload/Breadcrumb';

export const Upload = () => {
    const classes = useStyles();
    const { isThereAnyAssetInStore, isStartingUpload } = useRootStoreContext().ingestionStore;
    const { tabsInEditStore } = useRootStoreContext().editStore;

    return (
        <>
            <Box className={classes.root}>
                <Grid container spacing={3} justify="flex-start" alignItems="center">
                    {!isThereAnyAssetInStore && tabsInEditStore.length === 0 ? (
                        <Grid item xs={12} lg={2}>
                            <Breadcrumb />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} lg={2}>
                                <Breadcrumb />
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <SmallUpload
                                    textContent="Drag and drop your files to upload"
                                    textButton="Add More"
                                />
                            </Grid>
                        </>
                    )}

                    {!isThereAnyAssetInStore && tabsInEditStore.length === 0 ? (
                        <>
                            <Grid item xs={12}>
                                <Title title="Upload your assets" variant="h4" />
                            </Grid>
                            <Grid item xs={12}>
                                <UploadComponent />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Box
                                    borderTop={1}
                                    borderColor="#bababa"
                                    height={'1.5rem'}
                                    margin={'0 .25rem'}
                                ></Box>
                                <EditAsset />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
            <Backdrop style={{ color: '#fff', zIndex: 100 }} open={isStartingUpload}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default observer(Upload);
