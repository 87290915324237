import React, { useEffect, useState } from 'react';
import { useStyles } from './Preview.styles';
import { MagnifierPreview, MagnifierContainer, MagnifierZoom } from 'react-image-magnifiers';
import { Portal } from '@material-ui/core';

interface PreviewProps {
    previewUrl: string[] | null;
    largeUrl: string | null;
    handleImageOnLoad: (ev: React.SyntheticEvent<Element, Event>) => void;
    previewTarget: any;
}

const Magnifier: React.FC<PreviewProps> = ({
    previewUrl,
    largeUrl,
    handleImageOnLoad,
    previewTarget,
}: PreviewProps) => {
    const classes = useStyles();
    const [image, setImage] = useState<string | null>(null);
    const [hiddenPortal, setHidePortal] = useState(true);

    useEffect(() => {
        if (previewUrl) {
            previewUrl.reduce((validImage, currentImage) => {
                if (currentImage.startsWith('data:image')) {
                    setImage(currentImage);
                }
                return validImage;
            }, null as string | null);
        }
    }, [previewUrl]);

    const handlePreviewEnd = () => {
        setHidePortal(true);
    };

    const handlePreviewStart = () => {
        setHidePortal(false);
    };

    return (
        <MagnifierContainer>
            <div className={classes.loupeContainer}>
                {image ? (
                    <img src={image} className={classes.dmdcDefaultImage} />
                ) : (
                    <MagnifierPreview
                        className={classes.dmdcPreviewMagnifier}
                        imageAlt="Preview"
                        imageSrc={previewUrl as any}
                        onImageLoad={handleImageOnLoad}
                        onZoomEnd={handlePreviewEnd}
                        onZoomStart={handlePreviewStart}
                        cursorStyle="zoom-in"
                    />
                )}

                {largeUrl && !hiddenPortal && (
                    <Portal container={previewTarget}>
                        <div className={classes.dmdcZoomMagnifierContainer}>
                            <MagnifierZoom style={{ height: '400px' }} imageSrc={largeUrl ?? ''} />
                        </div>
                    </Portal>
                )}
            </div>
        </MagnifierContainer>
    );
};

export default Magnifier;
