import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import localStorageAccess, { LocalStorageKeys, setLastPath } from 'base/utils/localStorageAccess';
import { AuthenticationResponse } from 'base/models/UserInfo';
import { ROUTES } from 'base/constants/route';
import { appStateHelper } from 'base/utils/appStateHelper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const reauth = `/${ROUTES.REAUTH}`;
    const isLogged = localStorageAccess.getFromLocalStorage<AuthenticationResponse>(
        LocalStorageKeys.userInfo
    );
    setLastPath();
    if (!isLogged) {
        appStateHelper.requestToken.setRequestTokenState();
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isLogged || props.match.path === reauth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`/${ROUTES.LOGIN}`} />
                )
            }
        />
    );
};

export default observer(PrivateRoute);
