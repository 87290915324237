import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'base/stores/rootStore';
import { AuthSettings, UserActionFlags } from 'base/models/AuthSettings';

export type Settings = {
    BASE_URL: string;
    CLIENT_ID: string;
    MYID_URL: string;
    DIF_MYID_URL: string;
    DISNEYSCREENERS_LOGIN_URL: string;
    DISNEYSCREENERS_LOGIN_RETURN_URL: string;
    PREVIEW_URL: string;
    DSUPLOADWIDGET_STYLE: string;
    DSUPLOADWIDGET_SCRIPT: string;
    DSPORTAL_ID: string;
} | null;

export class SettingsStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable keys: Settings = null;
    @observable userActionFlags: UserActionFlags = null;

    setSettings = (settings: Settings) => {
        if (settings) {
            this.keys = { ...settings };
        }
    };
        
    @action setAuthSettings = (value: AuthSettings) => {
        const canViewUploadStatus = (value && value.userActions && value.userActions.ViewUploadStatus === true) || false;
        const canUploadMediaFilesForPackaging = (value && value.userActions && value.userActions.UploadMediaFilesForPackaging === true) || false;

        this.userActionFlags = {
            canViewUploadStatus: canViewUploadStatus,
            canUploadMediaFilesForPackaging: canUploadMediaFilesForPackaging
        };
    };        
}