import api from './config';
import { resolvePromise } from 'base/utils/resolveAPICall';

export const getAssetTypesByProductTypeId = async (productTypeId: number) => {
    return await api.get(`asset-types/${productTypeId}`);
};

export const getTemplateById = async (id: string) => {
    return await resolvePromise(api.get(`templates/${id}`));
};

export const getTemplates = async () => {
    return await api.get('templates');
};
