import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        marginTop: '1rem',
        '& .MuiTabs-root': { minHeight: '4rem' },
        '& .MuiTab-root': {
            fontSize: 16,
            minHeight: '4rem',
        },
        '&  .MuiTab-root:not(.Mui-selected)': {
            'border-bottom': '2px solid #bababa',
            color: '#575F72',
        },
        '& .MuiTabs-indicator': {
            border: '2px solid #bababa',
            height: '100%',
            background: 'transparent',
            'border-bottom': 'none',
            'border-top-left-radius': 8,
            'border-top-right-radius': 8,
            transition: 'none',
        },
        '& .MuiTabScrollButton-root ': {
            border: '2px solid #bababa',
            'border-top-left-radius': 8,
            'border-top-right-radius': 8,
        },
    },
}));
