import React from 'react';
import { GridCellParams, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid';
import { DateFormat } from '../../DateFormat';
import { TitleRowFormat } from '../../TitleRowFormat';
import { EditAssetButton } from '../../EditAssetButton';
import { AssetList } from 'components/Dashboard/AssetList';
import { dateTypes } from 'base/models/DashboardProps';
import { getLastModifiedDate } from 'base/utils/dateCalculator';

export const ColumnsType = (bulkActionCallback: () => void): GridColDef[] => {
    const ColumnsType: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title',
            type: 'string',
            flex: 0.45,
            renderCell: ({ row }: GridCellParams) => <TitleRowFormat typeTitle={row.title} />,
        },
        {
            field: 'titleId',
            headerName: 'Title ID',
            type: 'string',
            flex: 0.15,
            renderCell: ({ row }: GridCellParams) => {
                const titleId = row.wprId
                    ? (row.cpmProductId == null ? '' : row.cpmProductId) + ' (' + row.wprId + ')'
                    : row.cpmProductId;
                return <TitleRowFormat typeTitle={titleId} />;
            },
        },

        {
            field: 'releaseDate',
            headerName: 'Release Date',
            type: 'string',
            flex: 0.25,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {row.releaseDate && (
                        <DateFormat
                            date={row.releaseDate}
                            format={dateTypes.DATE_TYPE_STANDARD}
                            dateFormat="DD-MMM-YYYY"
                        />
                    )}
                </>
            ),
        },
        {
            field: 'assetType',
            headerName: 'Asset Group',
            type: 'string',
            flex: 0.25,
            renderCell: ({ row }: GridCellParams) => <TitleRowFormat typeTitle={row.assetType} />,
        },
        {
            field: 'totalAssets',
            headerName: 'Quantity',
            type: 'number',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }: GridCellParams) => <AssetList row={row} />,
            cellClassName: 'TotalAsset-cell',
        },
        {
            field: 'updatedAt',
            headerName: 'Last modified',
            type: 'date',
            flex: 0.25,
            align: 'left',
            headerAlign: 'left',
            renderCell: ({ row }: GridCellParams) => {
                const lastModifiedDate = getLastModifiedDate(row.assets);
                return (
                    <DateFormat date={lastModifiedDate} format={dateTypes.DATE_TYPE_REMAINING} />
                );
            },
        },
        {
            field: 'edit',
            headerName: ' ',
            align: 'right',
            headerAlign: 'right',
            flex: 0.2,
            sortable: false,
            filterable: false,
            renderHeader: ({ field, colDef, api }: GridColumnHeaderParams) => (
                <></>
                // requested by business to hide for now
                // <Button className="edit-all-selected" color="primary" onClick={bulkActionCallback}>
                //     Edit All Selected
                // </Button>
            ),
            renderCell: ({ row }: GridCellParams) => {
                return <EditAssetButton data={row} />;
            },
        },
    ];

    return ColumnsType;
};

export const ColumnsTitle: GridColDef[] = [
    {
        field: 'title',
        headerName: 'Title',
        type: 'string',
        flex: 0.5,
        renderCell: ({ row }: GridCellParams) => <TitleRowFormat typeTitle={row.title} />,
    },
    {
        field: 'titleId',
        headerName: 'Title ID',
        type: 'string',
        flex: 0.15,
        renderCell: ({ row }: GridCellParams) => {
            const titleId = row.wprId
                ? (row.cpmProductId == null ? '' : row.cpmProductId) + ' (' + row.wprId + ')'
                : row.cpmProductId;
            return <TitleRowFormat typeTitle={titleId} />;
        },
    },
    {
        field: 'releaseDate',
        headerName: 'Release Date',
        type: 'string',
        flex: 0.25,
        renderCell: ({ row }: GridCellParams) => (
            <>
                {row.releaseDate && (
                    <DateFormat
                        date={row.releaseDate}
                        format={dateTypes.DATE_TYPE_STANDARD}
                        dateFormat="DD-MMM-YYYY"
                    />
                )}
            </>
        ),
    },
    {
        field: 'totalAssets',
        headerName: 'Quantity',
        type: 'number',
        width: 140,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }: GridCellParams) => <AssetList row={row} />,
        cellClassName: 'TotalAsset-cell',
    },
    {
        field: 'updatedAt',
        headerName: 'Last modified',
        type: 'date',
        flex: 0.3,
        align: 'left',
        headerAlign: 'left',
        renderCell: ({ row }: GridCellParams) => {
            const lastModifiedDate = getLastModifiedDate(row.assets);
            return <DateFormat date={lastModifiedDate} format={dateTypes.DATE_TYPE_REMAINING} />;
        },
    },
    {
        field: 'edit',
        headerName: ' ',
        align: 'right',
        flex: 0.3,
        sortable: false,
        filterable: false,
        renderCell: ({ row }: GridCellParams) => {
            return <EditAssetButton data={row} />;
        },
    },
];
