import { Button } from '@material-ui/core';
import { SortGroup, SortGroupAsset } from 'base/models/Asset';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';

import './EditGroup.component.css';
import SvgButton, { Buttons as SvgButtonPresets } from 'components/SortAndGroup/SvgButton';
import { ImageWithDefault } from '../ImageWithDefault';

interface SortGroupItem extends SortGroupAsset {
    marked?: boolean;
}

const EditGroup: FC<{
    idx: number;
    group: SortGroup;
    close(): void;
    disband(assets: SortGroupAsset[]): void;
    update(group: SortGroup): void;
    removeOne(newGroup: SortGroup, removedAsset: SortGroupAsset): void;
}> = ({ idx, group, close, disband, update, removeOne }) => {
    const [items, setItems] = useState(group.assets as SortGroupItem[]);

    const [main, setMain] = useState({} as unknown as SortGroupItem);
    const [children, setChildren] = useState([] as unknown as SortGroupItem[]);
    const [mainCandidate, setMainCandidate] = useState<SortGroupItem>(
        null as unknown as SortGroupAsset
    );

    useEffect(() => {
        // Main besides marked with IsMain, by default will ALWAYS be the first asset in a group.
        // That helps cover up cases when dirty provided data allows multple main assets, or groups with no main assets.
        // The cover up would reflect the data fix after save.
        if (items?.length) {
            const [main, ...rest] = items;
            setMain(main);
            setChildren(rest);
        }
    }, [items]);

    const onItemClick = (item: SortGroupAsset) => {
        // check if same candidate is being clicked.
        // 1) if not then set it as candidate,
        // 2)  otherwise clear it out

        mainCandidate?.id !== item.id
            ? setItems(
                  items.map((m) => ({
                      ...m,
                      marked: m.id === item.id,
                  }))
              )
            : setItems(
                  items.map((m) => ({
                      ...m,
                      marked: false,
                  }))
              );

        const newMain = mainCandidate?.id !== item.id ? item : (null as unknown as SortGroupAsset);
        setMainCandidate(newMain);
    };

    const onRemoveFromGroup = (item: SortGroupAsset) => {
        const filterred = items.filter((f) => f.id !== item.id);
        setItems(filterred);
        removeOne({ ...group, assets: filterred }, item);
        filterred.length === 1 && close();
    };

    const setMainItem = () => {
        if (mainCandidate) {
            const newItems = [
                { ...mainCandidate, isMain: true, marked: false },
                ...items
                    .filter((f) => f.id !== mainCandidate.id)
                    .map((m) => ({ ...m, isMain: false, marked: false })),
            ] as unknown as SortGroupItem[];

            setItems(newItems);
            update({ ...group, assets: newItems });
        }
        //after main items is being set, reset the main candidate
        setMainCandidate(null as unknown as SortGroupAsset);
    };
    return (
        <div className="dmdc-edit-group">
            <Grid container justify="space-between">
                <div className="dmdc-edit-group--name">{group.name}</div>

                <span className="dmdc-edit-group--close" onClick={() => close()} title="Close">
                    <ClearIcon style={{ width: '100%', height: '100%' }} />
                </span>
            </Grid>

            <Grid container wrap="wrap" direction="row">
                {main ? (
                    <div
                        key={main.id}
                        className="dmdc-edit-group--product-item-main-wrapper"
                        title={main.name}
                    >
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            key={main.id}
                            className={`dmdc-edit-group--product-item-main`}
                            title={main.name}
                        >
                            <div className="dmdc-edit-group--child-image">
                                <ImageWithDefault
                                    src={main.imageUrl}
                                    alt={main.name}
                                ></ImageWithDefault>
                            </div>
                        </Grid>
                        <div className="dmdc-edit-group--item-desc">{main.name}</div>
                    </div>
                ) : null}

                <ReactSortable
                    group="group-edit"
                    onMove={(evt, orEvt) => {
                        return true; //not interfere with onmove, does default behaviour
                    }}
                    animation={200}
                    delay={2}
                    list={children}
                    setList={(currentList) => {
                        setChildren(currentList);
                        //inform parent state
                        //currentList?.length bypasses initially trigger
                        currentList?.length && update({ ...group, assets: [main, ...currentList] });
                    }}
                    className="dmdc-sortable--container"
                >
                    {children.map((item) => (
                        <Grid
                            onClick={() => onItemClick(item)}
                            container
                            direction="column"
                            wrap="nowrap"
                            key={item.id}
                            className={`dmdc-edit-group--product-item-wrapper  ${
                                item.marked ? 'dmdc-edit-group--product-item-marked-candidate' : ''
                            }`}
                            title={item?.name}
                        >
                            <Grid container justify="flex-end">
                                <span
                                    className={`dmdc-edit-group--remove-child-btn`}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        onRemoveFromGroup(item);
                                    }}
                                    title="pull outside group"
                                >
                                    <ClearIcon style={{ width: '100%', height: '100%' }} />
                                </span>
                            </Grid>
                            <div className="dmdc-edit-group--child-image">
                                <ImageWithDefault
                                    src={item.imageUrl}
                                    alt={item.name}
                                ></ImageWithDefault>
                            </div>
                            <div className="dmdc-edit-group--child-desc">{item?.name}</div>
                        </Grid>
                    ))}
                </ReactSortable>
            </Grid>

            <Grid
                container
                justify="flex-end"
                alignItems="center"
                className="dmdc-edit-group--footer"
            >
                {mainCandidate ? (
                    <Button
                        className="dmdc-edit-group--button"
                        title={`set ${mainCandidate.name} as parent`}
                        onClick={() => setMainItem()}
                    >
                        Make Parent
                    </Button>
                ) : null}

                <Button
                    className="dmdc-edit-group--button dmdc-edit-group--disband"
                    onClick={() => disband(items)}
                    title="Disband Group"
                >
                    <SvgButton {...SvgButtonPresets.disband}></SvgButton>
                </Button>
            </Grid>
        </div>
    );
};

export default observer(EditGroup);
