import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: { marginRight: 'auto' },
        sizeButton: {
            border: '1px solid #575F72',
            color: '#575F72',
            height: '24px',
            width: '24px',
            marginLeft: 8,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            cursor: 'pointer',
            justifyContent: 'center',
            fontFamily: 'GT America',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 18,
            textAlign: 'center',
            '&.active': {
                background: '#335F82',
                color: 'white',
            },
        },
        outlinedButtom: {
            boxShadow: 'none',
            marginRight: 5,
            border: 'solid #000 1px !important',
            borderRadius: 3,
        },
        headerActionsContainer: {
            width: 'auto',
            marginLeft: 16,
        },
        saveButton: {
            color: '#ffffff',
            backgroundColor: '#216085',
            '&:hover': {
                color: '#ffffff',
                backgroundColor: '#216085',
            },
            marginLeft: 16,
        },
        sizesWrapper: {
            '&.MuiGrid-item': {
                width: 'auto',
                border: '1px dashed black',
                borderRadius: '6px',
                padding: '3px 16px',
            },
        },
        selectedLayout: {
            backgroundColor: 'blue',
            color: 'white',
        },
        spinningIcon: {
            animation: `$spin 1000ms linear infinite`,
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(360deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
    })
);
