import React, { useEffect, useRef } from 'react';

import {
    useStyles,
    MainContainer,
    IconContainer,
    TextAndIconContainer,
    TextContainer,
    ButtonAddMoreContainer,
    ButtonAddMore,
} from './SmallUpload.styles';

import { Paper } from '@material-ui/core';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { useDropzone } from 'react-dropzone';
import { onDropAssetsAspera } from '../Upload.functions';
import { useRootStoreContext } from 'base/stores/rootStore';
import { Aspera } from 'base/utils/aspera-client';
import { executeTransfer } from 'base/services/aspera';

interface SmallUploadProps {
    textButton: string;
    textContent: string;
}

const dragAndDropSelector = 'drag-and-drop';

export const SmallUpload = ({ textButton, textContent }: SmallUploadProps) => {
    const { current: aspera } = useRef(new Aspera());

    const classes = useStyles();
    const {
        setAssetsInStore,
        initializeJobInStore,
        setCurrentJobInStore,
        updateAssetsWithTransferId,
        setIsStartingUploadInStore,
    } = useRootStoreContext().ingestionStore;
    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
    });

    const handleSelectAssetsFromDialog = async () => {
        const dialogOptions: any = {
            allowMultipleSelection: true,
            allowedFileTypes: [
                {
                    filter_name: 'All types',
                    extensions: ['*'],
                },
            ],
        };
        aspera.init();
        aspera.client.showSelectFileDialog(
            {
                success: async (event: any) => {
                    setIsStartingUploadInStore(true);

                    const isThereAnySelectedFiles = event.dataTransfer.files.length > 0;
                    if (isThereAnySelectedFiles) {
                        const data = await onDropAssetsAspera(
                            event,
                            false,
                            initializeJobInStore,
                            setCurrentJobInStore,
                            setAssetsInStore
                        );
                        if (data) {
                            executeTransfer(
                                data.transferSpec,
                                data.assets,
                                aspera,
                                setIsStartingUploadInStore,
                                setAssetsInStore,
                                updateAssetsWithTransferId
                            );
                        }
                    }
                },
                error: (error: any) => error,
            },
            dialogOptions
        );
    };

    const handleDragAndDropAssets = async (event: any) => {
        setIsStartingUploadInStore(true);

        const data: any = await onDropAssetsAspera(
            event,
            true,
            initializeJobInStore,
            setCurrentJobInStore,
            setAssetsInStore
        );
        if (data) {
            executeTransfer(
                data.transferSpec,
                data.assets,
                aspera,
                setIsStartingUploadInStore,
                setAssetsInStore,
                updateAssetsWithTransferId
            );
        }
    };

    useEffect(() => {
        aspera.validateStatusAndInstallation();
        aspera.client.setDragDropTargets(`#${dragAndDropSelector}`, {}, handleDragAndDropAssets);
        aspera.init();
    }, []);

    return (
        <div {...getRootProps()} id={dragAndDropSelector}>
            <input {...getInputProps()} />
            <Paper elevation={0} className={classes.paper}>
                <MainContainer>
                    <TextAndIconContainer>
                        <IconContainer>
                            <PublishOutlinedIcon style={{ fontSize: '50px' }} />
                        </IconContainer>
                        <TextContainer>{textContent}</TextContainer>
                    </TextAndIconContainer>
                    <ButtonAddMoreContainer>
                        <ButtonAddMore
                            disableRipple
                            variant="contained"
                            onClick={handleSelectAssetsFromDialog}
                        >
                            {textButton}
                        </ButtonAddMore>
                    </ButtonAddMoreContainer>
                </MainContainer>
            </Paper>
        </div>
    );
};

export default SmallUpload;
