import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
    isDragging: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
        placeholder: {
            borderStyle: 'none',
            borderColor: '#d3d3d3',
        },
        isDraggingPlaceholder: {
            borderStyle: 'dotted',
            borderColor: '#d3d3d3',
        },
        footerButtons: {
            border: '#6c7384 solid 2px',
            background: '#ffffff',
            minWidth: 10,
            padding: 0,
            marginRight: 2,
        },
    })
);
