export interface PresetField {
    fieldName: string;
    presetValue: string;
}
export interface Preset {
    assetGroupId: number;
    assetGroupName: string;
    fields: PresetField[];
}

export interface TemplateFieldOption {
    parentFieldValue?: string;
    options?: TemplateFieldOption[];
    display?: string;
    value?: string;
}

export interface TemplateField {
    childFieldName?: string;
    dmdcTargetField: string;
    fieldName: string;
    fieldType: string;
    icon?: string;
    isIdValue?: boolean;
    isDisabled: boolean;
    isRequired: boolean;
    isVisible: boolean;
    label: string;
    validationErrorMessage?: string;
    options?: TemplateFieldOption[];
}

export interface Template {
    id: string;
    mam: string;
    createdAt: Date;
    updatedAt: Date;
    templateName: string;
    templateLabel: string;
    fields: TemplateField[];
    presets: Preset[];
}

export interface BulkEditField {
    fieldName: string;
    value: any;
}

export type BulkEditFields = BulkEditField[];

export const statusFieldName = 'status';
