import React, { Fragment, useState, useEffect } from 'react';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

import { TemplateFieldOption } from 'base/models/Template';

import { useStyles, propsMenuItemsBellowControl } from './MultiSelect.styles';
import BaseFieldProps from 'base/models/BaseFieldProps';

export interface MultiSelectProps extends BaseFieldProps {
    value?: string;
    errorMessage?: string;
    childFieldName?: string;
    options: TemplateFieldOption[];
}
const MultiSelect: React.FC<MultiSelectProps> = ({
    value = '',
    label,
    errorMessage,
    isRequired,
    isDisabled,
    isVisible,
    fieldName,
    dmdcTargetField,
    childFieldName,
    options,
    onChange,
    onBlur,
}: MultiSelectProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<TemplateFieldOption[]>([]);
    const [error, setError] = useState(false);

    const handleChange = (value: TemplateFieldOption[]) => {
        setSelected(value);
        if (onChange) {
            const stringValue = value.join('|');
            onChange(fieldName, stringValue);
        }
    };

    useEffect(() => {
        if (value) {
            const valuesAsArray: TemplateFieldOption[] = value.split('|') as TemplateFieldOption[];
            setSelected(valuesAsArray);
        }
    }, [value]);

    const handleBlur = () => {
        const isRequiredAndEmpty = selected.length === 0 && isRequired;
        setError(isRequiredAndEmpty);
        if (onBlur) {
            const selectedJoinedValues = (selected as string[]).sort().join('|');
            onBlur(fieldName, selectedJoinedValues);
        }
    };

    return (
        <Fragment>
            {isVisible && (
                <FormControl
                    className={classes.formControl}
                    disabled={isDisabled}
                    required={isRequired}
                    hidden={isVisible ? false : true}
                >
                    <InputLabel
                        htmlFor={`multi-select-${dmdcTargetField}`}
                        id={`label-${fieldName}`}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        multiple
                        labelId={`label-${fieldName}`}
                        id={`multi-select-${dmdcTargetField}`}
                        value={selected}
                        renderValue={(selected) =>
                            (selected as string[])
                                .map((s: string) => options.find((o) => o.value === s)?.display)
                                .join(',')
                        }
                        name={`multi-select-${fieldName}`}
                        onChange={(e) => handleChange(e.target.value as TemplateFieldOption[])}
                        onBlur={handleBlur}
                        error={error}
                        data-testid={`multi-select-${dmdcTargetField}`}
                        MenuProps={{ ...propsMenuItemsBellowControl }}
                    >
                        {options?.map((option: TemplateFieldOption) => (
                            <MenuItem key={option.value} value={option.value}>
                                <ListItemIcon>
                                    <Checkbox
                                        color="primary"
                                        checked={selected.some((o) => o === option.value)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.display} />
                            </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText error>{errorMessage}</FormHelperText>}
                </FormControl>
            )}
        </Fragment>
    );
};

export default MultiSelect;
