import { Button, MenuProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 20,
            paddingTop: 20,
        },
        inputTextContainer: {
            paddingRight: '20px',
            width: '100%',
        },
        formControl: {
            width: 'inherit',
            [theme.breakpoints.down('sm')]: {
                minWidth: 120,
            },
            [theme.breakpoints.up('md')]: {
                minWidth: 320,
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: 400,
            },
        },
        titleSearch: {
            width: 'inherit',
            [theme.breakpoints.down('sm')]: {
                minWidth: 120,
            },
            [theme.breakpoints.up('md')]: {
                minWidth: 320,
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: 400,
            },
        },
    })
);

export const propsMenuItemsBellowControl: Partial<MenuProps> | undefined = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
    style: {
        maxHeight: 500,
    },
};

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonConfirm = styled(Button)`
    text-transform: none;
    box-shadow: none;
    height: 40px;
    width: 150px;
    border-radius: 4px;
    background-color: #00aae4;

    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    &:hover {
        color: #ffffff;
        background-color: #00aae4;
    }
`;
