import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import { UpdateStatusInfo } from 'base/models/UpdateStatus';

export const putStatusUpdate = async (data: UpdateStatusInfo) => {
    const response = await resolvePromise(
        api.put<UpdateStatusInfo>(`external/application-asset/status`, data)
    );
    return response;
};
