export const SortableFields = [
    'fileName',
    'assetName',
    'assetDescription',
    'contentType',
    'contentSubType',
    'sortOrder',
    'groupChoice',
    'assetRestrictions',
    'assetCopyright',
    'aboutExclusivity',
    'language',
    'assetCreated',
    'assetModifiedDate',
    'disclaimer',
    'packageContains',
];
