import React, { Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from './InputDate.styles';
import BaseFieldProps from 'base/models/BaseFieldProps';
import dayjs from 'dayjs';

export interface DatePickerProps extends BaseFieldProps {
    errorMessage?: string;
}

const InputDate: React.FC<DatePickerProps> = ({
    label,
    errorMessage,
    isRequired,
    isDisabled,
    isVisible,
    fieldName,
    dmdcTargetField,
    value,
    onChange,
    onBlur,
}: DatePickerProps) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState<string | null>(value ?? '');
    const [error, setError] = React.useState(false);

    const handleChange = (date: string) => {
        setSelectedDate(date);
        if (onChange) {
            onChange(fieldName, date);
        }
    };

    const handleValidateField = () => {
        const isRequiredAndNotEmpty = !selectedDate && isRequired ? true : false;
        setError(isRequiredAndNotEmpty);
        if (selectedDate !== null && onBlur) {
            onBlur(fieldName, selectedDate);
        }
    };

    useEffect(() => {
        if (value) {
            const displayDate = dayjs(value).format('YYYY-MM-DD');
            setSelectedDate(displayDate);
        }
    }, [value]);

    return (
        <Fragment>
            {isVisible && (
                <TextField
                    defaultValue={value}
                    id={`text-date-${dmdcTargetField}`}
                    value={selectedDate ?? value}
                    label={label}
                    name={`text-date-${fieldName}`}
                    disabled={isDisabled}
                    required={isRequired}
                    hidden={isVisible ? false : true}
                    type="date"
                    onChange={(event) => handleChange(event.currentTarget.value as string)}
                    onBlur={handleValidateField}
                    className={classes.date}
                    error={error}
                    helperText={error && errorMessage}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        </Fragment>
    );
};

export default InputDate;
