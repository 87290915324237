import React from 'react';

import { MainContainer } from './TitleItemOption.styles';
import { Title } from 'base/types/title';

interface TitleItemOptionProps {
    option: Title;
}

export const TitleItemOption = ({ option }: TitleItemOptionProps) => {
    const { releaseDate, name, identifiers, productType } = option;
    const newDate = new Date(releaseDate);

    const formatMonth = (month: number) => {
        month++;
        if (month < 10) {
            return `0${month}`;
        } else return month;
    };

    return (
        <MainContainer>
            <div>
                <strong>{name.primary}</strong>
            </div>
            <div>{`${identifiers.productId} - ${productType.name}`}</div>
            <div>{`${newDate.getFullYear()}-${formatMonth(
                newDate.getMonth()
            )}-${newDate.getDate()}`}</div>
        </MainContainer>
    );
};

export default TitleItemOption;
