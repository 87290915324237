import styled from 'styled-components';

export const PreviewImg = styled.img`
    border-radius: 5px;
    width: 112px;
    height: 148px;
    margin-right: 10px;
    object-fit: contain;
    background-color: #e0e0e0;
`;

interface ContainerProps {
    marginBottom: string;
}

export const Container = styled.div<ContainerProps>`
    margin-bottom: ${(props) => props.marginBottom};
`;

interface FlexContainerProps {
    flexDirection: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    height: 150px;
`;
