import { Asset, Assets, AssetUploadProperties } from 'base/models/Asset';
import { Template } from 'base/models/Template';

export const getAssetsWithUploadProperties = (assets: Assets) => {
    const assetsWithUploadProperties = assets.map((asset: Asset) => {
        const newUploadProperties: AssetUploadProperties = {
            hasStartUpload: true,
            isUploaded: true,
            isChecked: false,
            isCancelled: false,
            progress: 100,
            hasError: false,
        };
        return {
            ...asset,
            uploadProperties: newUploadProperties,
            inputSelectRules: [],
        };
    });
    return assetsWithUploadProperties;
};

export const getTemplateFromCached = (templateId: string, templates: Template[]) => {
    const template = templates.find((t: Template) => t.id === templateId);
    return template;
};
