import React from 'react';

import { Tab, Tabs } from 'base/models/Asset';
import { TabContainer } from 'components/EditAsset/TabContainer';
import { EditAssetTitleList } from '../EditAssetTitleList';
import { observer } from 'mobx-react-lite';

interface EditAssetTabListProps {
    tabs: Tabs;
    selectedTab: number;
}

const EditAssetTabList: React.FC<EditAssetTabListProps> = ({
    tabs,
    selectedTab,
}: EditAssetTabListProps) => {
    const tabIndex = selectedTab - 1; // Index elements are based 0
    return (
        <>
            {tabs.map(
                (tab: Tab, index) =>
                    tabIndex === index && (
                        <TabContainer key={tab.name}>
                            <EditAssetTitleList
                                titles={tab.titles}
                                tabName={tab.name}
                                template={tab.template}
                                key={`${tab.name}-${index}`}
                            />
                        </TabContainer>
                    )
            )}
        </>
    );
};

export default observer(EditAssetTabList);
