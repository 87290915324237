import { AssetType } from 'base/models/AssetType';
import {
    Assets,
    AssetUploadProperties,
    Tabs,
    Tab,
    TabTitle,
    Asset,
    TabTitles,
} from 'base/models/Asset';
import { Template, BulkEditField, BulkEditFields } from 'base/models/Template';
import { observable, action, makeObservable, computed } from 'mobx';
import { InputSelectRule } from 'base/models/Asset';
import { RootStore } from './rootStore';
import dayjs from 'dayjs';
import { Title } from 'base/types/title';
import { ReplacementStatus } from 'base/models/Replacement';

const DateFormat = 'DD MMM YYYY';
interface CheckedAssetsAndTitles {
    checkedAssets: Assets;
    titlesWithoutRemovedAssets: TabTitles;
}

export interface PendingUpdate {
    id: string;
    message: string;
}

export interface CommonFields {
    title?: string;
    titleId?: string[];
    templateId?: string;
    productTypeId?: number;
    assetTypeName?: string;
}

export interface AddAssetExternalData {
    title: Title;
    assetType: AssetType;
    assetTypes: AssetType[];
    template: Template;
}

export interface AssetPreviewData {
    assetId: string;
    previewId?: string | null | undefined;
    customPreviewId?: string | null | undefined;
}

export default class EditStore {
    rootStore: RootStore;
    constructor(rootstore: RootStore) {
        makeObservable(this);
        this.rootStore = rootstore;
    }

    @observable tabs: Tabs = [];

    @observable bulkInputSelectRules: InputSelectRule[] = [];
    @observable isEditMode = false;
    @observable assetTypeName = '';
    @observable pendingUpdates: PendingUpdate[] = observable.array();
    @observable isError = false;
    @observable errorText = '';
    // For External Editing and Adding
    @observable isExternalEditing = false;
    @observable isExternalAdding = false;
    @observable requestId: string | null = null;
    @observable addAssetExternalData: AddAssetExternalData | null = null;
    @observable isReplacement = false;
    @observable isSaving = false;
    @observable assetPreviewUpdates: AssetPreviewData[] = [];

    // Tabs Actions
    @action setTabsInEditStore = (newTab: Tab, newTitle: TabTitle) => {
        const isThereTabInStore = this.tabs.some((tab: Tab) => tab.name === newTab.name);
        const isThereTitleInStore = this.tabs.some((tab: Tab) =>
            tab.titles.some((title: TabTitle) => title.name === newTitle.name)
        );

        if (!isThereTabInStore && (!isThereTitleInStore || isThereTitleInStore)) {
            newTab.titles.push(newTitle);
            this.tabs.push(newTab);
        } else if (isThereTabInStore && !isThereTitleInStore) {
            this.tabs = this.tabs.map((tab: Tab) => {
                if (newTab.name === tab.name) {
                    const newTitles = [...tab.titles, newTitle];
                    newTitles.sort((a, b) => (a.name > b.name ? 1 : -1));

                    const tempTab = {
                        ...tab,
                        titles: newTitles,
                    };
                    return tempTab;
                } else return tab;
            });
        } else if (isThereTitleInStore && isThereTabInStore) {
            this.tabs = this.tabs.map((tab: Tab) => {
                if (tab.name === newTab.name) {
                    const tempTitles = tab.titles.map((title: TabTitle) => {
                        if (title.name === newTitle.name) {
                            const tempAssets = [...title.assets, ...newTitle.assets];
                            const tempTitle = {
                                ...title,
                                assets: tempAssets,
                            };
                            return tempTitle;
                        } else return title;
                    });
                    tempTitles.sort((a, b) => (a.name > b.name ? 1 : -1));

                    return {
                        ...tab,
                        titles: tempTitles,
                    };
                } else return tab;
            });
        }
    };

    @action setTabsAsEmptyInEditStore = () => {
        this.tabs = [];
    };

    @action startTabsInEditStore = (assets: Assets, template?: Template) => {
        const newTabs = assets.reduce((tabs, asset) => {
            if (asset.assetGroup && asset.title && asset.assetGroup.name && asset.assetGroup.id) {
                const tab = tabs.find((tab) => tab.name === asset.assetGroup?.name);
                if (tab) {
                    tab.template = template ?? undefined;
                    const title = tab.titles.find((title) => title.name === asset.title?.name);
                    if (title) {
                        title.assets.push(asset);
                    } else {
                        tab.titles.push({
                            assets: [asset],
                            displayId: asset.title.cpmProductId ?? 0,
                            name: asset.title.name,
                            releaseDate: dayjs(asset.title.releaseDate ?? new Date()).format(
                                DateFormat
                            ),
                        });
                    }
                } else {
                    tabs.push({
                        name: asset.assetGroup.name,
                        titles: [
                            {
                                assets: [asset],
                                displayId: asset.title.cpmProductId ?? 0,
                                name: asset.title.name,
                                releaseDate: dayjs(asset.title.releaseDate ?? new Date()).format(
                                    DateFormat
                                ),
                            },
                        ],
                        template: template ?? undefined,
                    });
                }
            }
            return tabs;
        }, [] as Tabs);
        this.tabs = newTabs;
    };

    @action startTabsForMultipleAssetsInEditStore = (
        assets: Assets,
        templatesIdValue: Map<string, Template>
    ) => {
        const newTabs = assets.reduce((tabs, asset) => {
            const template = templatesIdValue.get(asset.templateId ?? '');
            if (asset.assetGroup && asset.title && asset.assetGroup.name && asset.assetGroup.id) {
                const tab = tabs.find((tab) => tab.name === asset.assetGroup?.name);
                if (tab) {
                    tab.template = template ?? undefined;
                    const title = tab.titles.find((title) => title.name === asset.title?.name);
                    if (title) {
                        title.assets.push(asset);
                    } else {
                        tab.titles.push({
                            assets: [asset],
                            displayId: asset.title.cpmProductId ?? 0,
                            name: asset.title.name,
                            releaseDate: dayjs(asset.title.releaseDate ?? new Date()).format(
                                DateFormat
                            ),
                        });
                    }
                } else {
                    tabs.push({
                        name: asset.assetGroup.name,
                        titles: [
                            {
                                assets: [asset],
                                displayId: asset.title.cpmProductId ?? 0,
                                name: asset.title.name,
                                releaseDate: dayjs(asset.title.releaseDate ?? new Date()).format(
                                    DateFormat
                                ),
                            },
                        ],
                        template: template ?? undefined,
                    });
                }
            }
            return tabs;
        }, [] as Tabs);
        this.tabs = newTabs;
    };

    @action setTabTemplate = (tabName: string, template: Template) => {
        this.tabs = this.tabs.reduce((newTabs, tab) => {
            if (tab.name === tabName) {
                tab.template = template;
            }
            return [...newTabs, tab];
        }, [] as Tabs);
    };

    // Select and SubSelect Rules
    @action setNewInputSelectRule = (
        newRule: InputSelectRule,
        tabName: string,
        titleName: string,
        assetId: string
    ) => {
        this.tabs = this.tabs.reduce((newTabs, tab) => {
            if (tab.name === tabName) {
                tab.titles = tab.titles.reduce((newTitles, title) => {
                    if (title.name === titleName) {
                        title.assets = title.assets.reduce((newAssets, asset) => {
                            if (asset.id === assetId) {
                                asset.inputSelectRules?.push(newRule);
                            }
                            return [...newAssets, asset];
                        }, [] as Assets);
                    }
                    return [...newTitles, title];
                }, [] as TabTitles);
            }
            return [...newTabs, tab];
        }, [] as Tabs);
    };

    @action setNewBulkInputSelectRule = (newRule: InputSelectRule) => {
        const hasRule = this.bulkInputSelectRules.some(
            (r) => r.parentFieldName === newRule.parentFieldName
        );
        if (!hasRule) this.bulkInputSelectRules.push(newRule);
    };

    @action updateBulkInputSelectRule = (newRule: InputSelectRule) => {
        this.bulkInputSelectRules = this.bulkInputSelectRules.map((rule) => {
            if (rule.parentFieldName === newRule.parentFieldName) {
                return newRule;
            } else return { ...rule };
        });
    };

    @action updateAllInputSelectRule = (
        newRule: InputSelectRule,
        titleName: string,
        tabName: string
    ) => {
        this.tabs = this.tabs.reduce((newTabs, tab) => {
            if (tab.name === tabName) {
                tab.titles = tab.titles.reduce((newTitles, title) => {
                    if (title.name === titleName) {
                        title.assets = title.assets.reduce((newAssets, asset) => {
                            asset.inputSelectRules = asset.inputSelectRules?.reduce(
                                (newRules, rule) => {
                                    if (rule.fieldName === newRule.fieldName) {
                                        rule = newRule;
                                    }
                                    return [...newRules, rule];
                                },
                                [] as InputSelectRule[]
                            );
                            return [...newAssets, asset];
                        }, [] as Assets);
                    }
                    return [...newTitles, title];
                }, [] as TabTitles);
            }
            return [...newTabs, tab];
        }, [] as Tabs);
    };

    @action updateInputSelectRule = (
        newRule: InputSelectRule,
        tabName: string,
        titleName: string,
        assetId: string
    ) => {
        this.tabs = this.tabs.reduce((newTabs, tab) => {
            if (tab.name === tabName) {
                tab.titles = tab.titles.reduce((newTitles, title) => {
                    if (title.name === titleName) {
                        title.assets = title.assets.reduce((newAssets, asset) => {
                            if (asset.assetId === assetId) {
                                asset.inputSelectRules = asset.inputSelectRules?.reduce(
                                    (newRules, rule) => {
                                        if (rule.fieldName === newRule.fieldName) {
                                            rule = newRule;
                                        }
                                        return [...newRules, rule];
                                    },
                                    [] as InputSelectRule[]
                                );
                            }
                            return [...newAssets, asset];
                        }, [] as Assets);
                    }
                    return [...newTitles, title];
                }, [] as TabTitles);
            }
            return [...newTabs, tab];
        }, [] as Tabs);
    };

    @action getInputSelectRule = (
        childFieldName: string,
        tabName: string,
        titleName: string,
        assetId: string
    ) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);
        const asset = title?.assets.find((asset) => asset.assetId === assetId);
        const rule = asset?.inputSelectRules?.find(
            (inputRule) => inputRule.childFieldName === childFieldName
        );
        return rule;
    };

    @action getBulkInputSelectRule = (childFieldName: string) => {
        const rule = this.bulkInputSelectRules.find(
            (inputRule) => inputRule.childFieldName === childFieldName
        );
        return rule;
    };

    //Pending updates
    @action pushPendingUpdate = (newUpdate: PendingUpdate) => {
        this.pendingUpdates.push(newUpdate);
    };

    @action removePendingUpdate = (pendingUpdate: PendingUpdate) => {
        const newPendingUpdates = this.pendingUpdates.reduce((currentArray, currentUpdate) => {
            return currentUpdate.id === pendingUpdate.id
                ? currentArray
                : [...currentArray, currentUpdate];
        }, [] as PendingUpdate[]);
        this.pendingUpdates = observable.array(newPendingUpdates);
    };

    @action setFieldsByBulkEditInStore = (
        tabName: string,
        titleName: string,
        newFields: BulkEditFields
    ) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);

        if (title && title.assets) {
            const modifiedAssets = title.assets.map((asset: Asset) => {
                const { isChecked } = asset.uploadProperties;

                if (isChecked) {
                    newFields.forEach(
                        ({ fieldName, value }: BulkEditField) => (asset.fields[fieldName] = value)
                    );
                }
                return asset;
            });

            this.tabs = this.tabs.map((tab) => {
                if (tab.name === tabName) {
                    const titles = tab.titles.map((title) => {
                        if (title.name === titleName) {
                            return {
                                ...title,
                                assets: modifiedAssets,
                            };
                        } else return title;
                    });
                    return {
                        ...tab,
                        titles,
                    };
                } else return tab;
            });
        }
    };

    @action setAssetFieldFromTabInStore = (
        fieldName: string,
        value: string | boolean | object,
        tabName: string,
        titleName: string,
        assetId: string
    ) => {
        this.tabs = this.tabs.map((tab: Tab) => {
            if (tab.name === tabName) {
                const updatedTitles = tab.titles.map((title: TabTitle) => {
                    if (title.name === titleName) {
                        const updatedAssets: Assets = title.assets.map((asset: Asset) => {
                            if (asset.id === assetId) {
                                asset.fields[fieldName] = value;

                                return {
                                    ...asset,
                                    fields: asset.fields,
                                };
                            } else return asset;
                        });
                        return {
                            ...title,
                            assets: updatedAssets,
                        };
                    } else return title;
                });
                return {
                    ...tab,
                    titles: updatedTitles,
                };
            } else return tab;
        });
    };

    @action areAllAssignedAssetsChecked = (tabName: string, titleName: string) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);

        return title?.assets.every((asset) => asset.uploadProperties.isChecked);
    };

    @action assetsCheckedInTitleFromStore = (tabName: string, titleName: string) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);

        const totalAssetsChecked = title?.assets.reduce(
            (total, asset) => (asset.uploadProperties.isChecked ? ++total : total),
            0
        );

        return totalAssetsChecked ?? 0;
    };

    @action assetsCheckedInTabFromStore = (tabName: string) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        let totalAssetsChecked = 0;
        tab?.titles.forEach((title) => {
            const sumOfAssets = title?.assets.reduce(
                (total, asset) => (asset.uploadProperties.isChecked ? ++total : total),
                0
            );
            totalAssetsChecked += sumOfAssets;
        });

        return totalAssetsChecked ?? 0;
    };

    @action getCheckedAssetsAndTitlesByTabFromStore = (tabName: string): CheckedAssetsAndTitles => {
        const tab = this.tabs.find((tab) => tab.name === tabName);

        let checkedAssets: Assets = [];
        if (tab?.titles) {
            const titlesWithoutRemovedAssets = tab.titles.reduce((previousTitle, actualTitle) => {
                const checked = actualTitle.assets.filter(
                    (asset) => asset.uploadProperties.isChecked
                );
                checkedAssets = [...checkedAssets, ...checked];
                const isThereAnyChecked = checked.length > 0;

                return isThereAnyChecked ? [...previousTitle, actualTitle] : previousTitle;
            }, [] as TabTitles);

            return {
                checkedAssets,
                titlesWithoutRemovedAssets,
            };
        } else {
            return {
                checkedAssets: [],
                titlesWithoutRemovedAssets: [],
            };
        }
    };

    @action updateTitlesInTabFromStore = (
        tabName: string,
        titlesWithoutRemovedAssets: TabTitles,
        checkedAsset: Asset
    ) => {
        // Over the selected Titles, we remove the checked Assets
        let hasTitleAnyAssetYet = false;
        const titlesWithoutAssets = titlesWithoutRemovedAssets.reduce(
            (previousTitle, actualTitle) => {
                const newAssets = actualTitle.assets.reduce((previousAsset, actualAsset) => {
                    if (
                        checkedAsset.id === actualAsset.id &&
                        actualAsset.uploadProperties.isChecked
                    ) {
                        return previousAsset;
                    } else {
                        return [...previousAsset, actualAsset];
                    }
                }, [] as Assets);
                const tempTitle = {
                    ...actualTitle,
                    assets: newAssets,
                };

                hasTitleAnyAssetYet = newAssets.length > 0;
                return hasTitleAnyAssetYet ? [...previousTitle, tempTitle] : previousTitle;
            },
            [] as TabTitles
        );

        // We overwrite the Tabs with the previously recalculated Titles
        this.tabs = this.tabs.reduce((previousTab, actualTab) => {
            if (actualTab.name === tabName) {
                const newTab = {
                    ...actualTab,
                    titles: titlesWithoutAssets,
                };

                const hasTabAnyTitleYet = newTab.titles.length > 0;
                return hasTabAnyTitleYet ? [...previousTab, newTab] : previousTab;
            } else return [...previousTab, actualTab];
        }, [] as Tabs);

        return { hasTitleAnyAssetYet, titlesWithoutAssets };
    };

    @action updateTitleAssets = (tabName: string, titleName: string, assets: Asset[]) => {
        this.tabs = this.tabs.reduce((newTabs, tab) => {
            if (tab.name === tabName) {
                tab.titles = tab.titles.reduce((newTitles, title) => {
                    if (title.name === titleName) {
                        title = { ...title, assets: [...assets] };
                    }
                    return [...newTitles, title];
                }, [] as TabTitles);
            }
            return [...newTabs, tab];
        }, [] as Tabs);
    };

    @action removeAssetsFromEditStore = (assets: Assets) => {
        assets.forEach((asset) => {
            this.tabs = this.tabs.reduce((newTabs, tab) => {
                const newTitles = tab.titles.reduce((newTitles, title) => {
                    title.assets = title.assets.filter(
                        (oldAsset) => oldAsset.assetId !== asset.assetId
                    );
                    if (title.assets.length > 0) {
                        return [...newTitles, title];
                    } else {
                        return [...newTitles];
                    }
                }, [] as TabTitles);
                if (newTitles.length > 0) {
                    tab.titles = newTitles;
                    return [...newTabs, tab];
                } else {
                    return [...newTabs];
                }
            }, [] as Tabs);
        });
        return this.tabs.length;
    };

    @action cleanAllAssetsFromEditStore = () => {
        this.tabs = [];
    };

    @action removeAssetFromEditStore = (tabName: string, titleName: string, assetId: string) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);

        if (title && title.assets.length > 0) {
            const newAssets: Assets = title.assets.filter(
                (asset: Asset) => !asset.uploadProperties.isChecked
            );

            this.tabs = this.tabs.reduce((newTabs, tab) => {
                if (tab.name === tabName) {
                    tab.titles = tab.titles.reduce((newTitles, title) => {
                        if (title.name === titleName) {
                            title.assets = newAssets;
                        }
                        return [...newTitles, title];
                    }, [] as TabTitles);
                }
                return [...newTabs, tab];
            }, [] as Tabs);
        }
    };

    @action setAssignedAssetsCheckedInStore = (
        checkValue: boolean,
        tabName: string,
        titleName: string,
        assetId?: string
    ) => {
        const tab = this.tabs.find((tab) => tab.name === tabName);
        const title = tab?.titles.find((title) => title.name === titleName);

        if (title && title.assets.length > 0) {
            const newAssets: Assets = title.assets.map((asset: Asset) => {
                if (assetId) {
                    if (asset.id === assetId) {
                        const newProperties: AssetUploadProperties = {
                            ...asset.uploadProperties,
                            isChecked: checkValue,
                        };
                        return {
                            ...asset,
                            uploadProperties: newProperties,
                        };
                    } else return asset;
                } else {
                    const newProperties: AssetUploadProperties = {
                        ...asset.uploadProperties,
                        isChecked: checkValue,
                    };
                    return {
                        ...asset,
                        uploadProperties: newProperties,
                    };
                }
            });
            this.tabs = this.tabs.map((tab: Tab) => {
                if (tab.name === tabName) {
                    const tempTitles = tab.titles.map((title: TabTitle) => {
                        if (title.name === titleName) {
                            const tempAssets = [...newAssets];
                            const tempTitle = {
                                ...title,
                                assets: tempAssets,
                            };
                            return tempTitle;
                        } else return title;
                    });

                    return {
                        ...tab,
                        titles: tempTitles,
                    };
                } else return tab;
            });
        }
    };

    @action setIngestErrorInStore = (value: boolean, text?: string) => {
        this.isError = value;
        this.errorText = text ?? 'There was an error';
    };

    @action areRequiredFieldsFilled = (tabName: string) => {
        let result = true;
        const selectedTab = this.tabs.find((tab) => tab.name === tabName);
        const fields = selectedTab?.template?.fields;
        if (!fields) return false;

        this.tabs.forEach((tab) =>
            tab.titles.forEach((title) =>
                title.assets.forEach((asset) => {
                    fields.forEach((field) => {
                        if (field.isRequired && !asset.fields[field.fieldName]) {
                            result = false;
                            return;
                        }
                    });
                })
            )
        );
        return result;
    };

    @action upsertAssetPreviewUpdate = (assetId: string, previewId: string | null | undefined, customPreviewId: string | null | undefined) => {
        
        const newAssetData: AssetPreviewData = {
            assetId: assetId,
            previewId: previewId,
            customPreviewId: customPreviewId
        };
            
        const existingAssetData = this.assetPreviewUpdates.find(a=>a.assetId === assetId);    
        if(existingAssetData){
            this.assetPreviewUpdates = [...this.assetPreviewUpdates.filter(a=>a.assetId !== assetId)];
        }

        this.assetPreviewUpdates = [...this.assetPreviewUpdates, newAssetData];
    };

    @action cleanAssetPreviewUpdates = () => {
        this.assetPreviewUpdates = [];
    };

    @computed get tabsInEditStore() {
        return this.tabs;
    }

    @computed get getIsAnyUpdatePending() {
        return this.pendingUpdates.length > 0;
    }

    @action setIsExternalEditingInStore = (value: boolean) => {
        this.isExternalEditing = value;
    };

    @computed get getIsExternalEditing() {
        return this.isExternalEditing;
    }

    @action setIsExternalAddingInStore = (value: boolean) => {
        this.isExternalAdding = value;
    };

    @action setExternalDataForAddInStore = (
        asset: Asset,
        requestId: string,
        assetType: AssetType,
        assetTypes: AssetType[],
        template: Template
    ) => {
        this.addAssetExternalData = {
            title: {
                id: asset.title.id,
                name: {
                    primary: asset.title.primaryName as string,
                    secondary: asset.title.secondaryName,
                },
                productType: {
                    id: asset.title.productType?.id as number,
                    name: asset.title.productType?.name as string,
                },
                releaseDate: asset.title.releaseDate as Date,
                identifiers: {
                    productId: asset.title.cpmProductId as number,
                    wprId: asset.title.wprId as string,
                },
            },
            assetType,
            assetTypes,
            template,
        };
        this.isExternalAdding = true;
        this.requestId = requestId;
    };

    @action clearExternalDataForAddInStore = () => {
        this.addAssetExternalData = null;
        this.isExternalAdding = false;
    };

    @action setUploadComplete = (tabName: string, titleName: string, assetId: string) => {
        this.tabs = this.tabs.map((tab: Tab) => {
            if (tab.name === tabName) {
                const updatedTitles = tab.titles.map((title: TabTitle) => {
                    if (title.name === titleName) {
                        const updatedAssets: Assets = title.assets.map((asset: Asset) => {
                            if (asset.assetId === assetId) {
                                asset.uploadProperties.hasError = false;
                                asset.uploadProperties.progress = 100;
                                asset.uploadProperties.isUploaded = true;

                                return {
                                    ...asset,
                                    fields: asset.fields,
                                };
                            } else return asset;
                        });
                        return {
                            ...title,
                            assets: updatedAssets,
                        };
                    } else return title;
                });
                return {
                    ...tab,
                    titles: updatedTitles,
                };
            } else return tab;
        });
    };

    @action setAsperaAssetProgressInEditStore = (
        tabName: string,
        titleName: string,
        assetId: string,
        transferProgress: number
    ) => {
        this.tabs = this.tabs.map((tab: Tab) => {
            if (tab.name === tabName) {
                const updatedTitles = tab.titles.map((title: TabTitle) => {
                    if (title.name === titleName) {
                        const updatedAssets: Assets = title.assets.map((asset: Asset) => {
                            if (asset.assetId === assetId) {
                                const isUploaded = transferProgress === 100;
                                const newUploadProperties = {
                                    ...asset.uploadProperties,
                                    progress: transferProgress,
                                    isUploaded,
                                };

                                return {
                                    ...asset,
                                    uploadProperties: newUploadProperties,
                                };
                            } else return asset;
                        });
                        return {
                            ...title,
                            assets: updatedAssets,
                        };
                    } else return title;
                });
                return {
                    ...tab,
                    titles: updatedTitles,
                };
            } else return tab;
        });
    };

    @action setReplacementStatusInAsset = (
        assetId: string,
        tabName: string,
        status: ReplacementStatus
    ) => {
        if (assetId && tabName) {
            this.tabs = this.tabs.reduce((newTabs, tab) => {
                if (tab.name === tabName) {
                    tab.titles = tab.titles.reduce((newTitles, title) => {
                        title.assets = title.assets.reduce((newAssets, asset) => {
                            if (asset.assetId === assetId && asset.uploadProperties.isChecked) {
                                asset.replacementStatus = status;
                            }
                            return [...newAssets, asset];
                        }, [] as Assets);

                        return [...newTitles, title];
                    }, [] as TabTitles);
                }
                return [...newTabs, tab];
            }, [] as Tabs);
        }
    };

    @action setIsSaving = (isSaving: boolean) => {
        this.isSaving = isSaving;
    };

    @computed get getAddAssetExternalData() {
        return this.addAssetExternalData;
    }

    @computed get getIsExternalAdding() {
        return this.isExternalAdding;
    }

    @action setRequestIdInStore = (requestId: string | null) => {
        this.requestId = requestId;
    };

    @computed get getRequestId() {
        return this.requestId;
    }

    @computed get getIsReplacement() {
        return this.isReplacement;
    }
    @computed get isCompleteUpload() {
        const areCompleted = this.tabs.every((t) =>
            t.titles.every((title) =>
                title.assets.every((asset) => asset.uploadProperties.isUploaded)
            )
        );
        return areCompleted;
    }
    @computed get getAssetsInEditStore() {
        return this.tabs
            .reduce((titles, currentTab) => [...titles, ...currentTab.titles], [] as TabTitles)
            .reduce((assets, currentTitle) => [...assets, ...currentTitle.assets], [] as Assets);
    }

    @computed get getIsSavingInEditStore() {
        return this.isSaving;
    }

    @computed get getIsAnyTabInEditStore() {
        return this.tabs.length > 0;
    }

    @computed get getAssetPreviewUpdates() {
        return this.assetPreviewUpdates;
    }
}
