import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(2),
            },
            borderLeft: '1px solid #F0F0F0',
        },
        outline: {
            border: '1px solid #F0F0F0',
        },
        outlineTopLeft: {
            borderBottom: '1px solid #F0F0F0',
            borderRight: '1px solid #F0F0F0',
            display: 'flex',
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        outlineTopRight: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        outlineBottomLeft: {
            borderBottom: '1px solid #F0F0F0',
            height: '75px',
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(2),
            },
        },
    })
);

export const TextHeaderContainer = styled.div`
    color: #575f72;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
`;
