import React, { useEffect, useState } from 'react';
import {
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './SortGroupModal.styles';
import { getAssetGroupings, saveAssetGroupings } from '../../../base/api/asset';
import { AssetGroup, SortGroup } from 'base/models/Asset';

import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';

import SortAndGroup from 'components/SortAndGroup/containers/SortAndGroup.component';
import { LayoutSize } from 'components/SortAndGroup/models/SortAndGroup.types';
import { ConfirmModal } from '../ConfirmModal';

interface SortGroupModalProps {
    label?: string;
    titleId: string;
    cpmProductId?: number;
    group: AssetGroup;
    open: boolean;
    setOpen: (isOpen: boolean) => void;
    update: (items: SortGroup[]) => void;
}

const SortGroupModal: React.FC<SortGroupModalProps> = ({
    label,
    titleId,
    cpmProductId,
    group,
    open,
    setOpen,
    update,
}) => {
    const classes = useStyles();

    const { setInitialSortGroups, filteredSortGroups } = useRootStoreContext().groupingStore;
    const [isLoading, setIsLoading] = useState(false);
    const [layoutSize, setLayoutSize] = useState<LayoutSize>('medium');
    const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
    const [isSaveAndClose, setIsSaveAndClose] = useState(false);

    const handleLayoutSize = (size: LayoutSize) => {
        setLayoutSize(size);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveItems = async () => {
        setIsLoading(true);
        setInitialSortGroups(filteredSortGroups);

        const response = await saveAssetGroupings(filteredSortGroups, titleId);
        response.status === 200 && update(response.request.assetGroupingList);

        setIsLoading(false);
        isSaveAndClose && setOpen(false);
    };

    const fetchData = async () => {
        setIsLoading(true);
        setInitialSortGroups([]);
        const response = await getAssetGroupings(cpmProductId, group.id, titleId);
        if (response.items) {
            setInitialSortGroups(response.items);
        }
        setIsLoading(false);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSaveClick = (params: { close: boolean } = { close: false }) => {
        hidePopupMenu();
        setIsConfirmSaveModalOpen(true);
        setIsSaveAndClose(params.close);
    };

    const hidePopupMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle>
                {isLoading ? (
                    <Grid spacing={2} container direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <SyncIcon className={classes.spinningIcon} fontSize="large" />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Grid item zeroMinWidth={true} className={classes.title}>
                            <Typography variant="h5">
                                {`${label ? label + ' > ' : ''}${
                                    group.name
                                }  (${filteredSortGroups.reduce(
                                    (acc, next) => acc + next.assets?.length ?? 0,
                                    0
                                )})`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            alignItems="center"
                            className={classes.sizesWrapper}
                            justify="flex-end"
                            container
                            zeroMinWidth={true}
                        >
                            <span>View Size: </span>
                            {['SMALL', 'MEDIUM', 'LARGE'].map((m: string) => (
                                <div
                                    title={`${m.toLowerCase()} view`}
                                    className={`${classes.sizeButton} ${
                                        layoutSize === m.toLowerCase() && 'active'
                                    }`}
                                    key={m}
                                    onClick={() => {
                                        handleLayoutSize(m.toLowerCase() as LayoutSize);
                                    }}
                                >
                                    {m.charAt(0)}
                                </div>
                            ))}
                        </Grid>
                        <Grid
                            item
                            justify="flex-end"
                            container
                            zeroMinWidth={true}
                            className={classes.headerActionsContainer}
                        >
                            <Button
                                onClick={handleClose}
                                variant="outlined"
                                className={classes.outlinedButtom}
                            >
                                Close
                            </Button>

                            <Button
                                className={classes.saveButton}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                variant="contained"
                                endIcon={<ExpandMore />}
                            >
                                Save
                            </Button>
                            <Menu
                                id="dmdc-sort-group--menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={hidePopupMenu}
                            >
                                <MenuItem onClick={() => handleSaveClick({ close: true })}>
                                    Save and Close
                                </MenuItem>
                                <MenuItem onClick={() => handleSaveClick()}>Save and Stay</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                )}
            </DialogTitle>
            <DialogContent dividers style={{ overflowY: 'auto' }}>
                <SortAndGroup size={layoutSize} />
            </DialogContent>
            <ConfirmModal
                open={isConfirmSaveModalOpen}
                setOpen={setIsConfirmSaveModalOpen}
                onConfirm={saveItems}
                title="Confirm Save"
                message="Are you sure you want to save your changes?"
            />
        </Dialog>
    );
};

export default observer(SortGroupModal);
