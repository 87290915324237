import { makeStyles, TableCell } from '@material-ui/core';
import styled from 'styled-components';

const borderColor = '#bababa';

export const useStyles = makeStyles((theme) => ({
    scrollBar: {
        'max-height': '65vh',
        'box-shadow': 'none',
        'border-top': `1px solid ${borderColor}`,
        'border-bottom': `1px solid ${borderColor}`,
        'border-radius': 0,
        '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid rgba(0,0,0,.1)',
        },
    },
    gridRoot: {
        '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(2n):not(.Mui-selected), & .MuiTableBody-root .MuiTableRow-root:nth-of-type(2n):not(.Mui-selected) td':
            {
                backgroundColor: 'rgb(245,245,245)',
                '& .asset-list-cta button': {
                    backgroundColor: '#fff',
                },
            },
        '& .MuiTableBody-root tr:not(:last-child) .MuiTableCell-root': {
            borderBottom: 0,
        },
    },
}));

const CellStaticContentRules = `
    position: static;
    left: 0;
    z-index: 1;
    background-color: #fff;
 `;

const CellHeaderRules = `    
    width: 100%;
    border-right: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
    min-width: 150px;
    color: #575f72;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    text-align: left;
    background-color: rgba(225,225,225);
`;

export const CellStaticContent = styled(TableCell)`
    ${CellStaticContentRules}
`;

export const CellHeader = styled(TableCell)`
    ${CellHeaderRules}
`;

export const CellHeaderSticky = styled(TableCell)`
    ${CellStaticContentRules}
    ${CellHeaderRules}
    z-index: 10;
    position: sticky;
`;

export const TableCellField = styled(TableCell)`
    padding: 6px 16px;
    min-width: 150px;
`;

export const FileNameContainer = styled.div`
    max-width: 250px;
    min-width: 250px;
    word-break: break-all;

    color: #444a55;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    display: inline-flex;
    align-items: center;
    text-align: left;
`;

export const FileNameAndAvatarContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CheckboxHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
