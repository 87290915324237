import React, { useState, useEffect, useMemo } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Button,
    Tooltip,
} from '@material-ui/core';
import { Assets, SortGroup } from 'base/models/Asset';
import { StyledExpandMoreIcon, useStyles } from './EditAssetTitle.styles';
import { EditGrid } from '../EditGrid';
import BulkEdit from 'components/EditForm/BulkEdit/BulkEdit.component';
import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Template } from 'base/models/Template';
import Grid from '@material-ui/core/Grid';
import WidgetsIcon from '@material-ui/icons/Widgets';
import SortGroupModal from '../../shared/SortGroupModal/SortGroupModal.component';
import ConfirmModal from '../../shared/ConfirmModal/ConfirmModal.component';
import { useQueryClient } from 'react-query';
import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';

interface EditAssetTitleProps {
    titleName: string;
    titleProductId: number;
    titleReleaseDate?: string;
    assets: Assets;
    tabName: string;
    template: Template | undefined;
}

const EditAssetTitle: React.FC<EditAssetTitleProps> = ({
    titleName,
    titleProductId,
    assets,
    tabName,
    template,
    titleReleaseDate,
}: EditAssetTitleProps) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(true);
    const [isBulkEditOpen, setIsBulkEditOpen] = useState(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
    const [isShortGroupModalOpen, setIsShortGroupModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const {
        assetsCheckedInTitleFromStore,
        setTabTemplate,
        tabsInEditStore,
        getIsSavingInEditStore: isSavingAssets,
        getIsAnyUpdatePending,
        updateTitleAssets,
    } = useRootStoreContext().editStore;
    const templateQueryClient = useQueryClient();

    // Order that define the order of the columns of the grid
    const fields = useMemo(() => template?.fields ?? [], [template]);
    const [orderFields, setOrderFields] = useState(
        new Array(fields.length).fill(null).map((_, index) => index)
    );

    useEffect(() => {
        setOrderFields(new Array(fields.length).fill(null).map((_, index) => index));
    }, [fields]);

    useEffect(() => {
        const loadTemplate = async () => {
            if (!template) {
                // All asset should have the same template
                const [firstAsset] = assets;
                if (firstAsset.templateId) {
                    const queryObject: any = templateQueryClient.getQueryData([
                        QUERY_IDENTIFIERS.ASSET_TEMPLATES,
                    ]);
                    if (queryObject && queryObject.data) {
                        const cachedTemplates = queryObject.data as Template[];
                        const template = cachedTemplates.find(
                            (t: Template) => t.id === firstAsset.templateId
                        );

                        if (template) {
                            setTabTemplate(tabName, template);
                        }
                    }
                }
            }
        };
        loadTemplate().then(() => {
            setIsLoadingTemplate(false);
        });
    }, []);

    const handleIsExpanded = () => setIsExpanded((prev) => !prev);

    const handleSortGroupModal = () => {
        handleIsExpanded();
        if (getIsAnyUpdatePending) {
            setIsConfirmModalOpen(true);
        } else {
            setIsShortGroupModalOpen((isOpen) => !isOpen);
        }
    };

    const handleSortGroupChanged = (changes: SortGroup[]) => {
        const assetChangesMap = changes.reduce((acc, curr) => {
            curr.assets.forEach((changedAsset) => {
                acc.set(`${changedAsset.id}`, { ...changedAsset, group: curr.name });
            });
            return acc;
        }, new Map());

        const newAssets = assets.map((asset) => {
            const changedAsset = assetChangesMap.get(asset.id);
            return changedAsset
                ? {
                      ...asset,
                      fields: {
                          ...asset.fields,
                          sortOrder: changedAsset.sortOrder,
                          isParent: changedAsset.isMain,
                          groupChoice: changedAsset.group,
                      },
                  }
                : asset;
        });

        updateTitleAssets(tabName, titleName, newAssets);
    };

    useEffect(() => {
        const numberOfCheckedAssets = assetsCheckedInTitleFromStore(tabName, titleName);
        const isBulkEditOpen =
            (numberOfCheckedAssets > 1 || (numberOfCheckedAssets === 1 && assets.length === 1)) &&
            !isSavingAssets;
        setIsBulkEditOpen(isBulkEditOpen);
    }, [tabsInEditStore, isSavingAssets]);

    return (
        <div className={classes.root}>
            <Accordion elevation={0} expanded={isExpanded}>
                <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<StyledExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={handleIsExpanded}
                >
                    <>
                        <Grid
                            className={classes.titleHeader}
                            direction="row"
                            spacing={0}
                            container
                            alignItems="center"
                        >
                            <Grid item xs={8} className={classes.flexStart}>
                                <strong>{`${titleName}`}</strong>
                            </Grid>
                            <Grid item xs={4} className={classes.flexEnd}>
                                <strong>
                                    {`${titleProductId !== 0 ? titleProductId : ''}
                                    ${
                                        assets[0].title.wprId !== null
                                            ? ` (${assets[0].title.wprId})`
                                            : ''
                                    }
                                    ${titleReleaseDate ? `  |  ${titleReleaseDate}` : ''}`}
                                </strong>
                                <Tooltip title={'Sort & Group'}>
                                    <Button
                                        className={`${classes.sortGroupButton}`}
                                        onClick={handleSortGroupModal}
                                    >
                                        <WidgetsIcon />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                </AccordionSummary>
                <AccordionDetails>
                    {isBulkEditOpen && (
                        <BulkEdit
                            assets={assets}
                            titleName={titleName}
                            tabName={tabName}
                            template={template}
                            fields={fields}
                            orderFields={orderFields}
                        />
                    )}

                    {isLoadingTemplate ? (
                        <CircularProgress />
                    ) : (
                        <EditGrid
                            assets={assets}
                            titleName={titleName}
                            tabName={tabName}
                            template={template}
                            fields={fields}
                            orderFields={orderFields}
                            setOrderFields={setOrderFields}
                        />
                    )}
                    <SortGroupModal
                        label={titleName}
                        titleId={assets[0].title.id}
                        cpmProductId={assets[0].title.cpmProductId}
                        group={assets[0].assetGroup}
                        open={isShortGroupModalOpen}
                        setOpen={setIsShortGroupModalOpen}
                        update={handleSortGroupChanged}
                    />
                    <ConfirmModal
                        open={isConfirmModalOpen}
                        setOpen={setIsConfirmModalOpen}
                        onConfirm={handleSortGroupModal}
                        title="You have uncommit changes"
                        message="Are you sure you want continue to Sort & Group?"
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default observer(EditAssetTitle);
