import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getUsers } from '../../../base/api/dashboard';
import { useRootStoreContext } from 'base/stores/rootStore';
import { ManageUsersTextField, AutoCompleteTextField, DivContainer } from './UserFilter.styles';
import { UserDropdown } from 'base/models/DashboardProps';

type UserFilterProps = {
    updateUserSelected: (arg: UserDropdown) => void;
    userLogged: UserDropdown;
};

const UserFilter: React.FC<UserFilterProps> = ({ updateUserSelected, userLogged }) => {
    const [users, setUsers] = useState<UserDropdown[]>([] as UserDropdown[]);
    const { getUserFilterInStore: userFilter } = useRootStoreContext().dashboardStore;
    const [selectedUser, setSelectedUser] = useState<UserDropdown>(userFilter);

    useEffect(() => {
        getData();
        async function getData() {
            const { response } = await getUsers();
            if (response && response.data) {
                const filteredUsers = response.data;
                if (userLogged && filteredUsers.includes(userLogged) === false)
                    filteredUsers.push(userLogged);
                setUsers(filteredUsers);
            }
        }
    }, []);

    useEffect(() => {
        setSelectedUser(userFilter);
    }, [userFilter.fullname]);

    function updateNameValue(name: UserDropdown) {
        setSelectedUser(name);
    }

    return (
        <DivContainer>
            <ManageUsersTextField
                value="Manage Assets"
                variant="outlined"
                size="small"
                disabled
                inputProps={{
                    min: 0,
                    style: {},
                }}
            />
            <Autocomplete
                onChange={(event, newValue) => {
                    updateUserSelected(newValue as UserDropdown);
                    updateNameValue(newValue as UserDropdown);
                }}
                id="user-selected"
                options={users}
                style={{ width: '100%' }}
                size="small"
                value={selectedUser}
                getOptionSelected={(option, selected) => option.email === selected.email}
                getOptionLabel={(option: UserDropdown) => option.fullname}
                renderInput={(params) => {
                    return (
                        <AutoCompleteTextField
                            {...params}
                            placeholder="Search name"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    );
                }}
            />
        </DivContainer>
    );
};

export default UserFilter;
