import { createMuiTheme } from '@material-ui/core/styles';

/// <reference path="overrides-mui.d.ts"/>

const DMDCentralTheme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto', '"Helvetica Neue"', 'sans-serif'].join(','),
    },
    palette: {
        primary: { '500': '#3DA8DF' },
        secondary: { main: '#D53030' },
        success: { main: '#0DAC36' },
        error: { main: '#D53030' },
        warning: { main: '#FFDD57' },
    },
    overrides: {
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: '#7E899E',
                },
            },
            colorSecondary: {
                '&$checked': {
                    color: '#c0ffee',
                },
            },
        },
        MuiPickersDay: {
            daySelected: {
                color: '#fff',
            },
        },
        MuiButton: {
            containedPrimary: {
                color: '#fff',
            },
            outlined: {
                fontSize: '14px',
                fontWeight: 'bold',
                letterSpacing: 0,
                borderRadius: '2px',
                border: 'none !important',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
                minWidth: '110px',
                paddingTop: '7px',
                textTransform: 'none',
            },
            label: {
                '& .MuiPickersToolbarText-toolbarBtnSelected': {
                    color: '#fff',
                },
                '& .MuiPickersToolbarText-toolbarTxt': {
                    color: '#fff',
                },
            },
        },
        MuiDialog: {
            paper: {
                borderTop: '10px solid #3DA8DF',
                borderRadius: '1px',
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#3DA8DF',
                height: 5,
            },
        },
        MuiTab: {
            wrapper: {
                fontWeight: 'bold',
                textTransform: 'none',
            },
        },
        MuiStepIcon: {
            root: {
                '&$active': {
                    '& $text': {
                        fill: '#fff',
                    },
                },
            },
            text: {
                fill: '#fff',
            },
        },
    },
});

export default DMDCentralTheme;
