import { Button, makeStyles, Paper, TableCell } from '@material-ui/core';
import styled from 'styled-components';

const borderColor = '#bababa';

export const useStyles = makeStyles((theme) => ({
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid rgba(0,0,0,.1)',
        },
    },
}));

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CellStaticContentRules = `
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;
 `;

export const CellStaticContent = styled(TableCell)`
    ${CellStaticContentRules}
    border-bottom: none;
`;

export const CellStaticContentBorderRight = styled(TableCell)`
    ${CellStaticContentRules}
    border-right: 1px solid ${borderColor};
`;

export const TableCellField = styled(TableCell)`
    padding: 6px 16px;
    min-width: 150px;
`;

export const ApplySelectedContainer = styled.div`
    margin-left: 30px;
    width: 310px;
    display: flex;
    justify-content: start;
    font-size: 18px;
`;

export const ApplyButtonContainer = styled.div`
    background-color: #fff;
    padding: 0 0 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ApplyButton = styled(Button)`
    color: #216085;
    background-color: #ffffff;
    border-color: #575f72;
    border-style: solid;
    border-width: 1px;
`;
