import * as React from 'react';

import { createStyles, makeStyles, Theme, styled } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            text: theme.palette.primary,
        },
        menuButton: {
            color: '#fff',
            height: 48,
            width: 48,
            padding: 0,
        },
        header: {
            padding: '16px 20px',
            color: '#fff',
            background: '#000',
            wordBreak: 'break-word',
        },
    })
);

export const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(
    ({ theme }) => ({
        '& .MuiPaper-root': {
            fontFamily: 'GT America',
            borderRadius: 4,
            marginTop: theme.spacing(1),
            minWidth: 180,
            boxShadow:
                ' 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
            '& .MuiMenu-list': {
                padding: '0 0 .5rem 0',
                width: 368,
            },
            '& .MuiMenuItem-root': {
                fontFamily: 'GT America',
                fontWeight: 400,

                color: '#0d4179',

                '&:hover': {
                    background: 'rgba(0,0,0,.04)',
                },
            },
            '& .MuiDivider-root': {
                marginBottom: 8,
            },
        },
    })
);
