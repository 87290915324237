import styled from 'styled-components';

export const DivTitleContainer = styled.div`
    /* font-size: 0.75rem; */
    height: 32px;
    width: 213px;
    color: #1d1d1d;
    letter-spacing: 0;
    line-height: 29px;

    @media (min-width: 769px) {
        font-size: 21px;
        font-weight: 300;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
        font-weight: 500;
    }
`;
