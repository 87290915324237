import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'base/stores/rootStore';
import { SortGroup, SortGroupAsset } from 'base/models/Asset';
import { v4 as uuidv4 } from 'uuid';

export class GroupingStore {
    rootStore: RootStore;
    constructor(rootstore: RootStore) {
        makeObservable(this);
        this.rootStore = rootstore;
    }

    @observable initialSortGroups: SortGroup[] = [];
    @observable sortGroups: SortGroup[] = [];

    @observable isDragging = false;

    @action setIsDragging = (newState: boolean) => {
        this.isDragging = newState;
    };

    @action setInitialSortGroups = (newInitialSortGroup: SortGroup[]) => {
        this.initialSortGroups = [...newInitialSortGroup];
        this.sortGroups = [...newInitialSortGroup];
    };

    @action resetSortGroups = () => {
        this.setInitialSortGroups([...this.initialSortGroups]);
    };

    @action setSortGroups = (newSortGroups: SortGroup[]) => {
        const groupWithOrphans = newSortGroups.reduce((reducedSortGroups, sortGroup) => {
            if (sortGroup.assets) {
                if (sortGroup.assets.length > 0) {
                    return [...reducedSortGroups, sortGroup];
                } else {
                    return reducedSortGroups;
                }
            } else {
                const newSortGroup = {
                    id: uuidv4(),
                    name: sortGroup.name ?? '',
                    sortOrder: 0,
                    type: 'group',
                    assets: [
                        {
                            id: sortGroup.id,
                            name: sortGroup.name ?? '',
                            group: sortGroup.name ?? '',
                            // @ts-ignore
                            imageUrl: sortGroup.imageUrl,
                            // @ts-ignore
                            isMain: sortGroup.isMain,
                            sortOrder: sortGroup.sortOrder,
                            type: sortGroup.type,
                        },
                    ],
                };
                return [...reducedSortGroups, newSortGroup];
            }
        }, [] as SortGroup[]);
        this.sortGroups = groupWithOrphans.filter((group) => group.assets.length > 0);
    };

    @action editSortGroup = (newSortGroup: SortGroup) => {
        const sortGroupIndex = this.sortGroups.findIndex((sg) => sg.id === newSortGroup.id);
        if (sortGroupIndex > -1) {
            this.sortGroups[sortGroupIndex] = { ...newSortGroup };
        }
    };

    @action updateGroupAssets = (groupId: string, newAssets: SortGroupAsset[]) => {
        const groupIndex = this.sortGroups.findIndex((sG) => sG.id === groupId);
        if (groupIndex > -1) {
            const [mainAsset] = newAssets;
            this.sortGroups[groupIndex] = {
                ...this.sortGroups[groupIndex],
                name: this.sortGroups[groupIndex].name ?? mainAsset.name,
                assets: [...newAssets],
            };
        }
        if (this.sortGroups[groupIndex].assets.length === 0) {
            this.sortGroups.splice(groupIndex, 1);
        }
    };

    @action moveToUpByIdx = (groupIndex: number) => {
        const elementsToMove = 0;
        const indexToStart = 0;
        const [groupToMove] = this.sortGroups.splice(groupIndex, 1);

        this.sortGroups.splice(indexToStart, elementsToMove, groupToMove);
    };

    @action moveToBottomByIdx = (groupIndex: number) => {
        const numberOfGroups = this.sortGroups.length;
        const lastElementIndex = numberOfGroups - 1;
        const [groupToMove] = this.sortGroups.splice(groupIndex, 1);

        this.sortGroups.splice(lastElementIndex, 0, groupToMove);
    };

    @computed get filteredSortGroups() {
        return this.sortGroups.filter((f) => f.assets?.length > 0);
    }

    @action editGroupName = (group: SortGroup, newName: string) => {
        const groupToEdit = this.sortGroups.find((sg) => sg.id === group.id);
        if (groupToEdit) {
            this.sortGroups[this.sortGroups.indexOf(group)].name = newName;
        }
    };
}
