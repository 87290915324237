export enum assetStatusText {
    DRAFT = 'Draft',
    IN_PROGRESS = 'In progress',
    NOT_PUBLISHED = 'Not published',
    PUBLISHED = 'Published',
    FAILED = 'Failed',
    HOLDBACK = 'Holdback',
}

export enum groupType {
    TYPE_TITLE = 'Type',
    TITLE = 'Title',
}

export interface StatusCountType {
    Draft: number;
    InProgress: number;
    NotPublished: number;
    Published: number;
    Failed: number;
    Holdback: number;
}

export const statusCountDefault: StatusCountType = {
    Draft: 0,
    InProgress: 0,
    NotPublished: 0,
    Published: 0,
    Failed: 0,
    Holdback: 0,
};

export enum dateTypes {
    DATE_TYPE_REMAINING = 'Remaining',
    DATE_TYPE_STANDARD = 'Standard',
}

export interface UserDropdown {
    fullname: string;
    email: string;
}
