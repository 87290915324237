import { createContext, useContext } from 'react';
import IngestionStore from './ingestionStore';
import EditStore from './editStore';
import { SettingsStore } from './settingsStore';
import { DashboardStore } from './dashboardStore';
import { PreviewStore } from './previewStore';
import { GroupingStore } from './groupingStore';
import TransferStore from './transferStore';
import { ContextSwitcherStore } from './contextSwitcherStore';
export class RootStore {
    ingestionStore: IngestionStore;
    editStore: EditStore;
    settingsStore: SettingsStore;
    dashboardStore: DashboardStore;
    previewStore: PreviewStore;
    groupingStore: GroupingStore;
    transferStore: TransferStore;
    contextSwitcherStore: ContextSwitcherStore;
    constructor() {
        this.ingestionStore = new IngestionStore(this);
        this.editStore = new EditStore(this);
        this.settingsStore = new SettingsStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.previewStore = new PreviewStore(this);
        this.groupingStore = new GroupingStore(this);
        this.transferStore = new TransferStore(this);
        this.contextSwitcherStore = new ContextSwitcherStore(this);
    }
}
export const store = new RootStore();
export const RootStoreContext = createContext<RootStore>(store);

export const useRootStoreContext = () => {
    return useContext(RootStoreContext);
};
