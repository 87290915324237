import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface LayoutProps {}

export const useStyles = makeStyles<Theme, LayoutProps>((theme: Theme) =>
    createStyles({
        cardMediaCentered: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
    })
);
