export enum AssetStatus {
    Failed = 10,
    Draft = 0,
    InProgress = 9,
    NotPublished = 1,
    Published = 7,
    Holdback = 11,
}

export enum AsperaIngestStatus {
    InProgress = 1,
    Completed = 2,
    Failed = 3,
}

export enum ReplacementStatusEnum {
    InProgress = 1,
    Success,
    Failure,
}
