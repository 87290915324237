import { getAssetStatusCount } from 'base/api/dashboard';
import { StatusCountType, statusCountDefault } from 'base/models/DashboardProps';

interface ResponseCount {
    status: string;
    count: number;
}

type ResponseCounts = ResponseCount[];

export const UpdateCounter = async (userName: string) => {
    let counterResult = statusCountDefault;
    if (userName) {
        const { response } = await getAssetStatusCount(userName);
        if (response && response.data) {
            const data: ResponseCounts = response.data;

            let tempStatusCount: StatusCountType = statusCountDefault;

            for (const [, value] of Object.entries(data)) {
                switch (value.status) {
                    case 'Draft':
                        tempStatusCount = { ...tempStatusCount, Draft: value.count };
                        break;
                    case 'InProgress':
                        tempStatusCount = { ...tempStatusCount, InProgress: value.count };
                        break;
                    case 'NotPublished':
                        tempStatusCount = { ...tempStatusCount, NotPublished: value.count };
                        break;
                    case 'Published':
                        tempStatusCount = { ...tempStatusCount, Published: value.count };
                        break;
                    case 'Failed':
                        tempStatusCount = { ...tempStatusCount, Failed: value.count };
                        break;
                    case 'Holdback':
                        tempStatusCount = { ...tempStatusCount, Holdback: value.count };
                        break;
                }
            }

            counterResult = tempStatusCount;
        }
    }
    return counterResult;
};
