import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const ColorButton = withStyles((theme) => ({
    root: {
        color: '#216085',
        textTransform: 'none',
        border: '1px solid #216085',
        borderColor: '#216085',
        boxShadow: 'none',
        borderRadius: '4px',
    },
}))(Button);

export const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            border: '1px solid #216085',
            color: '#216085',
            borderRadius: '4px',
            '&:hover': {
                background: '#216085',
                color: '#fff',
            },
        },
        iconButton: { color: '#575F72', fontSize: '32px' },
    })
);
