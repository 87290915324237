import React, { useState } from 'react';

import { TableRow, Checkbox, Grid, Chip, IconButton } from '@material-ui/core';
import dayjs from 'dayjs';

import {
    CellStaticContentBorderRight,
    FileNameContainer,
    FileNameAndAvatarContainer,
    TableCellField,
    useStyles,
} from './EditGridRow.styles';

import { AssetStatus, ReplacementStatusEnum } from 'base/enums/AssetStatus';
import { Asset } from 'base/models/Asset';
import { TemplateField, statusFieldName, Preset } from 'base/models/Template';

import { Preview } from 'components/shared/Preview';
import { DynamicFieldItem } from 'components/EditForm/DynamicFieldItem';
import { UploadProgress } from '../UploadProgress';
import LaunchIcon from '@material-ui/icons/Launch';
import { DisplayDSModal } from 'components/Dashboard/DigitalScreenerComponent/Modal';

interface EditGridRowProps {
    asset: Asset;
    orderFields: number[];
    fields: TemplateField[];
    isSaving: boolean;
    selectedPresets: Preset | undefined;
    titleName: string;
    tabName: string;
    handleCheckboxItemChange: (e: React.ChangeEvent<HTMLInputElement>, assetId: string) => void;
    handleTableCellFocus: (
        _: React.FocusEvent<HTMLTableHeaderCellElement>,
        assetId: string
    ) => void;
    handleFieldBlur: (fieldName: string, value: any) => void;
}

const createdByFieldName = 'assetCreatedBy';
const createdDateFieldName = 'assetCreated';
const modifiedByFieldName = 'assetModifiedBy';
const modifiedDateFieldName = 'assetModifiedDate';

const EditGridRow = ({
    asset,
    orderFields,
    fields,
    isSaving,
    selectedPresets,
    titleName,
    tabName,
    handleCheckboxItemChange,
    handleTableCellFocus,
    handleFieldBlur,
}: EditGridRowProps) => {
    const classes = useStyles();
    const showSnipes =
        asset.fields.isHoldback ||
        asset.fields.assetRestrictions ||
        asset.fields.staffCustomers ||
        asset.fields.isExclusive ||
        asset.fields.isLimitedUse ||
        asset.fields.isIntl ||
        asset.fields.status === AssetStatus.NotPublished ||
        asset.fields.status === AssetStatus.InProgress ||
        asset.replacementStatus;
    const [isDSModalOpen, setIsDSModalOpen] = useState(false);

    const handleDSModal = async () => {
        setIsDSModalOpen(true);
    };

    return (
        <TableRow key={asset.id}>
            <CellStaticContentBorderRight align="justify">
                <Grid container wrap="nowrap" alignItems="center">
                    <Checkbox
                        color="primary"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCheckboxItemChange(e, asset.id)
                        }
                        checked={asset.uploadProperties.isChecked}
                    />

                    <FileNameAndAvatarContainer>
                        <div style={{ paddingRight: '10px' }}>
                            <Preview asset={asset} isFirstPreview={false} previewStyle={''} />
                        </div>
                        {showSnipes ? (
                            <div>
                                <FileNameContainer title={asset.fields.fileName}>
                                    <UploadProgress
                                        asset={asset}
                                        tabName={tabName}
                                        titleName={titleName}
                                    />
                                    {asset.fields.fileName}
                                </FileNameContainer>

                                <div>
                                    {asset.fields.isHoldback && (
                                        <Chip className={classes.holdback} label="Holdback" />
                                    )}
                                    {asset.replacementStatus?.id ===
                                        ReplacementStatusEnum.Failure && (
                                        <Chip className={classes.error} label="Replacement Error" />
                                    )}
                                    {asset.replacementStatus?.id ===
                                        ReplacementStatusEnum.InProgress && (
                                        <Chip
                                            className={classes.inProgress}
                                            label="Replacement In Progress"
                                        />
                                    )}
                                    {(asset.fields.assetRestrictions ||
                                        asset.fields.isLimitedUse ||
                                        asset.fields.isIntl) && (
                                        <Chip
                                            className={classes.restrictions}
                                            label="Restrictions"
                                        />
                                    )}
                                    {asset.fields.staffCustomers && (
                                        <Chip className={classes.tvdOnly} label="TVD Only" />
                                    )}
                                    {asset.fields.isExclusive && (
                                        <Chip className={classes.exclusive} label="Exclusive" />
                                    )}
                                    {asset.fields.status === AssetStatus.NotPublished && (
                                        <Chip
                                            className={classes.unpublished}
                                            label="Not Published"
                                        />
                                    )}
                                    {asset.fields.status === AssetStatus.InProgress && (
                                        <Chip className={classes.inProgress} label="In Progress" />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <FileNameContainer title={asset.fields.fileName}>
                                <UploadProgress
                                    asset={asset}
                                    tabName={tabName}
                                    titleName={titleName}
                                />
                                {asset.fields.fileName}
                            </FileNameContainer>
                        )}
                        {asset.packageId ? (
                            <IconButton
                                aria-label="more"
                                aria-haspopup="true"
                                onClick={handleDSModal}
                                className={classes.smallIcon}
                            >
                                <LaunchIcon />
                            </IconButton>
                        ) : null}
                        {isDSModalOpen ? (
                            <DisplayDSModal
                                packageId={asset.packageId || ''}
                                open={isDSModalOpen}
                                setOpen={setIsDSModalOpen}
                            ></DisplayDSModal>
                        ) : null}
                    </FileNameAndAvatarContainer>
                </Grid>
            </CellStaticContentBorderRight>

            {orderFields.map((columnIndex) => {
                const {
                    fieldName,
                    isIdValue,
                    isVisible,
                    fieldType,
                    label,
                    isRequired,
                    options,
                    childFieldName,
                    validationErrorMessage,
                    dmdcTargetField,
                } = fields[columnIndex];
                let { isDisabled } = fields[columnIndex];

                let value: any;
                switch (fieldName) {
                    case createdDateFieldName:
                        value = dayjs(asset.created?.date ?? new Date()).format('YYYY-MM-DD');
                        break;
                    case createdByFieldName:
                        value = asset.created?.user?.name ?? asset.created?.user?.email;
                        break;
                    case modifiedDateFieldName:
                        value = dayjs(asset.modified?.date ?? new Date()).format('YYYY-MM-DD');
                        break;
                    case modifiedByFieldName:
                        value = asset.modified?.user?.name ?? asset.modified?.user?.email;
                        break;
                    default:
                        value = asset.fields[fieldName];
                        break;
                }
                // TODO: Should verify only one status not both
                const isStatusFieldDisabled =
                    fieldName === statusFieldName &&
                    asset.status.id === AssetStatus.Draft &&
                    asset.fields.status === AssetStatus.Draft; // asset.fields.status is comming undefined
                if (
                    fieldName === statusFieldName &&
                    asset.fields.status === AssetStatus.InProgress
                ) {
                    options?.push({
                        parentFieldValue: '',
                        display: 'In Progress',
                        options: undefined,
                        value: AssetStatus.InProgress.toString(),
                    });
                    value = AssetStatus.InProgress.toString();
                    isDisabled = true;
                }
                return (
                    isVisible && (
                        <TableCellField
                            key={fieldName}
                            variant="body"
                            onFocus={(e: React.FocusEvent<HTMLTableHeaderCellElement>) =>
                                handleTableCellFocus(e, asset.id)
                            }
                        >
                            <DynamicFieldItem
                                key={fieldName}
                                fieldType={fieldType}
                                fieldName={fieldName}
                                label={label}
                                isRequired={isRequired}
                                isVisible={isVisible}
                                isDisabled={isDisabled || isStatusFieldDisabled || isSaving}
                                isIdValue={isIdValue}
                                options={options}
                                childFieldName={childFieldName}
                                validationErrorMessage={validationErrorMessage}
                                dmdcTargetField={dmdcTargetField}
                                presets={selectedPresets}
                                onFieldBlur={handleFieldBlur}
                                value={value ?? ''}
                                tabName={tabName}
                                titleName={titleName}
                                assetId={asset.id}
                            ></DynamicFieldItem>
                        </TableCellField>
                    )
                );
            })}
        </TableRow>
    );
};

export default EditGridRow;
