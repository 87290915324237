import React, { Fragment, useEffect } from 'react';
import { TextareaAutosize, FormControl } from '@material-ui/core';
import BaseFieldProps from 'base/models/BaseFieldProps';

export interface InputTextAreaProps extends BaseFieldProps {
    rowsMin: number;
    rowsMax: number;
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
    label,
    isDisabled,
    isRequired,
    isVisible,
    fieldName,
    dmdcTargetField,
    rowsMax,
    rowsMin,
    value,
    onChange,
    onBlur,
}: InputTextAreaProps) => {
    const [text, setText] = React.useState(value ?? '');
    const [error, setError] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
        if (onChange) {
            onChange(fieldName, event.target.value);
        }
    };

    const handleValidateField = () => {
        const isRequiredAndNotEmpty = !text && isRequired ? true : false;
        setError(isRequiredAndNotEmpty);
        if (onBlur) {
            onBlur(fieldName, text);
        }
    };

    useEffect(() => {
        if (value) setText(value);
    }, [value]);

    return (
        <Fragment>
            {isVisible && (
                <FormControl
                    disabled={isDisabled}
                    required={isRequired}
                    hidden={isVisible ? false : true}
                    error={error}
                >
                    <label
                        htmlFor={`text-area-${dmdcTargetField}`}
                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink"
                    >
                        {label}
                    </label>
                    <TextareaAutosize
                        id={`text-area-${dmdcTargetField}`}
                        disabled={isDisabled}
                        value={text}
                        name={`text-area-${fieldName}`}
                        onChange={handleChange}
                        onBlur={handleValidateField}
                        rowsMin={rowsMin}
                        rowsMax={rowsMax}
                        style={{ width: '300px' }}
                    />
                </FormControl>
            )}
        </Fragment>
    );
};

export default InputTextArea;
