import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, MenuItem, Divider } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import { useStyles, StyledMenu } from './Menu.styles';

interface MenuProps {
    isMenuOpen: boolean;
    setIsOpenMenu: (open: boolean) => void;
    labelData: {
        main: string;
        secondary: string;
    };
}

const Menu: React.FC<MenuProps> = ({ setIsOpenMenu, isMenuOpen, labelData }: MenuProps) => {
    const { push } = useHistory();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpenMenu(true);
    };
    const handleClose = () => {
        setIsOpenMenu(false);
        setAnchorEl(null);
    };

    const navigateTo = (path: string) => {
        push(path);
        handleClose();
    };

    return (
        <>
            <IconButton
                data-testid="hamburger-menu-btn"
                edge="end"
                color="primary"
                aria-label="menu"
                onClick={handleClick}
                className={classes.menuButton}
            >
                <MenuIcon className={classes.menuButton}></MenuIcon>
            </IconButton>

            <StyledMenu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <li className={classes.header}>
                    <strong>{labelData.main}</strong>
                    <div>{labelData.secondary}</div>
                </li>

                <Divider />
                <MenuItem onClick={() => navigateTo('/')}>Dashboard</MenuItem>
                <MenuItem onClick={() => navigateTo('/upload')}>Upload</MenuItem>
            </StyledMenu>
        </>
    );
};

export default Menu;
