import React, { Fragment, useState } from 'react';

import { Navbar } from 'components/Navbar';
import { Menu } from 'components/Menu';

import { MainContainer } from './Outline.styles';

import { UserInfo } from 'base/models/UserInfo';
import { retrieveUserInformation } from 'base/utils/localStorageAccess';

interface OutlineProps {
    children: React.ReactNode;
}

const userInfo: UserInfo | undefined = retrieveUserInformation();

const Outline: React.FC<OutlineProps> = ({ children }: OutlineProps) => {
    const [isMenuOpen, setIsOpenMenu] = useState(false);

    return (
        <Fragment>
            <Navbar setIsOpenMenu={setIsOpenMenu}>
                <Menu
                    setIsOpenMenu={setIsOpenMenu}
                    isMenuOpen={isMenuOpen}
                    labelData={{
                        main: `${userInfo?.firstName} ${userInfo?.lastName}`,
                        secondary: `${userInfo?.userName}`,
                    }}
                />
            </Navbar>
            <MainContainer>{children}</MainContainer>
        </Fragment>
    );
};

export default Outline;
