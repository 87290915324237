import { appStateHelper } from './../utils/appStateHelper';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import localStorageAccess, { LocalStorageKeys } from 'base/utils/localStorageAccess';
import { AuthenticationResponse } from 'base/models/UserInfo';
import { store } from 'base/stores/rootStore';

const api = (): AxiosInstance => {
    const API_BASE_URL = process.env.REACT_APP_BASE_URL || store.settingsStore.keys?.BASE_URL;

    const axiosRequestConfiguration: AxiosRequestConfig = {
        baseURL: API_BASE_URL,
        //withCredentials: true,
    };

    const axiosInstance = axios.create(axiosRequestConfiguration);

    axiosInstance.interceptors.request.use((config) => {
        if (config.method === 'put' && config.url?.toString().includes('.s3.')) {
            return config;
        } else {
            const token = localStorageAccess.getFromLocalStorage<AuthenticationResponse>(
                LocalStorageKeys.userInfo
            )?.token;
            if (token) config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
    });

    axiosInstance.interceptors.response.use(undefined, (error) => {
        if (axios.isCancel(error)) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return new Promise(() => {});
        }

        if (error.response.status === 401) {
            localStorageAccess.removeFromLocalStorage(LocalStorageKeys.userInfo);

            appStateHelper.requestToken.setRequestTokenState();

            window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return new Promise(() => {});
        }

        return error;
    });
    /*
        Add default headers, interceptors etc..
    */

    return axiosInstance;
};

export default api();
