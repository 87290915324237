import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'base/stores/rootStore';
import { StatusCountType, statusCountDefault, UserDropdown } from 'base/models/DashboardProps';
import { Assets } from 'base/models/Asset';
import { AssetStatus } from 'base/enums/AssetStatus';
import { groupType } from 'base/models/DashboardProps';

interface GroupedAsset {
    id: string;
    mam: string;
    createdAt: Date;
    updatedAt: Date;
    jobId: string;
    assetType: string;
    title: string;
    assets: Assets;
    user: string;
    totalAssets: number;
}

interface SnackbarSettings {
    isOpen?: boolean;
    message?: string;
    severity?: string;
}

type GroupedAssets = GroupedAsset[];

export class DashboardStore {
    rootStore: RootStore;
    constructor(rootstore: RootStore) {
        makeObservable(this);
        this.rootStore = rootstore;
    }

    @observable statusTotal: StatusCountType = statusCountDefault;
    @observable gridAssets: GroupedAssets = [];
    @observable userFilter: UserDropdown = { email: '', fullname: '' };
    @observable statusFilter = AssetStatus.Draft;
    @observable groupType = groupType.TYPE_TITLE;
    @observable isBackdropOpen = false;
    @observable snackbarSettings: SnackbarSettings = {
        message: '',
        isOpen: false,
        severity: '',
    };
    @observable isLoadingGrid = false;
    @observable isLoadingMenu = false;

    @observable portalId = '00c1b88f-5cf3-471b-903e-ebc0efc67234';

    @action setIsBackdrop = (value: boolean) => {
        this.isBackdropOpen = value;
    };

    @action setStatusTotal = (statusTotal: StatusCountType) => {
        if (statusTotal) this.statusTotal = { ...statusTotal };
    };

    @action setUserFilterInStore = (userFilter: UserDropdown) => {
        this.userFilter = userFilter;
    };

    @action setStatusFilterInStore = (statusFilter: AssetStatus) => {
        this.statusFilter = statusFilter;
    };

    @action setGroupTypeInStore = (groupType: groupType) => {
        this.groupType = groupType;
    };

    @action processAssets = (assets: Assets[]) => {
        const newGroupedAssets = assets.reduce((currentValue, group) => {
            const [firstGroup] = group;
            return [
                ...currentValue,
                {
                    id: firstGroup.id,
                    mam: firstGroup.mam,
                    createdAt: firstGroup.created?.date,
                    updatedAt: firstGroup.modified?.date,
                    jobId: firstGroup.jobId,
                    assetType: firstGroup.assetGroup.name
                        ? firstGroup.assetGroup.name
                        : 'Unassigned',
                    title: firstGroup.title.name ? firstGroup.title.name : 'Unassigned',
                    user: firstGroup.created?.user.name,
                    assets: group,
                    totalAssets: group.length,
                    cpmProductId: firstGroup.title.cpmProductId,
                    releaseDate: firstGroup.title.releaseDate ?? '',
                    wprId: firstGroup.title.wprId ?? null,
                } as GroupedAsset,
            ];
        }, [] as GroupedAssets);
        this.gridAssets = newGroupedAssets;
    };

    @action setSnackbarSettings = (isOpen?: boolean, message?: string, severity?: string) => {
        this.snackbarSettings = {
            message,
            isOpen,
            severity,
        };
    };

    @action setIsLoadingGrid = (isLoadingGrid: boolean) => {
        this.isLoadingGrid = isLoadingGrid;
    };

    @action setIsLoadingMenu = (isLoadingMenu: boolean) => {
        this.isLoadingMenu = isLoadingMenu;
    };

    @action setPortalId = (value: string) => {
        this.portalId = value;
    };

    @computed get getStatusCountInStore() {
        return this.statusTotal;
    }
    @computed get filteredAssets() {
        if (this.userFilter) {
            return this.gridAssets.filter((asset) => asset.user === this.userFilter.fullname);
        }
        return [];
    }

    @computed get getIsBackdropOpen() {
        return this.isBackdropOpen;
    }

    @computed get getSnackbarSettings() {
        return this.snackbarSettings;
    }

    @computed get getIsLoadingGrid() {
        return this.isLoadingGrid;
    }

    @computed get getIsLoadingMenu() {
        return this.isLoadingMenu;
    }

    @computed get getUserFilterInStore() {
        return this.userFilter;
    }

    @computed get getStatusFilterInStore() {
        return this.statusFilter;
    }

    @computed get getPortalId() {
        return this.portalId;
    }
}
