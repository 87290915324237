import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            backgroundColor: '#F6F8FB',
            border: '2px solid #F0F0F0',
            borderRadius: '6px',
        },
    })
);

export const ButtonAddMore = styled(Button)`
    text-transform: none;
    box-shadow: none;
    height: 48px;
    width: 194px;
    border-radius: 4px;
    background-color: #216085;

    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    &:hover {
        color: #ffffff;
        background-color: #216085;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const IconContainer = styled.div`
    padding-right: 10px;
`;

export const TextAndIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ButtonAddMoreContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;

    color: #2b2b2b;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
`;
