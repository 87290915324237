import { makeStyles, createStyles, Theme, Button, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            backgroundColor: '#F6F8FB',
            border: '2px solid #F0F0F0',
            borderRadius: '6px',
            height: '220px',
        },
    })
);

export const ButtonAddMore = styled(Button)`
    text-transform: none;
    box-shadow: none;
    height: 48px;
    width: 194px;
    border-radius: 4px;
    background-color: #216085;

    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    &:hover {
        color: #ffffff;
        background-color: #216085;
    }
`;

export const ButtonContainer = styled.div`
    margin-right: 15px;
    display: inline-block;
`;

export const PaperContainer = styled(Paper)`
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MainUploadParagraph = styled.div`
    color: #2b2b2b;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 22px;

    margin-bottom: 15px;
`;

export const SecondUploadParagraph = styled.div`
    color: #8b8b8b;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;

    margin-bottom: 15px;
`;

export const ButtonUpload = styled(Button)`
    text-transform: none;
    box-shadow: none;
    height: 48px;
    width: 194px;
    border-radius: 4px;
    background-color: #216085;

    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    &:hover {
        color: #ffffff;
        background-color: #216085;
    }
`;

export const UploadIconContainer = styled.div`
    color: #7e899e;
    margin-bottom: 15px;
`;
