import React, { useEffect, useState } from 'react';
import { dmdcAuthenticate } from 'base/api/auth';
import localStorageAccess, { LocalStorageKeys, clearLastPath, getLastPath } from 'base/utils/localStorageAccess';
import { Redirect, Route, useHistory, useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { appStateHelper } from 'base/utils/appStateHelper';
import { AuthenticationResponse } from 'base/models/UserInfo';
import { ROUTES } from 'base/constants/route';
import { USERACTION } from 'base/constants/userActions';

const DmdcLoginCallback: React.FC = () => {
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code') ?? '';

    useEffect(() => {
        async function auth() {
            queryParams.delete('code');
            history.replace({
                search: queryParams.toString(),
            });
            const response = await dmdcAuthenticate(code);
            if (response.response && response.response.data) {
                localStorageAccess.setToLocalStorage(
                    LocalStorageKeys.userInfo,
                    response.response.data
                );
                setIsLogged(true);
            }
        }
        auth();
    }, []);

    const renderRoute = () => {
        //last page redirection before lost credentials
        const lastPath = getLastPath();
        if (lastPath) {
            clearLastPath();
            return <Route render={() => <Redirect to={lastPath} />} />;
        }

        //vendors redirection
        const actions = localStorageAccess.getFromLocalStorage<AuthenticationResponse>(
            LocalStorageKeys.userInfo
        )?.user.actions;
        if (actions) {
            const hasPackagingPermission = actions.some(
                (item) =>
                    item.code === USERACTION.VIEWUPLOADSTATUS ||
                    item.code === USERACTION.UPLOADMEDIAFILESFORPACKAGING
            );
            const hasAdminPermission = !actions.some((item) => item.code === USERACTION.AUDIOVIDEO);
            if (hasPackagingPermission && hasAdminPermission) {
                return <Route render={() => <Redirect to={'/' + ROUTES.UPLOADSCREENERS} />} />;
            }
        }

        const redirectTo = appStateHelper.requestToken.getRequestTokenState();
        return <Route render={() => <Redirect to={redirectTo} />} />;
    };

    return isLogged ? (
        renderRoute()
    ) : (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
        </div>
    );
};
export default DmdcLoginCallback;
