import {
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    Button,
    DialogActions,
    IconButton,
    Checkbox,
} from '@material-ui/core';
import { Asset } from 'base/models/Asset';
import CloseIcon from '@material-ui/icons/Close';

import React, { useState, useEffect } from 'react';
import useAssetDuplicateStyles from '../AssetDuplicate.styles';
import { Title } from 'base/types/title';
import TitleCollection from '../TitleCollection/TitleCollection.component';

import TitlePicker from '../TitlePicker/TitlePicker.component';
import { Preview } from 'components/shared/Preview';
import { assetCopy } from 'base/api/asset';
import { HTTP_OK_STATUS } from 'base/constants/upload';

interface BootstrapDialogHeaderProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    disabled: boolean;
}

const BootstrapDialogHeader = ({ children, onClose, id, disabled }: BootstrapDialogHeaderProps) => {
    return (
        <DialogTitle id={id}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>{children}</Grid>
                {!!onClose && !disabled ? (
                    <Grid item>
                        <IconButton aria-label="close" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                ) : null}
            </Grid>
        </DialogTitle>
    );
};

export interface IProps {
    assets: Asset[];
    assetGroupName: string;
    open: boolean;
    onClose: () => void;

    notify: (type: 'error' | 'success', message: string) => void;
}

const AssetDuplicateDialog: React.FC<IProps> = ({
    assets,
    assetGroupName,
    open,
    onClose,

    notify,
}) => {
    const classes = useAssetDuplicateStyles();

    const [titles, setTitles] = useState<Title[]>([]);
    const [selectedTitles, setSelectedTitles] = useState<Title[]>([]);
    const [copyIsInProgress, setCopyIsInProgress] = useState<boolean>(false);
    const visibleUnselectdTitles = titles.filter((t) =>
        selectedTitles.every((st) => st.id !== t.id)
    );

    const [markedAssetMap, setMarkedAssetMap] = useState<{ [key: string]: Asset | null }>({});

    const resetForm = () => {
        setTitles([]);
        setSelectedTitles([]);
        setCopyIsInProgress(false);
    };

    useEffect(() => {
        resetForm();
    }, []);

    const handleClose = () => {
        resetForm();
        onClose();
    };

    useEffect(() => {
        const mappedAssets = (assets || []).reduce(
            (acc, next) => ({ ...acc, [next.id]: next }),
            {}
        );
        setMarkedAssetMap(mappedAssets);
    }, [assets]);

    const handleCopy = async () => {
        let copyAssets: Asset[] = [];
        for (const entryKey of Object.keys(markedAssetMap)) {
            if (markedAssetMap[entryKey]) {
                copyAssets = [markedAssetMap[entryKey] as Asset, ...copyAssets];
            }
        }
        if (!copyAssets.length) {
            return;
        }

        setCopyIsInProgress(true);
        const { response } = await assetCopy({ assets: copyAssets, titles: selectedTitles });

        const status = response?.status;
        if (status && status === HTTP_OK_STATUS) {
            notify('success', `Asset(s) copied.`);
            handleClose();
        } else {
            notify('error', `Asset(s) copying was unsuccsessful.`);
        }
        setCopyIsInProgress(false);
    };

    const handleCheckboxItemChange = (evt: React.ChangeEvent<HTMLInputElement>, asset: Asset) => {
        if (evt.target.checked) {
            setMarkedAssetMap({ ...markedAssetMap, [asset.id]: asset });
        } else {
            setMarkedAssetMap({ ...markedAssetMap, [asset.id]: null });
        }
    };

    const handleOnTitleSearch = (titles: Title[]) => {
        setTitles(titles);
    };

    const handleOnTitleSearchError = (error: any) => {
        notify('error', 'There is a problem with title search');
    };

    const handleOnTitleSelect = (title: Title, index: number) => {
        setSelectedTitles([title, ...selectedTitles]);
    };

    const handleOnTitleRemove = (title: Title, index: number) => {
        const newTitles = [...selectedTitles];
        newTitles.splice(index, 1);
        setSelectedTitles([...newTitles]);
    };

    return (
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <BootstrapDialogHeader
                disabled={copyIsInProgress}
                onClose={handleClose}
                id="dmdc-asset-duplicate-dialog-header"
            >
                Select titles to copy assets to
            </BootstrapDialogHeader>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        <Grid container direction="column">
                            <Grid item xs className={classes.dialogSectionHeading}>
                                {assetGroupName} ({assets.length})
                            </Grid>
                            <Grid item className={classes.titleList}>
                                {assets.map((asset) => (
                                    <Grid key={asset.id} container alignItems="center">
                                        <Checkbox
                                            id={`asset-select-for-copy-${asset.id}`}
                                            color="primary"
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                                handleCheckboxItemChange(evt, asset)
                                            }
                                            defaultChecked={true}
                                        />
                                        <Preview
                                            asset={asset}
                                            isFirstPreview={false}
                                            previewStyle={''}
                                            hideMenu={true}
                                        />
                                        <div
                                            title={`${asset.fields.fileName}`}
                                            className={`${classes.onelineEllipsis} ${classes.assetDescription}`}
                                        >
                                            <label>{asset.fields.fileName}</label>
                                            <div>
                                                <small>
                                                    {asset.title.primaryName} (
                                                    {asset.title.cpmProductId})
                                                </small>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={6} item>
                        <div className={classes.dialogSectionHeading}>Titles</div>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs>
                                <TitlePicker
                                    onSearch={handleOnTitleSearch}
                                    onSearchError={handleOnTitleSearchError}
                                />
                            </Grid>
                            <Grid item xs>
                                <TitleCollection
                                    icon="add"
                                    titles={visibleUnselectdTitles}
                                    onItemClick={(title, index) =>
                                        handleOnTitleSelect(title, index)
                                    }
                                />
                            </Grid>

                            <Grid item xs>
                                {selectedTitles?.length > 0 ? (
                                    <div>Below is the title to copy selected assets to:</div>
                                ) : null}
                                <TitleCollection
                                    icon="remove"
                                    titles={selectedTitles}
                                    onItemClick={(title, index) =>
                                        handleOnTitleRemove(title, index)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={copyIsInProgress} variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        copyIsInProgress ||
                        !selectedTitles?.length ||
                        !Object.keys(markedAssetMap).some((k) => markedAssetMap[k])
                    }
                    variant="outlined"
                    onClick={handleCopy}
                    color="primary"
                >
                    {!copyIsInProgress ? 'Copy' : 'Copying...'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssetDuplicateDialog;
