import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import IngestPayload from 'base/models/IngestPayload';
import { Assets, ExternalAssetsPayload } from 'base/models/Asset';
import DeleteDraftResponse from 'base/models/DeleteResponse';

export const postDraft = async (ingestPayload: IngestPayload) => {
    const response = await resolvePromise(
        api.post<IngestPayload>(`draft/job-assets`, ingestPayload)
    );
    return response;
};
export const putDraft = async (ingestPayload: IngestPayload) => {
    const response = await resolvePromise(
        api.put<IngestPayload>(`draft/job-assets`, ingestPayload)
    );
    return response;
};

export const commitDraft = async (payload: ExternalAssetsPayload) => {
    const response = await resolvePromise(api.post<Assets>('draft/mam-assets', payload));
    return response;
};

export const deleteDraft = async (assetId: string): Promise<any> => {
    const response = await resolvePromise(
        api.delete<DeleteDraftResponse>(`draft/asset/${assetId}`)
    );
    return response;
};

export const getDraftsByRequestId = async (requestId: string) => {
    const response = await resolvePromise(
        api.get<{ draftAssets: Assets; requestId: string }>(
            `external/draft/application-asset/${requestId}`
        )
    );
    return response;
};

export const getDraftsFromAssets = async (assetIds: string[]) => {
    const response = await resolvePromise(
        api.post<ExternalAssetsPayload>(`draft/request`, assetIds)
    );
    return response;
};
