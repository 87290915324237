import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';

import { Router } from 'components/Router';
import DMDCentralTheme from 'themes/dmd-central.theme';
import { useRootStoreContext } from 'base/stores/rootStore';
import api from 'base/api/config';
import { Settings } from 'base/stores/settingsStore';
import { QueryClient, QueryClientProvider } from 'react-query';

let appKey = '',
    description = '',
    subdomain = '',
    marketingSubdomain = '';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App: React.FC = () => {
    const { setSettings } = useRootStoreContext().settingsStore;
    const [areSettingsLoaded, setAreSettingsLoaded] = useState(false);

    useEffect(() => {
        const getKeysFromConfiguration = async () => {
            try {
                const response = await fetch(
                    `${window.location.protocol}//${window.location.host}/local_envs.json`
                );
                const keys: Settings = await response.json();

                setSettings(keys); // set in store
                api.defaults.baseURL = keys?.BASE_URL; // overwrite the settings to solve the error generated by "export default api()" in config.ts

                setAreSettingsLoaded(true);
            } catch (ex) {
                const keys: Settings = {
                    BASE_URL: process.env.REACT_APP_BASE_URL?.toString() || '',
                    CLIENT_ID: process.env.REACT_APP_CLIENT_ID?.toString() || '',
                    MYID_URL: process.env.REACT_APP_MYID_URL?.toString() || '',
                    DIF_MYID_URL: process.env.REACT_APP_DIF_MYID_URL?.toString() || '',
                    DISNEYSCREENERS_LOGIN_URL:
                        process.env.REACT_APP_DISNEYSCREENERS_LOGIN_URL?.toString() || '',
                    DISNEYSCREENERS_LOGIN_RETURN_URL:
                        process.env.REACT_APP_DISNEYSCREENERS_LOGIN_RETURN_URL?.toString() || '',
                    PREVIEW_URL: process.env.REACT_APP_PREVIEW_URL?.toString() || '',
                    DSUPLOADWIDGET_STYLE:
                        process.env.REACT_APP_DSUPLOADWIDGET_SCRIPT?.toString() || '',
                    DSUPLOADWIDGET_SCRIPT:
                        process.env.REACT_APP_DSUPLOADWIDGET_STYLE?.toString() || '',
                    DSPORTAL_ID: process.env.REACT_APP_DSPORTAL_ID?.toString() || '',
                };
                if (
                    keys.BASE_URL &&
                    keys.CLIENT_ID &&
                    keys.MYID_URL &&
                    keys.DIF_MYID_URL &&
                    keys.DISNEYSCREENERS_LOGIN_URL &&
                    keys.DISNEYSCREENERS_LOGIN_RETURN_URL &&
                    keys.PREVIEW_URL &&
                    keys.DSUPLOADWIDGET_SCRIPT &&
                    keys.DSUPLOADWIDGET_STYLE &&
                    keys?.DSPORTAL_ID
                ) {
                    setSettings(keys);
                    api.defaults.baseURL = keys.BASE_URL;
                    setAreSettingsLoaded(true);
                } else {
                    // eslint-disable-next-line
                    console.error('There was an error retrieving local_envs.json', ex);
                }
            }
        };
        getKeysFromConfiguration();
    }, []);

    const url = window.location.host;
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    const appValidation = [
        {
            app: 'dmdcentral',
            val: 'dmdc',
            alt: 'DMDCentral',
            text: 'Admin - Manage Assets - DMDCentral',
            ico: '/favicon.ico',
            subdomain: 'dmdcentral',
            marketingSubdomain: 'marketing',
        },
        {
            app: 'disneyinflight',
            val: 'dif',
            alt: 'DisneyInflight',
            text: 'Admin - Manage Assets - DisneyInflight',
            ico: '/favicon_dif.ico',
            subdomain: 'disneyinflight',
            marketingSubdomain: 'marketing',
        },
        {
            app: 'disneyscreeners',
            val: 'dsc',
            alt: 'DisneyScreeners',
            text: 'Admin - Manage Assets - DisneyScreeners',
            ico: '/favicon_dsc.ico',
            subdomain: 'disneyscreeners',
            marketingSubdomain: '',
        },
        {
            app: '3000',
            val: 'dmdc',
            alt: 'DMDCentral',
            text: 'Admin - Manage Assets - DMDCentral',
            ico: '/favicon.ico',
            subdomain: 'dmdcentral',
            marketingSubdomain: 'marketing',
        },
    ];

    appValidation.forEach(function (element) {
        if (url.includes(element.app)) {
            appKey = element.val;
            subdomain = element.subdomain;
            description = element.alt;
            document.title = element.text;
            favicon.href = element.ico;
            marketingSubdomain = element.marketingSubdomain;
        }
    });

    return (
        <ThemeProvider theme={DMDCentralTheme}>
            <QueryClientProvider client={queryClient}>
                {areSettingsLoaded ? <Router /> : <>Loading Settings...</>}
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export { appKey, description, subdomain, marketingSubdomain};

export default App;
