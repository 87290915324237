import styled from 'styled-components';

export const AssetProgressSpan = styled.span`
    position: absolute;
    top: 11px;
    left: 6px;
`;

export const AssetProgressContainer = styled.span`
    position: relative;
    padding-right: 15px;
`;

export const AssetProgressMessage = styled.span`
    align-self: center;
`;
