import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
} from '@material-ui/core';
import { useStyles } from './DeleteModal.styles';

const DeleteModal = (props: any) => {
    const { open, setOpen, onConfirm } = props;
    const classes = useStyles();

    return (
        <Dialog open={open}>
            <DialogTitle className={classes.DialogTitle}>Confirm Delete</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to delete the selected Asset(s)?</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                >
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;
